import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DownForMaintenanceComponent } from './pages/down-for-maintenance/down-for-maintenance.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { ServerErrorComponent } from './pages/server-error/server-error.component';

@NgModule({
  declarations: [
    DownForMaintenanceComponent,
    PageNotFoundComponent,
    ServerErrorComponent,
  ],
  imports: [CommonModule, RouterModule],
})
export class ErrorModule {}
