<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="custom-modal">
    <div class="dialog-title" cdkDragHandle>
      <h2 mat-dialog-title>Contact Permission Update History</h2>
      <mat-icon mat-dialog-close class="cursor-pointer">close</mat-icon>
    </div>
    <mat-divider></mat-divider>
    <mat-dialog-content class="mat-typography">
        <div class="basic-container" style="margin:5px">
            <table mat-table [dataSource]="contactDataSource" matSortActive="lead_contact_permission_change_date" matSortDirection="asc" #contactSort="matSort"  matSort class="mat-elevation-z8 table-striped">
              <ng-container matColumnDef="lead_contact_permission_type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Contact Permission </th>
                <td mat-cell *matCellDef="let row">
                    <span *ngIf="row?.lead_contact_permission_type == 'do_not_call'">Call</span>
                    <span *ngIf="row?.lead_contact_permission_type == 'do_not_email'">Email</span>
                    <span *ngIf="row?.lead_contact_permission_type == 'do_not_mail'">Mail</span>
                    <span *ngIf="row?.lead_contact_permission_type == 'do_not_text'">Text</span>
                </td>
              </ng-container>

              <ng-container matColumnDef="lead_contact_permission_to_contact">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                <td mat-cell *matCellDef="let row">
                    <span *ngIf="row?.lead_contact_permission_to_contact == 0">ON</span>
                    <span *ngIf="row?.lead_contact_permission_to_contact == 1">OFF</span>
                </td>
              </ng-container>

              <ng-container matColumnDef="lead_contact_permission_change_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Date/Time </th>
                <td mat-cell *matCellDef="let row"> {{row?.lead_contact_permission_change_date | date : 'MM/dd/yyyy'}} </td>
              </ng-container>

              <ng-container matColumnDef="login">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> User Id </th>
                <td mat-cell *matCellDef="let row">{{row?.lead_contact_permission_change_user.login}}</td>
              </ng-container>

              <ng-container matColumnDef="fname">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> User Name </th>
                <td mat-cell *matCellDef="let row">{{row?.lead_contact_permission_change_user.fname + ' ' + row?.lead_contact_permission_change_user.lname}}  </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="contactDisplayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: contactDisplayedColumns;">
              </tr>
            </table>
          </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button mat-raised-button mat-dialog-close>Close</button>
      </mat-dialog-actions>
  </div>
