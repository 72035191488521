<div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <div class="dialog-title" cdkDragHandle>
    <h2 mat-dialog-title>Add Agent Writing Number</h2>
    <mat-icon mat-dialog-close class="cursor-pointer">close</mat-icon>
  </div>
  <mat-divider></mat-divider>

  <mat-dialog-content class="mat-typography">
    <div class="basic-container lead-body">
      <ng-container *ngIf="message.type">
        <app-sms-notice-banner #messageBanner [type]="message.type" (closeBanner)="clearMessage(0)">
          <p>{{ message.text }}</p>
        </app-sms-notice-banner>
      </ng-container>
      <form [formGroup]="agentWritingForm">
        <div fxLayout="row wrap">
          <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
            <div fxLayout="row wrap">
              <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="3.5%" fxLayoutWrap>
                <mat-form-field class="example-full-width" fxFlex="100%">
                  <mat-label>Carrier</mat-label>
                  <mat-select disableOptionCentering matNativeControl formControlName="name" (selectionChange)="listActiveByCarrier($event)"
                    required>
                    <mat-option class="full-text" *ngFor="let elem of carrierData" [value]="elem.name">
                      {{elem.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="example-full-width" fxFlex="100%">
                  <mat-label>Product Type</mat-label>
                  <mat-select disableOptionCentering matNativeControl formControlName="product_type_desc"
                    (selectionChange)="productTypeId($event)">
                    <mat-optgroup label="---ACTIVE PRODUCT TYPES---">
                      <mat-option *ngFor="let elem of activeProduct" [value]="elem.product_type_desc">
                        {{elem.product_type_desc}}
                      </mat-option>
                    </mat-optgroup>
                    <mat-optgroup label="---INACTIVE PRODUCT TYPES---" *ngIf="!showDefault">
                      <mat-option *ngFor="let elem of inactiveProduct" [value]="elem">
                        {{elem}}
                      </mat-option>
                    </mat-optgroup>
                    <mat-optgroup label="---INACTIVE PRODUCT TYPES---" *ngIf="showDefault">
                      <mat-option *ngFor="let elem of productType" [value]="elem.product_type_desc">
                        {{elem.product_type_desc}}
                      </mat-option>
                    </mat-optgroup>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="example-full-width" fxFlex="100%">
                  <mat-label>Agent Writing Number</mat-label>
                  <input matInput autocomplete="off" formControlName="agent_writing_number">
                </mat-form-field>
              </div>

            </div>
            <div fxLayout="row wrap"
              *ngIf="agentWritingForm.value.product_type_desc ==  'Medicare Advantage' || agentWritingForm.value.product_type_desc == 'Medicare Part D'">
              <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="3.5%" fxLayoutWrap>
                <mat-form-field class="example-full-width" fxFlex="100%">
                  <mat-label>Plan Year</mat-label>
                  <mat-select disableOptionCentering matNativeControl formControlName="certification_year" required>
                    <mat-option value=""></mat-option>
                    <mat-option value={{year}}>{{year}}</mat-option>
                    <mat-option value={{year1}}>{{year1}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

            </div>
          </div>
        </div>
      </form>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-raised-button mat-dialog-close>Cancel</button>
    <button type="button" mat-button mat-raised-button color="primary" [disabled]="agentWritingForm.invalid"
      (click)="addAgent()">Add</button>
  </mat-dialog-actions>
</div>
