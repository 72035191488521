import { Component, Inject, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountSettings } from '@data/admin/account-settings.interface';
import { PhoneDialerDialogData } from '@data/services/integration/phone-dialer.interface';
import { PhoneDialerService } from '@data/services/integration/phone-dialer.service';
import { IUserSettings } from '@data/user-profile/user-profile.interface';
import { CommonService } from '@shared/services/common/common.service';
import { ToastMsgService } from '@shared/services/toastr/toast-msg.service';
import { first } from 'rxjs';

@Component({
  selector: 'app-phone-dialer-option',
  templateUrl: './phone-dialer-option.component.html',
  styleUrls: ['./phone-dialer-option.component.scss']
})
export class PhoneDialerOptionComponent implements OnInit {

  private _accountSettings: AccountSettings;
  private _leadId: number;
  private _newDefaultBehavior: boolean;
  private _phoneNumber: string;
  private _userSettings: IUserSettings;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PhoneDialerDialogData,
    private _commonService: CommonService,
    private _phoneDialerSvc: PhoneDialerService,
    private _toastMsgSvc: ToastMsgService,
  ) {
    this._accountSettings = data.accountSettings;
    this._leadId = data.leadId;
    this._phoneNumber = data.phoneNumber;
    this._userSettings = data.userSettings;
  }

  ngOnInit(): void {
  }

  public dialWithPhoneDialer(): void {
    this._phoneDialerSvc.createPhoneCall({
        callee_phone_number: this._phoneNumber,
        lead_id: this._leadId,
        user_id: this._userSettings.user_id!,
      })
      .pipe(first())
      .subscribe({
        error: (err) => {
          this._phoneDialerSvc.redirectToDialerLandingPage(err.message);  
        }
      })


    this._savePreference('2');

  }

  public dialWithBrowserDefault(): void {
    const telephonyPrefix = this._accountSettings.telephony_prefix || 'tel';

    this._phoneDialerSvc.dialPhoneNumberBrowserDefault(telephonyPrefix, this._phoneNumber);
    this._savePreference('1');
  }

  private _savePreference(behaviorId: string): void {
    /** 
     * Behavior id:
     *     1 - Always Browser defualt
     *     2 - ALways Phone.com
     *     3 - Ask me everytime
     */
    const newDefaultBehavior = this._newDefaultBehavior ? behaviorId : '3'

    if (this._userSettings.phone_dialer_provider_behavior_type_id != newDefaultBehavior)
      this._commonService.updateLoggedInUserSettings(this._userSettings.user_id!, { phone_dialer_provider_behavior_type_id: newDefaultBehavior })
        .pipe(first())
        .subscribe({
          error: () => {
            this._toastMsgSvc.showError('Unable to update phone dialer preference. Please try again later.')
          }
        });
  }

  public updatePreference(event: MatCheckboxChange): void {
    this._newDefaultBehavior = event.checked;
  }
}
