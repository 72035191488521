<mat-toolbar *ngIf="showHeader">
  <ng-container *ngIf="isExpanded" #sidenav>
    <img class="default_logo cursor-pointer" [src]="defaultLogo" alt="Lead Advantage Pro" (click)="redirectToMain()" />
  </ng-container>

  <ng-container *ngIf="!isExpanded" #sidenav>
    <img class="default_small_logo cursor-pointer" [src]="defaultLogoSmall" alt="Lead Advantage Pro"
      (click)="redirectToMain()" />
  </ng-container>

  <div class="main-header">
    <div class="left">
      <button mat-icon-button [disabled]="isDisabled" (click)="expendMenu()" aria-label="Menu">
        <mat-icon class="header-icon">menu</mat-icon>
      </button>
      <div fxLayout="row" fxLayoutAlign="center center">
        <ng-container *ngIf="isLoading; else quickSearch">
          <div class="searchSpinner">
            <app-sms-spinner size="sm"></app-sms-spinner>
          </div>
        </ng-container>
        <ng-template #quickSearch>
          <button mat-icon-button (click)="openSearchDialog()" aria-label="Search">
            <mat-icon class="header-icon" title="Quick Search">search</mat-icon>
          </button>
        </ng-template>
      </div>
      <div class="container current-date">
        <div fxLayout="row">
          <span class="date">{{ date }}</span>
          <div fxLayout="column" class="gap">
            <span class="month">{{ month }} {{ year }}</span>
            <span class="day">{{ day }} {{ time | uppercase }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <button mat-icon-button (click)="openNotification()" aria-label="Notification">
        <mat-icon class="notification-icon" *ngIf="notification_count != 0" color="primary" matBadgeColor="warn"
          matBadge="{{ notification_count }}">notifications</mat-icon>
        <mat-icon class="header-icon notification-icon" *ngIf="notification_count == 0">notifications</mat-icon>
      </button>

      <button mat-icon-button class="btnName" aria-label="Dropdown Menu"
        #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu" (menuOpened)="openMenu()" (menuClosed)="showSubmenu = false">
        <mat-icon class="menu-button header-icon" [ngClass]="{ expand_more: showSubmenu }">chevron_right</mat-icon>
        <span class="user-name">{{ userName }}</span>
      </button>
      <mat-menu #menu="matMenu" [overlapTrigger]="false">
        <ng-template matMenuContent>
          <ng-container *ngIf="isSubMenuLoading; else openedMenu">
            <div mat-menu-item [disabled]="true">
              <app-sms-spinner [size]="'sm'">
                <span>Loading Menu Items</span>
              </app-sms-spinner>
            </div>
          </ng-container>

          <ng-template #openedMenu>
            <button mat-menu-item (click)="openUserProfile()">
              <mat-icon>manage_accounts</mat-icon> <span>Profile</span>
            </button>

            <button mat-menu-item *ngIf="configurableSettingsFlag" (click)="openConfigurableSettings()">
              <mat-icon>build</mat-icon> <span>Configurable Settings</span>
            </button>

            <button mat-menu-item *ngIf="hasPeproAccess" (click)="openPepro()">
              <mat-icon>explore</mat-icon> <span>Plan Explorer</span>
            </button>

            <button mat-menu-item *ngIf="(showPhoneDialer$ | async)" (click)="openCallRecordingUserSettings()">
              <mat-icon>voicemail</mat-icon> <span>Call Recording</span>
            </button>

            <button mat-menu-item (click)="logout()">
              <mat-icon>exit_to_app</mat-icon> <span>Logout</span>
            </button>
          </ng-template>

        </ng-template>
      </mat-menu>
    </div>
  </div>
</mat-toolbar>