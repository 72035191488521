<form autocomplete="off" [formGroup]="addressForm">
  <div fxLayout="row" fxLayoutAlign="space-between baseline">
    <h4 class="component-heading">Addresses</h4>
    <div fxFlex="10" >
      <button class="add-entry" mat-icon-button type="button" [matMenuTriggerFor]="addressMenu" title="Add Entry">
        <mat-icon inline>add</mat-icon>
      </button>
      <mat-menu #addressMenu="matMenu">
        <ng-container *ngFor="let content of address_content">
          <button *ngIf="!content.isSelected" (click)="addAddressType(content)" mat-menu-item>{{ content.name }}</button>
        </ng-container>
      </mat-menu>  
    </div>
  </div>

  <div formArrayName="addresses">
    <ng-container *ngFor="let address of addresseListArr?.controls; let i=index" [formGroupName]="i">

      <div fxLayout="row">
        <div fxFlex="85">
          <div fxLayout="row">
            <!-- address type -->
            <mat-form-field fxFlex="20">
              <mat-label>Type</mat-label>
              <mat-select [disabled]="address?.value?.address_class == 'true'" matNativeControl
                formControlName="address_type_id" [ngClass]="{'line-through': address?.value?.address_class == 'true'}">
                <mat-option *ngFor="let content of address_content"
                  value="{{content.name}}">{{content.name}}</mat-option>
              </mat-select>
            </mat-form-field>

            <!-- address1 -->
            <mat-form-field fxFlex="">
              <mat-label>Address</mat-label>
              <input [class]="address?.value?.address_class == 'true' ? 'not-allowed' : ''"
                (mousedown)="$event.stopPropagation()"
                [readonly]="address?.value?.address_class == 'true'"
                [appInputMaxLength] matInput formControlName="address1"
                [ngClass]="{'line-through': address?.value?.address_class == 'true'}" appDisableAutofill>
              <mat-error *ngIf="address?.get('address1')?.errors && submitted">
                Please enter address
              </mat-error>
            </mat-form-field>

          </div>

          <div fxLayout="row">
            <!-- address2 -->
            <mat-form-field fxFlex="20">
              <mat-label>Address 2</mat-label>
              <input [class]="address?.value?.address_class == 'true' ? 'not-allowed' : ''"
                [readonly]="address?.value?.address_class == 'true'" (mousedown)="$event.stopPropagation()"
                [appInputMaxLength] matInput formControlName="address2"
                [ngClass]="{'line-through': address?.value?.address_class == 'true'}" appDisableAutofill>
            </mat-form-field>

            <!-- city -->
            <mat-form-field fxFlex="">
              <mat-label>City</mat-label>
              <input [class]="address?.value?.address_class == 'true' ? 'not-allowed' : ''"
                [readonly]="address?.value?.address_class == 'true'" (mousedown)="$event.stopPropagation()"
                [appInputMaxLength] matInput formControlName="city"
                [ngClass]="{'line-through': address?.value?.address_class == 'true'}" appDisableAutofill>
              <mat-error *ngIf="address?.get('city')?.errors && submitted">Please enter city</mat-error>
            </mat-form-field>

            <!-- state_id -->
            <mat-form-field fxFlex="8">
              <mat-select [disabled]="address?.value?.address_class == 'true'" placeholder="State" #State
                formControlName="state_id" [ngClass]="{'line-through': address?.value?.address_class == 'true'}">
                <mat-option *ngFor="let el of state_data" [value]="el.abbreviation">{{ el.abbreviation }}</mat-option>
              </mat-select>
              <mat-error *ngIf="address?.get('state_id')?.errors && submitted">Please select state</mat-error>
            </mat-form-field>

            <!-- zip -->
            <mat-form-field fxFlex="10">
              <mat-label>Zip</mat-label>
              <input matInput [class]="address?.value?.address_class == 'true' ? 'not-allowed' : ''"
                [readonly]="address?.value?.address_class == 'true'" (mousedown)="$event.stopPropagation()" 
                formControlName="zip" [minlength]="5" [maxlength]="5" appNumbersOnly
                (input)="getCounties(i, '', true)" [ngClass]="{'line-through': address?.value?.address_class == 'true'}"
                appDisableAutofill>
              <mat-error *ngIf="address?.get('zip')?.errors && submitted">Please enter correct zip</mat-error>
            </mat-form-field>

            <!-- county -->
            <mat-form-field fxFlex="20" *ngIf="type!='profile' && type!= 'admin' && type!='add-user'">
              <mat-select [disabled]="address?.value?.address_class == 'true'"  placeholder="County"
                formControlName="county" [ngClass]="{'line-through': address?.value?.address_class == 'true'}">
                <mat-option *ngIf="address?.value?.county.length>0" class="selected-county" [value]="address?.value?.county">{{ address?.value?.county }}</mat-option>
                <mat-optgroup *ngFor="let country of countiesData" label="{{ country.state_name }}">
                  <mat-option *ngFor="let county of country.counties" (click)="changeOption(country,i)" [value]="county?.county_name">{{ county?.county_name }}</mat-option>
                </mat-optgroup>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div fxFlex="15">
          <button class="cta-buttons delete" type="button" title="Remove Address" mat-icon-button
            *ngIf="address?.value?.address_class == ''" (click)="clearAddress(address.value,i)">
            <mat-icon class="_font--larger" inline>clear</mat-icon>
          </button>
          <button class="cta-buttons undo" type="button" title="Undo Changes" mat-icon-button
            *ngIf="address?.value?.address_class == 'true'" (click)="refreshAddress(address.value,i)">
            <mat-icon class="_font--larger" inline>replay</mat-icon>
          </button>
          <mat-checkbox class="cta-buttons" title="Default Address" *ngIf="type!='admin'" (change)="addressCheckBoxvalue($event,address.value,i)"
            [checked]="address?.value?.isSelected==true || type == 'plan' || type=='add-user'">
          </mat-checkbox>
        </div>

      </div>

    </ng-container>

  </div>
</form>