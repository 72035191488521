import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'clickPhone'
})
export class ClickPhonePipe implements PipeTransform {

  transform(rawNum : string) {
    const phonePattern = /(\d{3})(\d{3})(\d{4})/g;

    return rawNum.split(phonePattern).join('-');
  }

}
