import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, UntypedFormBuilder, FormControl, Validators, FormGroupDirective, FormArray } from '@angular/forms'
import { PHONE_TYPES } from '@app/constants';
import { AdminService } from '@data/services/admin/admin.service';
import { LeadService } from '@data/services/lead/lead.service';
import { Subject, Subscription, takeUntil } from 'rxjs';
@Component({
  selector: 'app-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss']
})
export class PhoneComponent implements OnInit {
  private isErrorSubs: Subscription;
  private getLeadLedgerSubs: Subscription;
  private getAdminFormArraySubs: Subscription;
  private _componentDestroyed$ = new Subject<boolean>();
  private checkedIndex: number;
  private deletedItems: any = [];
  public phoneForm: UntypedFormGroup;
  public phone_content = PHONE_TYPES;
  public submitted: boolean = false;
  public hasChange: boolean = false;
  @Input() formArrayName!: string;
  @Input() type!: string;
  public phonesListArr!: FormArray;

  constructor(
    private fb: UntypedFormBuilder,
    private rootFormGroup: FormGroupDirective,
    private leadService: LeadService,
    private adminService: AdminService
  ) {
    this.isErrorSubs = this.leadService.getErrorAlert().subscribe(response => {
      if (response) {
        this.submitted = true;
        if (this.phonesListArr) {
          this.phonesListArr.controls.find((x: any) => {
            if (x.controls.phone.status) { x.controls.phone.touched = true }
          })
        }
      }
    });
    this.getLeadLedgerSubs = this.leadService.getLeadLedger().subscribe(response => {
      this.phoneForm = this.rootFormGroup.control;
      this.phonesListArr = this.phoneForm.get(this.formArrayName) as FormArray;
    });
    this.getAdminFormArraySubs = this.adminService.getAdminFormArray().subscribe(response => {
      this.phoneForm = this.rootFormGroup.control;
      this.phonesListArr = this.phoneForm.get(this.formArrayName) as FormArray;
    });
    /* code for to refresh the delete itmes */
    this.leadService.getFormChangeAlert().pipe(takeUntil(this._componentDestroyed$)).subscribe((elem: string) => {
      if (elem == 'clearArray') {
        this.deletedItems = [];
        if (this.phonesListArr?.value?.length) {
          this.phonesListArr.value.forEach((element: any) => {
            this.phone_content.forEach((ele: any) => {
              if (element.phone_type == ele.text) {
                ele['isSelected'] = ele.text == 'Other' ? false : true;
              } else {
                ele['isSelected'] = false;
              }
            });
          });
        }
      }
    })

    /* code for to add the line through when add the email || address */
    this.leadService.getCrossedItems().pipe(takeUntil(this._componentDestroyed$)).subscribe((elem: string) => {
      if (elem == 'emailAdded' || elem == 'addressAdded') {
        this.phoneForm = this.rootFormGroup.control;
        this.phonesListArr = this.phoneForm.get(this.formArrayName) as FormArray;
        if (this.phonesListArr?.value) {
          this.phonesListArr?.value.forEach((element: any, index: number) => {
            this.deletedItems.forEach((ele: number) => {
              if (index == ele) {
                element['phone_class'] = 'true'
              }
            });
          });
        }
      }
    })
  }

  ngOnInit(): void {
    this.phoneForm = this.rootFormGroup.control;
    this.phonesListArr = this.phoneForm.get(this.formArrayName) as FormArray;
    if (!this.phonesListArr?.value?.length) {
      this.addPhoneType({ text: 'Home' });
    } else {
      this.phonesListArr.value.forEach((element: any) => {
        this.phone_content.forEach((ele: any) => {
          if (element.phone_type == ele.text) {
            ele['isSelected'] = ele.text == 'Other' ? false : true;
          }
        });
      });
    }
  }

  // ::::::::::::::::::::::::::::: add phone functionality ::::::::::::::::::::::::::::: //

  public addPhoneType(phoneType: any) {
    this.phones.push(
      this.fb.group({
        phone_type: phoneType.text,
        phone: new FormControl('', [Validators.minLength(14)]),
        phone_ext: [''],
        phone_id: [''],
        phone_class: [''],
        isSelected: "",
        isDisabled: true
      })
    );
    phoneType.isSelected = phoneType.text == 'Other' ? false : true;
    /* code to select the previous default phone when adding new phone */
    this.phonesListArr.value.find((x: any, i: number) => {
      if (this.checkedIndex) {
        if (i == this.checkedIndex) {
          x.isSelected = true;
        } else {
          x.isSelected = false
        }
      }
      if (this.deletedItems.includes(i)) {
        x.phone_class = "true"
      } else {
        x.phone_class = ""
      }
    })
    this.leadService.setCrossedItems('phoneAdded');
  }

  public get phones(): UntypedFormArray {
    return this.phoneForm.get("phones") as UntypedFormArray
  }

  // ::::::::::::::::::::::::::::: end phone functionality ::::::::::::::::::::::::::::: //

  //function to Strike-through the phone value
  public clearPhone(value: any, index: number) {
    this.hasChange = true;
    value.phone_class = "true";
    this.deletedItems.push(index)
    this.leadService.sendFormChangeAlert('valueChange');
  }

  //function to undo Strike-through the phone value
  public refreshPhone(value: any, index: number) {
    if (this.deletedItems.includes(index)) {
      this.deletedItems.splice(index)
    }
    value.phone_class = "";
    this.leadService.sendFormChangeAlert('');

  }

  //function called when checkbox is unchecked for phone
  public phoneCheckBoxvalue(checkbox: any, item: any, index: number) {
    this.hasChange = true;
    this.phones.value.forEach((val: any) => {
      if (val.phone == item.phone && val.phone_type == item.phone_type) {
        this.checkedIndex = index;
        val.isSelected = !val.isSelected;

      } else {
        val.isSelected = false;
      }
    });
    this.leadService.sendFormChangeAlert('valueChange');
  }

  public ngOnDestroy() {
    if (this.isErrorSubs) this.isErrorSubs.unsubscribe();
    if (this.getLeadLedgerSubs) this.getLeadLedgerSubs.unsubscribe();
    if (this.getAdminFormArraySubs) this.getAdminFormArraySubs.unsubscribe();
    this._componentDestroyed$.next(true);
    this._componentDestroyed$.complete();

  }

}
