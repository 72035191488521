<div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <div class="dialog-title" cdkDragHandle>
        <h2 mat-dialog-title>{{quickLinkText ? 'CMS Check' :'Single Sign On'}}</h2>
        <mat-icon mat-dialog-close class="cursor-pointer">close</mat-icon>
    </div>
    <mat-dialog-content>
        <p *ngIf="!quickLinkText">Select “Continue” to autosign onto the portal for {{carrierName }}</p>
        <p *ngIf="quickLinkText" [innerHTML]="quickLinkText | safeHtml"></p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <div class="example-button-row">
            <div fxLayout="row wrap">
                <button type="button" mat-raised-button mat-dialog-close>Cancel</button>
                <button type="button" color="primary" mat-raised-button mat-dialog-close (click)="submitSSO()">Continue</button>
            </div>
        </div>
    </mat-dialog-actions>
</div>
