import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@app/service/auth.service';
import { addMessage } from '@data/admin/message.interface';
import { Carrier } from '@data/admin/users.interface';
import { messages } from '@data/admin/message.interface';
import { leadExport, trackCodeInterface } from '@data/admin/leads.interface';
import { leadData } from '@data/admin/leads.interface';
import { leadImport } from '@data/admin/leads.interface';
import { leadInstruction } from '@data/admin/leads.interface';
import { rolePermission } from '@data/admin/roles.interface';
import { IProductFilter, productFilter } from '@data/admin/product.interface';
import { roles } from '@data/admin/roles.interface';
import { accountData } from '@data/admin/accounts.interface';
import { search } from '@data/admin/leads.interface';
import { clientData } from '@data/admin/leads.interface';
import { campaignData } from '@data/admin/leads.interface';
import { campaignSegment } from '@data/admin/leads.interface';
import { environment } from '@env/environment';
import { catchError, map, Observable, Subject, throwError } from 'rxjs';
import { IResponse } from '@data/shared/response.interface';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  private adminSubject = new Subject<any>();

  private _cachedFilterProducts: IProductFilter[];
  private _filterProducts$ = new Subject<IProductFilter[]>();

  public filterData : IProductFilter[]; // variable to store the data for product filter.
  constructor(private http: HttpClient, private auth: AuthService) { }

  public adminFormArray(data: any) {
    this.adminSubject.next(data);
  }

  public getAdminFormArray(): Observable<any> {
    return this.adminSubject.asObservable();
  }

  // ****************************** messages ******************************************
  public getAllMessages(data: messages) {
    return this.http.post((environment.api) + "notice/search", data).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }

  public addNewMessage(data: addMessage) {
    return this.http.post((environment.api) + "notice", data).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }

  public editMessage(data: addMessage) {
    return this.http.post((environment.api) + "notice/" + data.notice_id, data).pipe(
      catchError((error) => {
        return throwError(() => error);
      }
      ))

  }

  public deleteMessage(notice_id: string) {
    return this.http.get((environment.api) + "notice/" + notice_id + "/delete").pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }
  // ******************************* users ******************************************

  public loggedInUser(accountId: number) {
    return this.http.get((environment.api) + "account/" + accountId).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }

  public getRegistrationLink(role_id: string) {
    return this.http.get((environment.api) + "registration/buildLink/account/" + this.auth.account_id + "/role/" + role_id).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }
  public getRolePermissionsData(role_id: string) {
    return this.http.get((environment.api) + "account/" + this.auth.account_id + "/role/" + role_id + "/permissions").pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }

  //-------------------------- agent writing numbers popup  ---------------------------------------------------
  public listActiveByCarrier(carrier_ids: object) {
    return this.http.post((environment.api) + "productType/listActiveByCarrier", carrier_ids).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }

  // ******************************* leads ******************************************
  // ## export leads ##
  public exportLeads(data: leadExport) {
    return this.http.post((environment.api) + "leadExport", data).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }

  public addLeadSource(data: leadData) {
    return this.http.post((environment.api) + "LeadVendor", data).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }

  public getExistingLead(vendorId: number) {
    return this.http.get((environment.api + "LeadVendor/" + vendorId)).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }

  public updateExistingLead(data: leadData, vendorId: number) {
    return this.http.post((environment.api) + "LeadVendor/" + vendorId, data).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }

  public deleteLeadSource(vendorId: number) {
    return this.http.get((environment.api) + "LeadVendor/" + vendorId + "/delete").pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }

  public leadVendorSearch(data: search) {
    return this.http.post((environment.api) + "LeadVendor/search", data).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }

  //** client-affiliate-partner **//
  public getb2bPartner(data: search, accountId: string) {
    return this.http.post((environment.api) + "account/" + accountId + "/b2bpartner/search", data).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }

  public addClientInfo(data: clientData) {
    return this.http.post((environment.api) + "account/" + this.auth.account_id + "/b2bpartner", data).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }

  public updateClientInfo(data: clientData, b2b_id: number) {
    return this.http.post((environment.api) + "account/" + this.auth.account_id + "/b2bPartner/" + b2b_id, data).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }

  public deleteClientInfo(b2b_id: number) {
    return this.http.get((environment.api) + "account/" + this.auth.account_id + "/b2bpartner/" + b2b_id + "/delete").pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }

  //** lead-instructions **//
  public getLeadInstructions(payload: search) {
    return this.http.post((environment.api) + "LeadInstructions/search", payload).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }

  public getLeadInstructionProduct() {
    return this.http.get((environment.api) + "document/drilldown/productType").pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }

  public addLeadInstruction(payload: leadInstruction) {
    return this.http.post((environment.api) + "leadInstructions", payload).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }

  public updateLeadInstruction(id: number, payload: leadInstruction) {
    return this.http.post((environment.api) + "leadInstructions/" + id, payload).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }

  public deleteLeadInstruction(id: number) {
    return this.http.get((environment.api) + "leadInstructions/" + id + "/delete").pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }

  //** campaign **//
  public addNewCampaign(payload: campaignData) {
    return this.http.post((environment.api) + "account/" + this.auth.account_id + "/campaign", payload).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }

  public updateExistingCampaign(id: number, payload: campaignData) {
    return this.http.post((environment.api) + "account/" + this.auth.account_id + "/campaign/" + id, payload).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }

  public deleteCampaign(id: number) {
    return this.http.get((environment.api) + "account/" + this.auth.account_id + "/campaign/" + id + "/delete").pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }

  public addNewCampaignSegment(payload: campaignSegment) {
    return this.http.post((environment.api) + "campaignSegment", payload).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }

  public updateExistingCampaignSegment(id: number, payload: campaignSegment) {
    return this.http.post((environment.api) + "campaignSegment/" + id, payload).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }

  public deleteCampaignSegment(id: string) {
    return this.http.get((environment.api) + "campaignSegment/" + id + "/delete").pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }

  // ## import lead ##
  public getLeadImportFile() {
    return this.http.get((environment.api) + "leadImportFile/template").pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }

  public addLeadImportFile(payload: leadImport) {
    return this.http.post((environment.api) + "leadImportFile", payload).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }

  public getAccountInfo() {
    return this.http.get((environment.api) + "account/" + this.auth.account_id).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }

  public AccountSelectedUser(payload: object, account_id: number) {
    return this.http.post((environment.api) + "account/" + account_id + "/settings", payload).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }

  public updateAccount(data: accountData) {
    return this.http.post((environment.api) + "account/" + data.account_id, data).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }

  public smsAccounts(data: search) {
    return this.http.post((environment.api) + "account/search", data).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }

  public smsAccountInitialField(id: number) {
    return this.http.get((environment.api) + "account/" + id).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }

  public smsAccountUpdateFields(data: accountData, id: string) {
    return this.http.post((environment.api) + "account/" + id, data).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )

  }

  public deletePhoneNumber(phoneId: number, id: number) {
    return this.http.get(environment.api + 'account/' + id + '/phone/' + phoneId + '/delete/').pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public deleteEmailNumber(emailId: number, id: number) {
    return this.http.get(environment.api + 'account/' + id + '/email/' + emailId + '/delete/').pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public deleteAddressNumber(AddressId: number, id: number) {
    return this.http.get(environment.api + 'account/' + id + '/address/' + AddressId + '/delete/').pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public createNewSmsAccount(data: accountData) {
    return this.http.post(environment.api + 'account/', data).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }

  public deleteSMSAccount(accountId: number) {
    return this.http.get(environment.api + 'account/' + accountId + '/delete').pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }

  public getManageRole(payload: search) {
    return this.http.post(environment.api + 'account/' + this.auth.account_id + '/role/search/', payload).pipe(
      catchError((error) => {
        return throwError(() => error)
      })
    )
  }

  public manageRoleSetting() {
    return this.http.get(environment.api + 'account/' + this.auth.account_id + '/settings').pipe(
      catchError((error) => {
        return throwError(() => error)
      })
    )

  }
  public updateRole(payload: roles, roleId: number) {
    return this.http.post(environment.api + 'account/' + this.auth.account_id + '/role/' + roleId, payload).pipe(
      catchError((error) => {
        return throwError(() => error)
      })
    )
  }

  public addRole(payload: roles) {
    return this.http.post(environment.api + 'account/' + this.auth.account_id + '/role/', payload).pipe(
      catchError((error) => {
        return throwError(() => error)
      })
    )
  }
  public roleSearch(payload: search) {
    return this.http.post(environment.api + 'account/' + this.auth.account_id + '/role/search', payload).pipe(
      catchError((error) => {
        return throwError(() => error)
      })
    )
  }

  public rolePermission(role_id: string) {
    return this.http.get(environment.api + 'account/' + this.auth.account_id + '/role/' + role_id + '/permissions').pipe(
      catchError((error) => {
        return throwError(() => error)
      })
    )
  }

  public roleUpdatePermission(payload: rolePermission, role_id: string) {
    return this.http.post(environment.api + 'account/' + this.auth.account_id + '/role/' + role_id + '/permissions', payload).pipe(
      catchError((error) => {
        return throwError(() => error)
      })
    )
  }

  public planSearch(payload: search) {
    return this.http.post(environment.api + 'plan/search/', payload).pipe(
      catchError((error) => {
        return throwError(() => error)
      })
    )
  }

  public addfilter(payload: productFilter) {
    return this.http.post(environment.api + 'productFilter/', payload).pipe(
      catchError((error) => {
        return throwError(() => error)
      })
    )
  }

  public productFilterSearch(payload: search) {
    return this.http.post<IProductFilter[]>(environment.api + 'productFilter/search/', payload).pipe(
      catchError((error) => {
        return throwError(() => error)
      })
    )
  }

  public planFilterDelete(product_filter_id: string) {
    return this.http.get(environment.api + "productFilter/" + product_filter_id  + "/delete/").pipe(
      catchError((error) => {
        return throwError(() => error)
      })
    )
  }

  public getCachedProductFilters(payload: search) {
    if (!this._cachedFilterProducts || this._cachedFilterProducts.length === 0)
      return this.productFilterSearch(payload)
        .pipe(
          map( (productFilters: IProductFilter[]) => {
            this._cachedFilterProducts = productFilters;
            this._filterProducts$.next(this._cachedFilterProducts);

            return this._cachedFilterProducts;
          }
        ));

    else
      setTimeout(() => {
        this._filterProducts$.next(this._cachedFilterProducts)
      })


    return this._filterProducts$.asObservable();
  }

  public resetProductFilterCache() {
    this._cachedFilterProducts = [];
  }

  public deleteProductFilter(filterId: number) {
    return this.http.get(environment.api + 'productFilter/' + filterId + '/delete/').pipe(
      catchError((error) => {
        return throwError(() => error)
      })
    )
  }

  public getAdminAccountInfo() {
    return this.http.get((environment.api) + `account/${this.auth.account_id}`).pipe(
      catchError((error) => {
        return throwError(() => error)
      })
    )
  }

  public roleManageSearch(payload: search) {
    return this.http.post(environment.api + 'account/search', payload).pipe(
      catchError((error) => {
        return throwError(() => error)
      })
    )
  }
  public getTrackingCode(criteria: search) {
    return this.http.post((environment.api) + `account/` + environment.INSURACTIVE_ACCOUNT_ID + `/b2bpartnerTrackingCode/search`, criteria).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  public addUpdateTrackingCode(criteria: trackCodeInterface) {
    return this.http.post((environment.api) + `account/` + criteria.account_id + `/b2bpartnerTrackingCode`, criteria).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }
  public deleteTrackingCode(tracking_code_id: number) {
    return this.http.get((environment.api) + "account/" + environment.INSURACTIVE_ACCOUNT_ID + "/b2bpartnerTrackingCode/" + tracking_code_id + "/delete")
  }

}
