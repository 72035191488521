import { DatePipe } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TOOLBAR, validatorPatterns } from '@app/constants';
import { AuthService } from '@app/service/auth.service';
import { LeadService } from '@data/services/lead/lead.service';
import * as data from '@data/states.json';
import { environment } from '@env/environment';
import { LeadDetailComponent } from '@modules/lead/components/lead-detail/lead-detail.component';
import { IEmailType } from '@shared/constants/email-type.constant';
import { IPhoneType } from '@shared/constants/phone-type.constant';
import { ConfirmDeleteComponent } from '@shared/modals/confirm-delete/confirm-delete.component';
import { CheckDobDate, CheckInvalidDate, CommonService, CompareStringsCaseInsensitive } from '@shared/services/common/common.service';
import { ToastMsgService } from '@shared/services/toastr/toast-msg.service';
import moment from 'moment';
import { Editor } from 'ngx-editor';
import { Subject, first, takeUntil } from 'rxjs';

@Component({
  selector: 'app-new-lead',
  templateUrl: './new-lead.component.html',
  styleUrls: ['./new-lead.component.scss'],
  providers: [DatePipe]
})
export class NewLeadComponent implements OnInit, OnDestroy {

  private _componentDestroyed$ = new Subject<boolean>();

  public addLeadForm!: FormGroup;
  public editor: Editor; // Editor;
  public toolbar: any = TOOLBAR;
  public statesData: any = [];
  public states = (data as any).default;
  public maxDate: Date = new Date();
  public message: any = {};
  public isb2bPartner: boolean = false;
  public b2bPartner_data: any = []
  public tracking_data: any = []
  public isButtonClicked: boolean = false;

  public phoneTypes = IPhoneType.getPhoneTypes();
  public emailTypes = IEmailType.getEmailTypes();

  constructor(
    private _authService: AuthService,
    private _datePipe: DatePipe,
    private _fb: FormBuilder,
    private _leadService: LeadService,
    private _router: Router,
    private _toasMsgService: ToastMsgService,
    private _commonService: CommonService,
    public dialogRef: MatDialogRef<NewLeadComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public leadData: any
  ) {
    this.editor = new Editor();

    this.addLeadForm = this._fb.group({
      fname: ['', Validators.required],
      lname: ['', Validators.required],
      b2bpartner_id: [''],
      source_code: [''],
      phone_type: ['home'],
      phone: [''],
      email_type: ['home'],
      email: ['', [Validators.pattern(validatorPatterns.EMAIL)]],
      dob: ['', [CheckInvalidDate, CheckDobDate]],
      gender: [''],
      zip: [''],
      county: [''],
      state_id: [''],
      address: [''],
      notes: ['']
    });

  }

  ngOnInit(): void {
    if (this._authService.user_details['account_id'] == environment.INSURACTIVE_ACCOUNT_ID) {
      this.isb2bPartner = true;
      this.addLeadForm.controls["b2bpartner_id"].setValidators(Validators.required);
      this.addLeadForm.controls["source_code"].setValidators(Validators.required);

      this.addLeadForm.controls["b2bpartner_id"].updateValueAndValidity();
      this.addLeadForm.controls["source_code"].updateValueAndValidity();
      this.getB2BPartner();
    }

    if (this.leadData)
      this.addLeadData();
  }

  public ngOnDestroy(){
    this._componentDestroyed$.next(true);
    this._componentDestroyed$.complete();
  }


  private getB2BPartner() {
    this._leadService.getb2bpartner('new-lead')
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe({
        next: (res: any) => {
          if (res != '' && res.length > 0)
            this.b2bPartner_data = res;
        },
        error: (err: any) => {
          this.message = {
            type: 'alert',
            text: err?.message,
          };
          this.clearMessage(30000);
        }
      });
  }

  public b2bChange(b2bpartner_id: string) {
    this.getTrackingCode(b2bpartner_id);
  }

  private getTrackingCode(b2bpartner_id: string) {
    this._leadService.getTrackingCode(b2bpartner_id)
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe({
        next: (res: any) => {
          if (res != '' && res.length > 0)
            this.tracking_data = res;
        }, error: (err: any) => {
          this.message = {
            type: 'alert',
            text: err?.message,
          };
          this.clearMessage(30000);
        }
      });
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.addLeadForm.controls[controlName].hasError(errorName);
  }

  public addLead(type: string) {
    let formValue = this.addLeadForm.value;
    let height: any;
    if (this.leadData?.form?.length) {
      height = this.leadData?.form?.[0]?.height_feet != '' || this.leadData?.form?.[0]?.height_inches != ''
        ? Number(this.leadData?.form?.[0]?.height_feet * 12) + Number(this.leadData?.form?.[0]?.height_inches)
        : '';

    }
    let params = {
      fname: formValue?.fname,
      lname: formValue?.lname,
      ...(this.leadData?.form?.length && this.leadData?.form?.[0]?.weight) && { weight: this.leadData?.form?.[0]?.weight },
      ...(this.leadData?.form?.length && this.leadData?.form?.[0]?.part_a_date) && { medicare_parta_date: moment(this.leadData?.form?.[0]?.part_a_date).format('MM/DD/YYYY') },
      ...(this.leadData?.form?.length && this.leadData?.form?.[0]?.part_b_date) && { medicare_partb_date: moment(this.leadData?.form?.[0]?.part_b_date).format('MM/DD/YYYY') },
      ...(this.leadData?.form?.length && (this.leadData?.form?.[0]?.height_feet || this.leadData?.form?.[0]?.height_inches)) && { height: height },
      ...(formValue?.b2bpartner_id) && { b2bpartner_id: formValue?.b2bpartner_id },
      ...(formValue?.source_code) && { source_code: formValue?.source_code },
      ...(formValue?.gender) && { gender: formValue?.gender },
      ...(formValue?.dob) && { dob: this._datePipe.transform(formValue?.dob, 'MM/dd/yyyy') }, //change date format
      ...(formValue?.phone) && {
        phones: [{
          phone_type: formValue?.phone_type,
          phone: formValue?.phone.replace("(", "").replace(")", "").replace(" ", "").replace("-", "") // remove masked values
        }
        ],
      },
      ...(formValue?.email) && {
        emails: [{
          email_type: formValue?.email_type,
          email: formValue?.email
        }
        ],
      },
      ...(formValue?.county || this.leadData) && {
        addresses: [{
          address_type_id: "home",
          zip: formValue?.zip ? formValue?.zip : '',
          county: formValue?.county ? formValue?.county : '',
          state_id: formValue?.state_id ? formValue?.state_id : ''
        }
        ]
      },
      user_id: this._authService.getToken().user_id,
      account_id: `${this._authService.account_id}`,
      lead_type_id: "1",
    }

    this.isButtonClicked = true;

    this._leadService.addNewLead(params)
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe({
        next: (res: any) => {
          this.isButtonClicked = false;
          this.showToast("Lead created successfully!");
          //condition for opening the popup through quotes module
          if (this.leadData) {
            this._leadService.leadSelected = true;
            this._leadService.lead_details = res;
            // Code to check user clicked on ADD (simple) button or Add + Detail button
            if (type == 'simple') {
              this.dialog.closeAll();
              if (formValue.notes) this.noteSave(res.lead_id, formValue.notes);
            } else {
              window.scroll(0, 0);
              const dialogRef = this.dialog.open(LeadDetailComponent, {
                data: res.lead_id
              });
              dialogRef.afterClosed().subscribe((elem: any) => {
                if (formValue.notes) this.noteSave(res.lead_id, formValue.notes);
                //window.location.href = 'lead/' + res.lead_id;
                this._router.navigate(['lead/' + res.lead_id]);
                this._leadService.sendLeadLedger("refreshLead");
              })
            }
          }
          //condition for opening the popup through leads module
          else {
            if (type == 'simple') {
              this.dialog.closeAll();
              if (formValue.notes) this.noteSave(res.lead_id, formValue.notes);
              //window.location.href = 'lead/' + res.lead_id;
              this._router.navigate(['lead/' + res.lead_id]);
              this._leadService.sendLeadLedger("refreshLead");
            } else {
              window.scroll(0, 0);
              const dialogRef = this.dialog.open(LeadDetailComponent, {
                data: res.lead_id
              });
              dialogRef.afterClosed().subscribe((elem: any) => {
                if (formValue.notes) this.noteSave(res.lead_id, formValue.notes);
                //window.location.href = 'lead/' + res.lead_id;
                this._router.navigate(['lead/' + res.lead_id]);
                this._leadService.sendLeadLedger("refreshLead");
              })
            }
          }
        }, error: (err: any) => {
        this.isButtonClicked = false;
        this.message = {
          type: 'alert',
          text: err?.error?.message,
        };
        var fullErrorMessage = "";
        for (var r in err.error.result) {
					if (!err.error.result.hasOwnProperty(r)) continue;
          for (var e = 0; e < err.error.result[r].length; e++) {
            fullErrorMessage = fullErrorMessage + err.error.result[r][e].MESSAGE + ". "
					}
          this.message = {
            type: 'alert',
            text: err?.error?.message + ' ' + fullErrorMessage,
          };
        }
        this.clearMessage(30000);
      }
    });
  }

  public noteSave(lead_id: string, noteValue: string) {
    let payload = {
      lead_id: lead_id,
      note: noteValue,
      user_id: this._authService.getToken().user_id
    }
    this._leadService.addConversationNotes(payload).pipe(first()).subscribe({
      next: (response: any) => {
        if (response.length > 0) {}
        this._leadService.sendRefreshConvTable('refreshTable');
      },
      error: (err: any) => {
        this._toasMsgService.showError(err?.error?.message);
      }
    })
  }

  /* clear notice message */
  public clearMessage(timer: number) {
    setTimeout(() => {
      this.message = this._toasMsgService.resetMessage();
    }, timer);
  }

  private closeDialog() {
    this.dialogRef.close(true);
  }

  private showToast(msg: string) {
    this.closeDialog();
  }

  // get countries according to zip code
  public getCounties() {
    if (this.addLeadForm?.value?.zip?.length == 5) {
      this._leadService.getCountiesByZip(this.addLeadForm?.value?.zip)
        .pipe(takeUntil(this._componentDestroyed$))
        .subscribe({
          next: (res: any) => {
            if (res.length > 0) {
              this.addLeadForm.patchValue({ county: '', state_id: '' });
              this.statesData = res || [];
              if (this.statesData?.length) {
                if (this.statesData?.length < 2) {
                  if (this.statesData[0]?.counties.length < 2) {
                    /* set county and state's value according to zip code */
                    this.addLeadForm.patchValue({
                      county: this.statesData[0]?.counties[0]?.county_name,
                      state_id: this.statesData[0]?.state_id
                    });
                  } else {
                    /* set state's value according to zip code */
                    this.addLeadForm.patchValue({ state_id: this.statesData[0]?.state_id });
                    this._commonService.countyStateAlert(this.statesData)
                  }
                } else {
                  this._commonService.countyStateAlert(this.statesData)
                }
              }
            } else {
              const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
                width: '30%', disableClose: true, autoFocus: false,
                data: {
                  'text': 'The zip code you entered (' + this.addLeadForm?.value?.zip + ') cannot be found in our system.',
                  'type': 'ok'
                }
              });
              dialogRef.afterClosed().subscribe(() => {
                this.addLeadForm.patchValue({ county: '', state_id: '', zip: '' });
              })
            }
          }, error: (err: any) => {
            this.message = {
              type: 'alert',
              text: err?.error?.message,
            };
            this.clearMessage(30000);
          }
        });
    } else {
      this.addLeadForm.patchValue({ county: '', state_id: '' });
    }
  }

  //method to patch the medsup result data in new lead form
  private addLeadData() {
    if (this.leadData?.form?.length >= 1) {
      let dob=""
      if(this.leadData?.type == 'fex'){
        dob = this.leadData?.form?.[0]?.dob ?
        moment(this.leadData?.form?.[0]?.dob).format('MM/DD/YYYY') :
        moment().subtract(this.leadData?.form?.[0]?.age, "y").startOf('year').format("MM/DD/YYYY")
      }else{
        dob = this.leadData?.form?.[0]?.dob ? moment(this.leadData?.form?.[0]?.dob).format('MM/DD/YYYY') : ''
      }
      this.addLeadForm.patchValue({
        dob: dob,
        gender: this.leadData?.form?.[0]?.gender,
        zip: this.leadData?.form?.[0]?.zip,
        state_id: this.leadData?.form[0]?.state?.state_id ? this.leadData?.form[0]?.state?.state_id : this.leadData?.form[0]?.state_id ? this.leadData?.form[0]?.state_id : this.leadData?.form[0]?.state,
      })
      this.setCounty();
    }
  }

  public changeOption(country: any) {
    this.addLeadForm.patchValue({ state_id: country.state_id });
  }

  public changeState(event: any) {
    this.addLeadForm.patchValue({ zip: '', county: '' })
  }

  private setCounty() {
    if (this.leadData?.form?.[0]?.zip?.length == 5) {
      this._leadService.getCountiesByZip(this.leadData?.form?.[0]?.zip)
        .pipe(takeUntil(this._componentDestroyed$))
        .subscribe({
          next: (res: any) => {
            if (res.length > 0) {
              this.addLeadForm.patchValue({ county: '' });
              this.statesData = res || [];
              this.addLeadForm.patchValue({
                county: this.leadData?.form?.[0].county
              });
              //check if imported leads county is invalid
              let checkCountyExists = null;
              for (let state of this.statesData) {
                checkCountyExists = state.counties.find(
                  (county: { county_name: any; }) => CompareStringsCaseInsensitive(county.county_name, this.addLeadForm.get('county')?.value)
                );
                if (checkCountyExists) {
                  this.addLeadForm.patchValue({
                    county: checkCountyExists.county_name
                  });
                  break;
                }
              }

              if(checkCountyExists?.length==0) {
                // County not found in the 'stateCounties' data
                this.addLeadForm.patchValue({ county: '' });
              }
            }
          }, error: (err: any) => {
            this.message = {
              type: 'alert',
              text: err?.error?.message,
            };
            this.clearMessage(30000);
          }
        });
    }
  }


}
