<div id="configurable-settings-setup" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <div cdkDragHandle class="dialog-title" mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
      <h2 mat-dialog-title>Configurable Settings</h2>
      <button mat-icon-button mat-dialog-close aria-label="Close Modal"><mat-icon>close</mat-icon></button>
  </div>
    <mat-dialog-content class="mat-typography">
        <ng-container *ngIf="message.type">
            <app-sms-notice-banner #messageBanner [type]="message.type" (closeBanner)="clearMessage(0)">
                <p>{{ message.text }}</p>
            </app-sms-notice-banner>
        </ng-container>
        <mat-tab-group mat-dialog-content class="mat-dialog-content admin-sub-tab uppercase">
            <mat-tab label="Client Self-Service">
                <div class="mat-tab-content">

                    <ng-container *ngIf="isLoading; else configurableSettings">
                        <app-sms-spinner></app-sms-spinner>
                    </ng-container>

                    <ng-template #configurableSettings>
                        <form name="configurableSettingForm" id="configurableSettingForm" [formGroup]="configurableSettingForm">
                            <span class="flex items-baseline">
                                <p class="config-text">Send automated reminder emails for Client Self-Service invites?</p>
                                <button mat-icon-button type="button" color="basic" matTooltip='If reminder emails are turned ON, your client will receive an automatic reminder email seven days after receiving their Client Self-Service invitation, if they do not submit a response before then. They will receive a second and final reminder email three days after the first reminder, if they do not submit a response after the first reminder. Reminder emails will be identical to the initial invitation, with the word "REMINDER" in the subject line.' matTooltipPosition="right" [matTooltipShowDelay]="800">
                                    <mat-icon class="text-emerald-800">help</mat-icon>
                                </button>
                            </span>

                            <mat-radio-group aria-label="Send automated reminder emails for Client Self-Service invites?" formControlName="sspr_configurable_settings">
                                <mat-radio-button value="1" class="mr-8">Yes</mat-radio-button>
                                <mat-radio-button value="0" class="mr-8">No</mat-radio-button>
                            </mat-radio-group>
                            <br>
                            <!--https://leadadvantage.atlassian.net/browse/SSPUP-47 -->
                            <span class="flex items-baseline">
                                <p class="config-text">Allow clients to update Personal Information in Section 1 of Client Self-Service?</p>
                                <button mat-icon-button type="button" color="basic" matTooltip='If Personal Information updates are turned ON, your client will be able to update their Personal Information in Section 1 of Client Self-Service. You will be able to see the client changes in a ledger on Lead Details.' matTooltipPosition="right" [matTooltipShowDelay]="800">
                                    <mat-icon class="text-emerald-800">help</mat-icon>
                                </button>
                            </span>
                            <p class="config-text italic text-red">NOTE: A change to this Personal Information setting only applies to new Client Self-Service links sent out. Any existing
                            links will not follow the new permission setting.</p>
                            <mat-radio-group aria-label="Allow clients to update Personal Information in Section 1 of Client Self-Service?" formControlName="sspup_configurable_settings">
                                <mat-radio-button value="1" class="mr-8">Yes</mat-radio-button>
                                <mat-radio-button value="0" class="mr-8">No</mat-radio-button>
                            </mat-radio-group>

                        </form>
                    </ng-template>

                </div>
            </mat-tab>
        </mat-tab-group>
    </mat-dialog-content>

  <div mat-dialog-actions *ngIf="configurableSettingForm.dirty" align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button color="primary" mat-button mat-dialog-close cdkFocusInitial
      (click)="selfServiceConfigUpdate(configurableSettingForm.value.sspr_configurable_settings, configurableSettingForm.value.sspup_configurable_settings);$event.preventDefault();">Update</button>
  </div>
</div>
