import { AuthService } from "@app/service/auth.service";
import { LaunchdarklyService } from "@shared/services/LaunchDarkly/launchdarkly.service";

export function initializeAppFactory(
  authService: AuthService,
  launchDarylySvc: LaunchdarklyService,
): () => Promise<any> {

  return (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        launchDarylySvc.initialize(authService.user_details) ? resolve(true) : reject(false);
      } catch (error) {
        reject(error);
      }
    });
  };
};