export class IEmailType {
    public static Home: string = 'Home';
    public static Work: string = 'Work';
    public static Other: string = 'Other';

    public static getEmailTypes() {
        return [
            { id: IEmailType.Home, value: IEmailType.Home.toLowerCase() },
            { id: IEmailType.Work, value: IEmailType.Work.toLowerCase() },
            { id: IEmailType.Other, value: IEmailType.Other.toLowerCase() },
        ]
    }

}
