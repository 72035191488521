import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from '@data/services/user/user.service';
import { Subscription } from 'rxjs';
import { AssignLeadAlertComponent } from '../assign-lead-alert/assign-lead-alert.component';

@Component({
  selector: 'app-delete-agent',
  templateUrl: './delete-agent.component.html',
  styleUrls: ['./delete-agent.component.scss']
})
export class DeleteAgentComponent implements OnInit {
  private deleteCarrierSubs: Subscription;
  private deleteIds: any = []
  public agentToDelete: any = []
  public isLoading: any = false
  public errorMsg: string | null = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AssignLeadAlertComponent>,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.agentToDelete = this.data
    this.getAllDeleteIds()
  }

  // method to get ids for deletion
  private getAllDeleteIds() {
    this.data.map((elem: any) => {
      this.deleteIds.push(elem.user_carrier_id)
    })
  }

  // method to call the delete API
  public deleteAgent() {
    this.isLoading = true
    let payload = {
      user_carrier_ids: this.deleteIds
    }
    this.deleteCarrierSubs = this.userService.deleteCarrier(payload).subscribe({
      next: (elem: any) => {
        this.isLoading = false
        this.dialogRef.close(elem)

      },
      error: (err) => {
        this.isLoading = false
        this.showErrorMessage(err.error.message)
        this.dialogRef.close()
      },
      complete: () => { }
    })
  }

  private showErrorMessage(errorMsg: string) {
    this.errorMsg = errorMsg;
    setTimeout(() => {
      this.errorMsg = null;
    }, 3000);
  }

  public clearMessage() {
    this.errorMsg = null;
  }
  ngOnDestroy() {
    if (this.deleteCarrierSubs)
      this.deleteCarrierSubs.unsubscribe();
  }

}
