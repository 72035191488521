import { Component, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { RegistrationService } from '@data/services/registration/registration.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-user-agreement',
  templateUrl: './user-agreement.component.html',
  styleUrls: ['./user-agreement.component.scss']
})
export class UserAgreementComponent implements OnInit, OnDestroy {

  private _componentDestroyed$ = new Subject<boolean>();

  public document: SafeHtml;
  public isLoading: boolean = true;

  constructor(
    private _registrationService: RegistrationService,
    private _sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this._registrationService.getUserAgreement()
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe({
        next: (userAgreement: any) => {
          this.isLoading = false;
          this.document = this._sanitizer.bypassSecurityTrustHtml(userAgreement.agreement);
        }
      });
  }

  ngOnDestroy(): void {
    this._componentDestroyed$.next(true);
    this._componentDestroyed$.complete();
  }

}
