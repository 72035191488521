import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { CarrierService } from '@data/carrier/carrier.service';
import { AdminService } from '@data/services/admin/admin.service';
import { LeadService } from '@data/services/lead/lead.service';
import { UserService } from '@data/services/user/user.service';
import { ToastMsgService } from '@shared/services/toastr/toast-msg.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-agent-writing-number',
  templateUrl: './agent-writing-number.component.html',
  styleUrls: ['./agent-writing-number.component.scss']
})
export class AgentWritingNumberComponent implements OnInit {
  private agentCarrierSubs: Subscription;
  private productTypeSubs: Subscription;
  private campaignSegmentSubs: Subscription;
  private listActiveBySubs: Subscription;
  private addCarrierSubs: Subscription;
  private productName: any = [];
  private selectedCarrierId: number;
  private selectedProductId: number;
  private formData: any = [];
  public agentWritingForm: UntypedFormGroup;
  public carrierData: any = [];
  public productType: any = [];
  public showDefault: boolean = true;
  public activeProduct: any = [];
  public inactiveProduct: any = [];
  public year: number;
  public year1: number;
  public message: any = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public user_id: any,
    private adminService: AdminService,
    private _carrierSvc: CarrierService,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<AgentWritingNumberComponent>,
    private userService: UserService,
    private leadService: LeadService,
    private toastMsgService: ToastMsgService
  ) {
    this.agentWritingForm = this.fb.group({
      name: new FormControl('', Validators.required),
      product_type_desc: new FormControl('', Validators.required),
      agent_writing_number: new FormControl('', Validators.required),
      certification_year: new FormControl('')
    })
  }

  ngOnInit(): void {
    this.getAgentCarrier()
    this.getProductType()
    this.getCampaignSegment()
    this.getCertificationYear()
  }
  // method to get agent carrier
  private getAgentCarrier() {
    let payload = {
      criteria: {
        carrier_id: {
          type: "range",
          value: ["1", "*"]
        }
      },
      field_list: "name,product_id,product_name,carrier_id",
      page_number: 1,
      search_type: "simple"
    }
    this.agentCarrierSubs = this._carrierSvc.getCarriers(payload).subscribe({
      next: (elem) => {
        this.carrierData = elem
        this.agentWritingForm.patchValue({
          name: this.carrierData.name
        })
      },
      error: (err) => {
        this.message = this.toastMsgService.showError(err.error.message, true);
        this.clearMessage(30000);
      },
      complete: () => { }

    })
  }

  // method to get product type
  private getProductType() {
    let payload = {
      criteria: {
        product_type_id: {
          type: "range",
          value: [1, "*"]
        }
      },
      field_list: "",
      search_type: "simple"
    }
    this.productTypeSubs = this.leadService.getProductType(payload).subscribe({
      next: (elem) => {
        if (elem) {
          this.productType = elem
          this.agentWritingForm.patchValue({
            product_type_desc: this.productType.product_type_desc
          })
        }
      },
      error: (err) => {
        this.message = this.toastMsgService.showError(err.error.message, true);
        this.clearMessage(30000);
      },
      complete: () => { }
    })
  }

  // method to get campaign segment
  private getCampaignSegment() {
    let payload = {
      criteria: {
        campaign_id: {
          type: "range",
          value: [1, "*"]
        }
      },
      field_list: "",
      search_type: "simple"
    }
    this.campaignSegmentSubs = this.leadService.getCampaignSegment(payload).subscribe({
      next: (elem: any) => {
      },
      error: (err) => {
        this.message = this.toastMsgService.showError(err.error.message, true);
        this.clearMessage(30000);
      },
      complete: () => { }

    })
  }

  //method to get active product types for carrier as per selection
  public listActiveByCarrier(event: MatSelectChange) {
    let filteredData = this.carrierData.filter((res: any) => res.name == event.value)
    this.selectedCarrierId = filteredData[0].carrier_id
    if (this.selectedCarrierId) {
      let payload = {
        carrier_ids: this.selectedCarrierId
      }
      this.listActiveBySubs = this.adminService.listActiveByCarrier(payload).subscribe({
        next: (elem) => {
          this.activeProduct = elem
          this.showDefault = false
          let activeNames = this.activeProduct.map((elem: any) => { return elem.product_type_desc })
          this.productName = this.productType.map((elem: any) => { return elem.product_type_desc })
          this.inactiveProduct = this.productName.filter((elem: any) => { return activeNames.indexOf(elem) == -1 })
        },
        error: (err) => {
          this.message = this.toastMsgService.showError(err.error.message, true);
          this.clearMessage(30000);
        },
        complete: () => { }

      })

    }
  }

  // method to get product type id for adding agent
  public productTypeId(event: MatSelectChange) {
    let filteredData = this.productType.filter((res: any) => res.product_type_desc == event.value)
    this.selectedProductId = filteredData[0].product_type_id
  }

  // method to add agent
  public addAgent() {
    if (this.agentWritingForm.valid) {
      this.formData = this.agentWritingForm.value
      let data = {
        agent_writing_number: this.formData.agent_writing_number,
        carrier_id: this.selectedCarrierId,
        certification_year: this.formData.certification_year,
        product_type_id: this.selectedProductId
      }
      if (data) {
        this.addCarrierSubs = this.userService.addCarrier(data, this.user_id).subscribe({
          next: (elem) => {
            if (elem) {
              this.message = this.toastMsgService.showSuccess("Agent writing number has been added successfully", true);
              this.clearMessage(15000);
              setTimeout(() => {
                this.dialogRef.close(elem)
              }, 2000)

            }
          },
          error: (err) => {
            this.message = this.toastMsgService.showError("Agent Writing Number is already present for carrier with this product type", true);
            this.clearMessage(30000);
            setTimeout(() => {
              this.dialogRef.close()
            }, 2000)
          },
          complete: () => { }
        })
      }
    }
  }
  //method to get certification year
  private getCertificationYear() {
    this.year = new Date().getFullYear()
    this.year1 = new Date().getFullYear() + 1
  }

  /* clear notice message */
  public clearMessage(timer: number) {
    setTimeout(() => {
      this.message = this.toastMsgService.resetMessage();
    }, timer);
  }

  ngOnDestroy() {
    if (this.agentCarrierSubs)
      this.agentCarrierSubs.unsubscribe();
    if (this.productTypeSubs)
      this.productTypeSubs.unsubscribe();
    if (this.campaignSegmentSubs)
      this.campaignSegmentSubs.unsubscribe();
    if (this.listActiveBySubs)
      this.listActiveBySubs.unsubscribe();
    if (this.addCarrierSubs)
      this.addCarrierSubs.unsubscribe();
  }
}
