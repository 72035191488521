<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="custom-modal" appDialogScroll>
  <div class="loading-indicator" *ngIf="isLoading">
    <app-sms-spinner>
    </app-sms-spinner>
  </div>

  <div class="dialog-title" cdkDragHandle>
    <h2 mat-dialog-title>USER PROFILE</h2>
    <mat-icon (click)="captureClose()" class="cursor-pointer">close</mat-icon>
  </div>
  <mat-divider></mat-divider>
  <div fxLayout="row wrap" class="update_msg"
    *ngIf="(hasChange && userData?.user_id && userProfileForm.valid && initialChange); else elseBlock">
    <p>Changes have been made. Click <span class="upperUpdateLink"
        (click)="update(userProfileForm.value,'updateOnly')">"UPDATE"</span> to save them</p>
  </div>
  <ng-template #elseBlock>
    <ng-container *ngIf="message?.type">
      <app-sms-notice-banner #messageBanner [type]="message.type" (closeBanner)="clearMessage(0)">
        <p>{{ message.text }}</p>
      </app-sms-notice-banner>
    </ng-container>
  </ng-template>
  <mat-dialog-content class="mat-typography">
    <div class="basic-container lead-body">
      <form [formGroup]="userProfileForm">
        <div fxLayout="row wrap">
          <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
            <div fxLayout="row wrap">
              <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                <h4 class="user-profile-heading">USER INFORMATION</h4>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="3.5%" fxLayoutAlign="left">
                  <mat-form-field class="example-full-width" fxFlex="{{userData?.user_id ? '20%' : '33%'}}">
                    <mat-label>First Name</mat-label>
                    <input (mousedown)="$event.stopPropagation()" matInput autocomplete="off" formControlName="fname"
                      [appInputMaxLength] appDisableAutofill>
                    <mat-error *ngIf="userProfileForm.get('fname')?.errors && submitted">
                      Please enter first name
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="example-full-width" fxFlex="{{userData?.user_id ? '20%' : '33%'}}">
                    <mat-label>Last Name</mat-label>
                    <input (mousedown)="$event.stopPropagation()" matInput autocomplete="off" formControlName="lname"
                      [appInputMaxLength] appDisableAutofill>
                    <mat-error *ngIf="userProfileForm.get('lname')?.errors && submitted">
                      Please enter last name
                    </mat-error>
                  </mat-form-field>
                  <p *ngIf="userData?.user_id"><span class="fw-600 custom-font">Account Owner</span><span
                      [ngClass]="{'account-no': userDetailArray?.user_id != accountDetail?.account_owner_user_id,'account-yes': userDetailArray?.user_id == accountDetail?.account_owner_user_id}">
                      {{isAccountOwner}}</span></p>
                </div>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="3.5%" fxLayoutAlign="left">
                  <mat-form-field class="example-full-width" fxFlex="30%">
                    <mat-label>Gender</mat-label>
                    <mat-select disableOptionCentering matNativeControl formControlName="gender">
                      <mat-option value="m">Male
                      </mat-option>
                      <mat-option value="f">Female
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="example-full-width" fxFlex="30%">
                    <mat-label>Company</mat-label>
                    <input (mousedown)="$event.stopPropagation()" matInput autocomplete="off" formControlName="company"
                      [appInputMaxLength] appDisableAutofill>
                  </mat-form-field>
                </div>
              </div>
              <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                <div fxLayout="row wrap">
                  <div *ngIf="isCommonFlag" fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                    <app-phone formArrayName="phones" [type]="userData.user_id ? 'profile' : 'add-user'"></app-phone>
                  </div>
                </div>
              </div>
              <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                <div fxLayout="row wrap">
                  <div *ngIf="isCommonFlag" fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                    <app-email formArrayName="emails" [type]="userData.user_id ? 'profile' : 'add-user'"></app-email>
                  </div>
                </div>
              </div>
              <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                <div fxLayout="row wrap">
                  <div *ngIf="isCommonFlag" fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                    <app-address formArrayName="addresses"
                      [type]="userData.user_id ? 'profile' : 'add-user'"></app-address>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
            <div fxLayout="row wrap">
              <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                <div class="user-details" fxLayout="row" fxLayout.xs="column" fxLayoutGap="3.5%" fxLayoutWrap
                  fxLayoutAlign="end">
                  <h4 fxlayout class="user-profile-heading">USER DETAILS</h4>
                  <div fxLayout="row" class="right">
                    <h4 class="user-profile-heading activate" *ngIf="userData?.isFromAdmin && userData?.user_id">
                      Activate
                    </h4>
                    <mat-slide-toggle color="primary" *ngIf="userData?.isFromAdmin && userData?.user_id"
                      class="activate-toggle mt-2" [(ngModel)]="userProfileForm.value.active"
                      [ngModelOptions]="{standalone: true}" formControlName="active">
                    </mat-slide-toggle>
                  </div>
                </div>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="left">
                  <mat-form-field class="example-full-width inactive_area" fxFlex="40%">
                    <mat-label>Username</mat-label>
                    <input (mousedown)="$event.stopPropagation()" matInput autocomplete="off" formControlName="username"
                      readonly class="not-allowed" [tabindex]="-1">
                  </mat-form-field>
                  <mat-form-field class="example-full-width inactive_area" fxFlex="25%">
                    <mat-label>User ID</mat-label>
                    <input (mousedown)="$event.stopPropagation()" matInput autocomplete="off" formControlName="user_id"
                      readonly class="not-allowed" [tabindex]="-1">
                  </mat-form-field>
                  <mat-form-field
                    [class]="userData?.user_id == authService.getToken().user_id ? 'example-full-width inactive_area' : 'example-full-width'"
                    fxFlex="25%"
                    [title]="userData?.user_id == authService.getToken().user_id ? 'User doesnt have permission to change the role' : ''">
                    <mat-label>Role Type</mat-label>
                    <input matInput autocomplete="off" formControlName="role_name"
                      *ngIf="userData?.user_id == authService.getToken().user_id" readonly class="not-allowed" [tabindex]="-1">
                    <mat-select disableOptionCentering matNativeControl formControlName="role_name"
                      *ngIf="userData?.user_id != authService.getToken().user_id">
                      <mat-option *ngFor="let elem of rolesData" [value]="elem.role_name">
                        {{elem.role_name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="3.5%" fxLayoutWrap fxLayoutAlign="left"
                  class="inactive_area">
                  <mat-form-field class="example-full-width" fxFlex="40%">
                    <mat-label> Last Activate/Deactivate Date</mat-label>
                    <input (mousedown)="$event.stopPropagation()" matInput autocomplete="off"
                      formControlName="last_active_date_local" readonly class="not-allowed" [tabindex]="-1">
                  </mat-form-field>
                  <mat-form-field class="example-full-width" fxFlex="25%">
                    <mat-label>Registration Date</mat-label>
                    <input (mousedown)="$event.stopPropagation()" matInput autocomplete="off"
                      formControlName="registration_date" readonly class="not-allowed" [tabindex]="-1">
                  </mat-form-field>
                  <mat-form-field class="example-full-width" fxFlex="25%">
                    <mat-label>Previous Login</mat-label>
                    <input (mousedown)="$event.stopPropagation()" matInput autocomplete="off"
                      formControlName="last_access_date" readonly class="not-allowed" [tabindex]="-1">
                  </mat-form-field>
                </div>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="3.5%" fxLayoutWrap fxLayoutAlign="left">
                  <mat-form-field class="example-full-width" fxFlex="40%">
                    <mat-label>NPN</mat-label>
                    <input (mousedown)="$event.stopPropagation()" matInput autocomplete="off" formControlName="npn"
                      [appInputMaxLength]>
                  </mat-form-field>
                  <mat-form-field class="example-full-width"
                    [class]="userData?.type!='sms-users' ? 'inactive_area' : ''" fxFlex="25%">
                    <mat-label>Reference ID</mat-label>
                    <input (mousedown)="$event.stopPropagation()" matInput autocomplete="off"
                      formControlName="reference_id" [readonly]="userData?.type!='sms-users'"
                      [class]="userData?.type!='sms-users' ? 'not-allowed': ''" [tabindex]="userData?.type!='sms-users' ? -1 : 0">
                  </mat-form-field>
                </div>
              </div>
              <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                <h4 class="user-profile-heading">ACCOUNT</h4>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="3.5%" fxLayoutAlign="left">
                  <mat-form-field class="example-full-width inactive_area " fxFlex="40%">
                    <mat-label>Account ID</mat-label>
                    <input (mousedown)="$event.stopPropagation()" matInput autocomplete="off"
                      formControlName="account_id" readonly class="not-allowed" [tabindex]="-1">
                  </mat-form-field>
                  <mat-form-field class="example-full-width inactive_area " fxFlex="40%">
                    <mat-label>Account Owner Name</mat-label>
                    <input (mousedown)="$event.stopPropagation()" matInput autocomplete="off"
                      formControlName="account_business_name" readonly class="not-allowed" [tabindex]="-1">
                  </mat-form-field>
                </div>
              </div>
              <div *ngIf="!userData?.user_id" fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                <h4 class="user-profile-heading text-upper">User Name</h4>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="3.5%" fxLayoutAlign="left">
                  <mat-form-field class="example-full-width" fxFlex="40%">
                    <mat-label>User Name</mat-label>
                    <input matInput autocomplete="off" name="login"
                      maxlength="15" formControlName="loginName" minlength="6" pattern="[a-zA-Z0-9]*">
                        <mat-hint class="hint">Must be between 6-15 alphanumeric characters.</mat-hint>
                        <mat-error *ngIf="userProfileForm.get('loginName')?.invalid">User Name must be <strong>6-15 letters or numbers</strong>.</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div *ngIf="!userData?.isFromAdmin || userData?.type == 'sms-users'" fxFlex.gt-lg="100" fxFlex.gt-md="100"
                fxFlex.gt-xs="100" fxFlex="100">
                <h4 class="user-profile-heading">UPDATE PASSWORD</h4>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="3.5%" fxLayoutAlign="left"
                  class="showHideCred">
                  <mat-form-field *ngIf="!userData?.isFromAdmin" class="example-full-width" fxFlex="25%">
                    <mat-label>Current Password</mat-label>
                    <input (mousedown)="$event.stopPropagation()" matInput autocomplete="off"
                      formControlName="currentpassword" [type]="inputTypeCurrent">
                    <mat-icon matSuffix (click)="hideShowPassword('current')" class="showHideIcon">{{showCurrentPassword
                      ?'visibility_off':'visibility'}}</mat-icon>
                    <mat-error *ngIf="userProfileForm.get('currentpassword')?.errors?.invalid">The current password
                      entered is incorrect</mat-error>
                  </mat-form-field>
                  <mat-form-field class="example-full-width" fxFlex="25%">
                    <mat-label>New Password*</mat-label>
                    <input (mousedown)="$event.stopPropagation()" [type]="inputTypeNew" matInput autocomplete="off"
                      formControlName="newpassword">
                    <mat-icon matSuffix (click)="hideShowPassword('new')" class="showHideIcon">{{showNewPassword
                      ?'visibility_off':'visibility'}}</mat-icon>
                  </mat-form-field>
                  <mat-form-field class="example-full-width" fxFlex="30%">
                    <mat-label>Confirm Password*</mat-label>
                    <input (mousedown)="$event.stopPropagation()" [type]="inputTypeConfirm" matInput autocomplete="off"
                      formControlName="confirmpassword">
                    <mat-icon matSuffix (click)="hideShowPassword('confirm')" class="showHideIcon">{{showConfirmPassword
                      ?'visibility_off':'visibility'}}</mat-icon>
                    <mat-error *ngIf="userProfileForm.get('confirmpassword')?.errors?.confirmedValidator">New Password
                      and Confirm Password must be match.</mat-error>
                  </mat-form-field>
                </div>

                <div *ngIf="userProfileForm.get('newpassword')?.errors?.pattern">
                  <p>Passwords must accomodate as follows</p>
                  <ul>
                    <li>At least 8 characters</li>
                    <li>At least 1 Capital Letter</li>
                    <li>At least 1 Lowercase Letter</li>
                    <li>At least 1 number</li>
                    <li>Must include special characters of @ # $ !</li>
                  </ul>
                  <p>New password does not meet the required criteria.</p>
                </div>
              </div>
              <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100"
                *ngIf="isAgentWritingNumber || !userData?.user_id">
                <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100" *ngIf="isAgentWritingNumber">
                  <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="3.5%" fxLayoutAlign="left">
                    <h4 class="user-profile-heading">AGENT WRITING NUMBERS</h4>
                    <button *ngIf="userData?.type == 'sms-users'" type="button" mat-mini-fab color="primary"
                      matTooltip="Add New" class="right" (click)="openAgentPopup()">
                      <mat-icon>add</mat-icon>
                    </button>
                  </div>
                  <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="3.5%" fxLayoutAlign="left">
                    <p>No writing numbers</p>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div *ngIf="!isAgentWritingNumber" fxLayout="row" fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100"
            fxFlex="100">
            <div fxFlex="50" *ngIf="!isAgentWritingNumber && userData?.user_id">
              <button type="button" mat-icon-button class="btnName">
                <mat-icon class="menu-button" [ngClass]="{'chevron_right' : showSubmenu}" (click)="openMenu()">
                  expand_more</mat-icon>
                <span class="user-profile-heading">AGENT WRITING NUMBERS</span>
              </button>
            </div>
            <div *ngIf="userData?.type == 'sms-users'" fxFlex="50" fxFlexAlign=" end">
              <div fxLayout="row" class="right">
                <h4 class="user-profile-heading">AWN SYNC</h4>
                <mat-slide-toggle class="awn-sync" color="primary" [(ngModel)]="userProfileForm.value.cm_sync_ignore"
                  [ngModelOptions]="{standalone: true}" formControlName="cm_sync_ignore">
                </mat-slide-toggle>
                <h5 class="user-profile-heading">{{userProfileForm.value.cm_sync_ignore == 1 ? 'ON' : 'OFF'}}</h5>
                <div class="profile-divider">|</div>
                <button type="button" mat-icon-button matTooltip="Add New" (click)="openAgentPopup()">
                  <mat-icon class="custom-icon-color inline-icon hover-icon icon-size">add</mat-icon>
                </button>
                <button type="button" mat-icon-button matTooltip="Delete" (click)="deleteAgentPopup()">
                  <mat-icon class="custom-icon-color inline-icon hover-icon icon-size">delete</mat-icon>
                </button>
              </div>
            </div>
          </div>
          <div fxLayout="row wrap" class="mb-20"
            [hidden]="(isAgentWritingNumber && !userData?.user_id) || userData?.type == 'add-user' || !applicationDataSource.filteredData.length">
            <div [hidden]="showSubmenu || userData?.type == 'add-user' || !applicationDataSource.filteredData.length">
              <div class="example-container1 mat-elevation-z8 fix-height"
                [hidden]="(isAgentWritingNumber && !userData?.user_id)|| userData?.type == 'add-user' || !applicationDataSource.filteredData.length">
                <table matSort mat-table [dataSource]="applicationDataSource" #agentWritingSort="matSort"
                  class="mat-elevation-z8  agent-table table-striped admin-table-hover table-transform ">
                  <ng-container matColumnDef="record_num" *ngIf="userData?.type == 'sms-users'">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let row">
                      <mat-checkbox [(ngModel)]="checkboxStates[row.user_carrier_id]"
                        [ngModelOptions]="{standalone: true}" (change)="getRowId($event,row)">
                        &nbsp;&nbsp;
                      </mat-checkbox>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="carrier_full_name">
                    <th class="custom-font" mat-header-cell *matHeaderCellDef mat-sort-header>Carrier Name </th>
                    <td mat-cell *matCellDef="let row"> {{row.carrier_full_name}} </td>
                  </ng-container>

                  <ng-container matColumnDef="product_type_desc">
                    <th class="custom-font" mat-header-cell *matHeaderCellDef mat-sort-header> Product Type </th>
                    <td mat-cell *matCellDef="let row"> {{row.product_type_desc}} </td>
                  </ng-container>

                  <ng-container matColumnDef="agent_writing_number">
                    <th class="custom-font" mat-header-cell *matHeaderCellDef mat-sort-header> Agent Writing Number
                    </th>
                    <td mat-cell *matCellDef="let row"> {{row.agent_writing_number}} </td>
                  </ng-container>

                  <ng-container matColumnDef="certification_year">
                    <th class="custom-font" mat-header-cell *matHeaderCellDef mat-sort-header> Plan Year(s) </th>
                    <td mat-cell *matCellDef="let row"> {{row.certification_year ? row.certification_year : 'N/A'}}
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="applicationDisplayedColumns; sticky: true"></tr>
                  <tr mat-row *matRowDef="let row; columns: applicationDisplayedColumns;">
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div class="mat-paginator-container"
            [hidden]="(isAgentWritingNumber && !userData?.user_id) || userData?.type == 'add-user' || !applicationDataSource.filteredData.length">
            <div
              [ngClass]="{'hide': showSubmenu == true || userData?.type == 'add-user' || !applicationDataSource.filteredData.length }">
              <mat-paginator #agentWritingPagination #agentWritingSort="matSort" matSort [length]="totalRecords"
                [pageSizeOptions]="[10, 20, 30, 40, 50]">
              </mat-paginator>
            </div>
          </div>
        </div>
      </form>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end" cdkDragHandle>
    <button *ngIf="!hasChange" mat-button mat-raised-button (click)="captureClose()">Close</button>
    <button *ngIf="hasChange" mat-button mat-raised-button mat-dialog-close>Cancel</button>
    <button mat-button mat-raised-button color="primary" (click)="update(userProfileForm.value,'updateOnly')"
      *ngIf="hasChange && initialChange && userProfileForm.valid">{{!userData?.user_id ?'Add':'Update'}}</button>
    <button mat-button mat-raised-button color="primary" (click)="update(userProfileForm.value,'close')"
      *ngIf="hasChange && initialChange && userData?.user_id && userProfileForm.valid">Update + Close</button>
  </mat-dialog-actions>
</div>
