import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

import { RegistrationRoutingModule } from './registration-routing.module';
import { UserRegistrationComponent } from './pages/user-registration/user-registration.component';
import { UserAgreementComponent } from './components/user-agreement/user-agreement.component';
import { SharedModule } from '@shared/shared.module';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';


@NgModule({
  declarations: [
    UserRegistrationComponent,
    UserAgreementComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    RegistrationRoutingModule,
    SharedModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { 
      provide: ErrorStateMatcher, 
      useClass: ShowOnDirtyErrorStateMatcher
    },
  ]
})
export class RegistrationModule { }
