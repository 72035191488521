import { Injectable } from '@angular/core';
import { throwError, Observable, Subject, BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@app/service/auth.service';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  private dropSubject = new Subject<any>();
  private searchSubject = new BehaviorSubject<any>("");
  private notifySubject = new Subject<any>();
  constructor(private http: HttpClient, private auth: AuthService) { }

  public sendDropdownData(data: any) {
    this.dropSubject.next(data);
  }

  public getDropdownData(): Observable<any> {
    return this.dropSubject.asObservable();
  }

  public sendSearchAlert(input: object) {
    this.searchSubject.next(input);
  }

  public getSearchAlert(): Observable<any> {
    return this.searchSubject.asObservable();
  }

  public sendNotifyAlert(input: string) {
    this.notifySubject.next(input);
  }

  public getNotifyAlert(): Observable<any> {
    return this.notifySubject.asObservable();
  }

  public getPeproAccess() {
    return this.http.get(environment.PEAPI + 'api/user/' + this.auth.getToken().user_id + '/active').pipe(
      catchError((err) => {
        return throwError(() => err);
      })
    );
  }

  public populateDropDowns() {
    return this.http.get(environment.api + 'search/populateDropDowns').pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public getAccountDetail(id: any) {
    return this.http.get(environment.api + 'account/' + id + '/').pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public getUserCardSetting() {
    return this.http
      .get(
        environment.api + 'user/' + this.auth.getToken().user_id + '/settings'
      )
      .pipe(
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public quickSearch(input: object) {
    return this.http.post(environment.api + 'search/quick', input).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public qsLeadPlanCurrent(input: object) {
    return this.http.post(environment.api + 'leadPlanCurrent/quickSearch/', input).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public getAgentWritingNumberDetail(obj: any) {
    return this.http
      .post(environment.api + 'user/usercarrier/search/', obj)
      .pipe(
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public getEmailTypes() {
    return this.http.get(environment.api + 'email/types').pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public getPhoneTypes() {
    return this.http.get(environment.api + 'phone/types').pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public getAddressTypes() {
    return this.http.get(environment.api + 'address/types').pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public getNotificationCount() {
    return this.http
      .get(
        environment.api +
        'noticeOutstandingCount/' +
        this.auth.getToken().user_id
      )
      .pipe(
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public addNewUser(data: any) {
    return this.http
      .post(environment.api + 'user', data)
      .pipe(
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public updateUser(data: any,user_id:any) {
    return this.http
      .post(environment.api + 'user/' + user_id, data)
      .pipe(
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public getUserNotice(page_number: number, type: string) {
    let criteria;
    if (type === 'openNotify') {
      criteria = {
        search_type: 'simple',
        criteria: {
          dismissed: {
            type: 'simple',
            value: ['false'],
          },
        },
      };
    } else if (type === 'quickNotify') {
      criteria = {
        search_type: 'simple',
        criteria: {
          important_popup: {
            type: 'simple',
            value: ['true'],
          },
          dismissed: {
            type: 'simple',
            value: ['false'],
          },
          start_date: {
            type: 'range',
            value: ['*', moment().utc()],
          },
          end_date: {
            type: 'range',
            value: [moment().utc(), '*'],
          },
        },
        field_list: '',
        records_per_page: '',
      };
    } else {
      criteria = {
        search_type: 'simple',
        criteria: {
          dismissed: {
            type: 'simple',
            value: ['true'],
          },
        },
        field_list:
          'notice_title,notice_text,start_date,end_date,dismissed_date,important_popup',
        page_number: page_number,
        records_per_page: 35,
        orderby_field: 'start_date',
        orderby_direction: 'desc',
      };
    }
    return this.http
      .post(
        environment.api +
        'user/' +
        this.auth.getToken().user_id +
        '/userNotice/search',
        criteria
      )
      .pipe(
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public dismissNotice(criteria: any) {
    return this.http
      .post(
        environment.api +
        'user/' +
        this.auth.getToken().user_id +
        '/userNotice/dismiss',
        criteria
      )
      .pipe(
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public getNotice(criteria: any) {
    return this.http.post(environment.api + 'notice', criteria).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }
}
