<div fxLayout="row wrap" class="modal-container" appDialogScroll>
    <app-sms-notice-banner *ngIf="errorMsg" type="alert" (closeBanner)="clearMessage()">
        <p>{{ errorMsg }}</p>
      </app-sms-notice-banner>
    <div class="left-div" fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
        <form [formGroup]="addTaskForm">
            <ng-container>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="3.5%" fxLayoutAlign="left" class="w-100">
                    <h4>
                        <ng-container *ngIf="isAssignLead && leadName==''">
                            Assign Lead
                            <button type="button" mat-mini-fab color="primary" (click)="showSearchPanel()">
                                <mat-icon>add</mat-icon>
                            </button>
                        </ng-container>
                        <ng-container *ngIf="leadName">
                            <mat-form-field>
                                <mat-label>Lead Name</mat-label>
                                <input matInput style="visibility: hidden" autocomplete="off" [(ngModel)]="leadName" [ngModelOptions]="{standalone: true}" />
                                <span class="linked-td" (click)="goToLeadLedger(isFromCalendar?.extendedProps?.lead_id,$event)">
                                    <a class="linked-td weight-td" href="lead/{{isFromCalendar?.extendedProps?.lead_id}}">{{leadName}}</a>
                                </span>
                            </mat-form-field>
                            <button *ngIf="isAssignLead" type="button" mat-mini-fab color="warn" (click)="removeSelectedLead()">
                                <mat-icon>remove</mat-icon>
                            </button>
                            <button *ngIf="!isAssignLead && !appointment_id" type="button" mat-mini-fab color="primary" (click)="showSearchPanel()">
                                <mat-icon>add</mat-icon>
                            </button>
                        </ng-container>
                    </h4>
                </div>
                <div *ngIf="displayAssignLead" class="w-100 close-btn">
                    <mat-icon class="cursor-pointer" (click)="hideSearchPanel()">highlight_off</mat-icon>
                </div>
                <app-select-assign-lead [hidden]="!displayAssignLead" (lead)="selectLead($event)" [clearSearch]="clearSearch"></app-select-assign-lead>
            </ng-container>            
            <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="3.5%" fxLayoutAlign="left">
                <div fxFlex="100%">
                    <mat-form-field class="w-100">
                        <mat-label>Title</mat-label>
                        <input matInput autocomplete="off" formControlName="title" (mousedown)="$event.stopPropagation()" [appInputMaxLength]/>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="3.5%" fxLayoutAlign="left">
                <section fxFlex="50%">
                    <mat-checkbox formControlName="completed">Completed</mat-checkbox>
                </section>
                <section fxFlex="50%">
                    <mat-checkbox formControlName="importance">Important</mat-checkbox>
                </section>
            </div>
            <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="3.5%" fxLayoutAlign="left">
                <mat-form-field class="w-100">
                    <mat-label>Due Date</mat-label>
                    <input matInput [matDatepicker]="dueDate" (mousedown)="$event.stopPropagation()" formControlName="startDate" (input)="dateCheck($event)" (dateChange)="dateCheck($event)" autocomplete="off" appDate>
                    <mat-datepicker-toggle matSuffix [for]="dueDate"></mat-datepicker-toggle>
                    <mat-datepicker #dueDate></mat-datepicker>
                </mat-form-field>
                <mat-form-field class="w-100">
                    <mat-label>Due Time</mat-label>
                    <input formControlName="start_time" (mousedown)="$event.stopPropagation()" matInput type="time" (keyup)="timeCheck($event)" (change)="timeCheck($event)" autocomplete="off">
                </mat-form-field>
            </div>
            <div class="calendar-ngx-editor">
                <label>Note/Details</label>
                <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
                <ngx-editor (mousedown)="$event.stopPropagation()" [editor]="editor" formControlName="description"></ngx-editor>
            </div>
        </form>
    </div>
    <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
        <div  fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
            <div class="left">
                <button class="custom-button" *ngIf="!isAddTask && !isConversationTask" color="warn" mat-button mat-raised-button (click)="deleteTask()">Delete</button>
                <button class="custom-button" *ngIf="!isAddTask && !isConversationTask" mat-button mat-raised-button (click)="copyTask()">Copy</button>
            </div>
        </div>
        <div  fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
            <div class="right">
                <button class="custom-button" mat-button mat-raised-button (click)="closeModal()">{{isNewApt && !hasChange ? 'Close' : isNewApt && hasChange ? 'Cancel' : 'Cancel'}}</button>
                <button class="custom-button" *ngIf="addTaskForm.valid && isDateTime && hasChange" mat-button mat-raised-button color="primary" (click)="addTask()" [disabled]="isButtonClicked">{{isNewApt ? 'Add' : 'Update'}}</button>
            </div>
        </div>
    </div>
</div>
