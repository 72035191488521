<div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <div class="loading-indicator" *ngIf="isLoading">
    <app-sms-spinner></app-sms-spinner>
  </div>
  <div class="dialog-title" cdkDragHandle>
    <h2 mat-dialog-title>Quick Search</h2>
    <mat-icon mat-dialog-close class="cursor-pointer" (click)="closePopover()">close</mat-icon>
  </div>
  <mat-divider></mat-divider>
  <form [formGroup]="leadSearchForm" (submit)="searchLead(leadSearchForm.value)">
    <mat-dialog-content class="mat-typography">
      <div class="basic-container">
        <ng-container *ngIf="message.type">
          <app-sms-notice-banner #messageBanner [type]="message.type" (closeBanner)="clearMessage(0)">
            <p>{{ message.text }}</p>
          </app-sms-notice-banner>
        </ng-container>
        <mat-tab-group class="admin-sub-tab quick-search-tab" mat-align-tabs="start" headerPosition="above"
          animationDuration="0ms" [selectedIndex]="0" (selectedTabChange)="onTabChange($event)">
          <mat-tab label="Lead">
            <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="3.5%" fxLayoutAlign="left">
              <mat-form-field class="example-full-width" fxFlex="21%">
                <mat-label>First Name</mat-label>
                <input type="text" formControlName="fname" matInput autocomplete="off"
                  (mousedown)="$event.stopPropagation()" [appInputMaxLength] appDisableAutofill/>
              </mat-form-field>
              <mat-form-field class="example-full-width" fxFlex="21%">
                <mat-label>Last Name</mat-label>
                <input type="text" formControlName="lname" matInput autocomplete="off"
                  (mousedown)="$event.stopPropagation()" [appInputMaxLength] appDisableAutofill/>
              </mat-form-field>
              <mat-form-field class="example-full-width" fxFlex="21%">
                <mat-label>Phone</mat-label>
                <input type="text" formControlName="phone" [maxlength]="14" matInput autocomplete="off"
                  appPhoneNumberMask (mousedown)="$event.stopPropagation()" appDisableAutofill/>
              </mat-form-field>
              <mat-form-field class="example-full-width" fxFlex="22%">
                <mat-label>Email</mat-label>
                <input type="text" formControlName="email" matInput autocomplete="off"
                  (mousedown)="$event.stopPropagation()" [appInputMaxLength] appDisableAutofill/>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="3.5%" fxLayoutAlign="left">
              <mat-form-field class="example-full-width" fxFlex="21%">
                <mat-label>City</mat-label>
                <input type="text" formControlName="city" matInput autocomplete="off"
                  (mousedown)="$event.stopPropagation()" [appInputMaxLength] appDisableAutofill/>
              </mat-form-field>
              <mat-form-field class="example-full-width" fxFlex="21%">
                <mat-label>County</mat-label>
                <input type="text" formControlName="county" matInput type="text" autocomplete="off"
                  (mousedown)="$event.stopPropagation()" [appInputMaxLength] appDisableAutofill/>
              </mat-form-field>
              <mat-form-field class="example-full-width" fxFlex="21%">
                <mat-select formControlName="state_id" multiple placeholder="Select State" #leadState
                  (openedChange)="triggerEvent(state)">
                  <input class="custom-input" #state placeholder="Search" autocomplete="off" />
                  <mat-option *ngFor="let el of state_data" [class.hide]="
                      state.value !== '' &&
                      el.name
                        ?.toLowerCase()
                        .indexOf(state?.value?.toLowerCase()) === -1
                    " [value]="el.abbreviation">
                    {{ el.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="example-full-width" fxFlex="22%">
                <mat-label>Zip Code</mat-label>
                <input formControlName="zip" matInput [minlength]="5" [maxlength]="5" type="text" autocomplete="off"
                  appNumbersOnly (mousedown)="$event.stopPropagation()" appDisableAutofill/>
              </mat-form-field>
            </div>
            <div fxLayout.xs="column" fxLayoutWrap fxLayoutGap="3.5%" fxLayoutAlign="left">
              <mat-form-field class="example-full-width" fxFlex="47%">
                <mat-label>Date of Birth (From)</mat-label>
                <input formControlName="dobFrom" [max]="maxDate" matInput [matDatepicker]="dobFrom" appDate
                  (mousedown)="$event.stopPropagation()" />
                <mat-datepicker-toggle matSuffix [for]="dobFrom"></mat-datepicker-toggle>
                <mat-datepicker #dobFrom></mat-datepicker>
              </mat-form-field>
              <mat-form-field class="example-full-width" fxFlex="47%">
                <mat-label>Date of Birth (To)</mat-label>
                <input formControlName="dobTo" [max]="maxDate" matInput [matDatepicker]="dobTo" appDate
                  (mousedown)="$event.stopPropagation()" />
                <mat-datepicker-toggle matSuffix [for]="dobTo"></mat-datepicker-toggle>
                <mat-datepicker #dobTo></mat-datepicker>
              </mat-form-field>
            </div>
            <div fxLayout.xs="column" fxLayoutWrap fxLayoutGap="3.5%" fxLayoutAlign="left">
              <mat-form-field class="example-full-width" fxFlex="47%">
                <mat-label>Lead Date (From)</mat-label>
                <input formControlName="leadDateFrom" matInput [matDatepicker]="leadFrom" appDate
                  (mousedown)="$event.stopPropagation()" />
                <mat-datepicker-toggle matSuffix [for]="leadFrom"></mat-datepicker-toggle>
                <mat-datepicker #leadFrom></mat-datepicker>
              </mat-form-field>
              <mat-form-field class="example-full-width" fxFlex="47%">
                <mat-label>Lead Date (To)</mat-label>
                <input formControlName="leadDateTo" matInput [matDatepicker]="leadTo" appDate
                  (mousedown)="$event.stopPropagation()" />
                <mat-datepicker-toggle matSuffix [for]="leadTo"></mat-datepicker-toggle>
                <mat-datepicker #leadTo></mat-datepicker>
              </mat-form-field>
            </div>
            <div fxLayout.xs="column" fxLayoutWrap fxLayoutGap="3.5%" fxLayoutAlign="left">
              <mat-form-field class="example-full-width" fxFlex="21%">
                <mat-select formControlName="lead_dispo_id" multiple placeholder="Select Dispositions" #leadDisp
                  (openedChange)="triggerEvent(lead_disp)">
                  <input class="custom-input" #lead_disp placeholder="Search" autocomplete="off" />
                  <mat-option *ngFor="let el of total_lead_dispos" [class.hide]="
                      lead_disp.value !== '' &&
                      el.dispo_name
                        ?.toLowerCase()
                        .indexOf(lead_disp?.value?.toLowerCase()) === -1
                    " [value]="el.dispo_id">
                    {{ el.dispo_name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="example-full-width" fxFlex="21%">
                <mat-select formControlName="user_id" multiple placeholder="Select Agent" #leadAgent
                  (openedChange)="triggerEvent(agent)">
                  <input class="custom-input" #agent placeholder="Search" autocomplete="off" />
                  <mat-option *ngFor="let el of total_agents" [class.hide]="
                      agent.value !== '' &&
                      el.fname
                        ?.toLowerCase()
                        .indexOf(agent?.value?.toLowerCase()) === -1
                    " [value]="el.user_id">
                    {{ el.fname + " " + el.lname }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="example-full-width" fxFlex="21%">
                <mat-label>Tracking Code</mat-label>
                <input formControlName="lead_vendor_source_code" matInput autocomplete="off"
                  (mousedown)="$event.stopPropagation()" [appInputMaxLength] />
              </mat-form-field>
              <mat-form-field class="example-full-width" fxFlex="22%">
                <mat-select formControlName="tag_id" multiple placeholder="Select Tag" #leadTag
                  (openedChange)="triggerEvent(tag)">
                  <input class="custom-input" #tag name="searchText" placeholder="Search" autocomplete="off" />
                  <mat-option *ngFor="let el of total_tags" [class.hide]="
                      tag.value !== '' &&
                      el.tag_name
                        ?.toLowerCase()
                        .indexOf(tag?.value?.toLowerCase()) === -1
                    " [value]="el.tag_id">
                    {{ el.tag_name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </mat-tab>
          <mat-tab label="Updated Rx + Current Plan">
            <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="3.5%" fxLayoutAlign="left" class="selectUser">
              <mat-form-field class="example-full-width" fxFlex="21%">
                <select matNativeControl formControlName="user_id" placeholder="Select Agent" #leadAgent
                  (openedChange)="triggerEvent(agent)">
                  <option *ngFor="let el of total_agents" [class.hide]="
                      agent.value !== '' &&
                      el.fname
                        ?.toLowerCase()
                        .indexOf(agent?.value?.toLowerCase()) === -1
                    " [value]="el.user_id" [selected]="el.user_id === leadAgent.value">
                    {{ el.fname + " " + el.lname }}
                  </option>
                </select>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="3.5%" fxLayoutAlign="left">
              <div class="example-full-width radio-button-row" fxFlex="50%">
                <mat-radio-group aria-label="Drug List Updated?" formControlName="drug_list_updated_flag">
                  <p>Drug List Updated? </p>
                  <mat-radio-button value="1" class="mr-8" (click)="drugUpdate('yes')">Yes</mat-radio-button>
                  <mat-radio-button value="0" class="mr-8" (click)="drugUpdate('no')">No</mat-radio-button>
                </mat-radio-group>
                <div fxLayout.xs="column" fxLayoutWrap fxLayoutGap="3.5%" fxLayoutAlign="left" >
                  <mat-form-field class="example-full-width" fxFlex="47%">
                    <mat-label>Date (From)</mat-label>
                    <input formControlName="drug_list_updated_date_from" matInput [matDatepicker]="dateFrom" appDate
                      (mousedown)="$event.stopPropagation()" [required]="dateRequired"/>
                    <mat-datepicker-toggle matSuffix [for]="dateFrom"></mat-datepicker-toggle>
                    <mat-datepicker #dateFrom></mat-datepicker>
                    <mat-error *ngIf="checkError('drug_list_updated_date_from', 'required')">This field is required.</mat-error>
                  </mat-form-field>
                  <mat-form-field class="example-full-width" fxFlex="47%">
                    <mat-label>Date (To)</mat-label>
                    <input formControlName="drug_list_updated_date_to" matInput [matDatepicker]="dateTo" appDate
                      (mousedown)="$event.stopPropagation()" [required]="dateRequired" />
                    <mat-datepicker-toggle matSuffix [for]="dateTo"></mat-datepicker-toggle>
                    <mat-datepicker #dateTo></mat-datepicker>
                    <mat-error *ngIf="checkError('drug_list_updated_date_to', 'required')">This field is required.</mat-error>
                  </mat-form-field>
                </div>
                <div>
                  <p>Date Range Applies to Recent Updates for Rx</p>
                </div>
              </div>
              <div class="example-full-width radio-button-row" fxFlex="50%">
                <mat-radio-group aria-label="Has Current Plan?" formControlName="has_current_plan_flag">
                  <p>Has Current Plan? </p>
                  <mat-radio-button value="1" class="mr-8" (click)="leadPlanCurrent('yes', leadSearchForm.value.drug_list_updated_flag)">Yes</mat-radio-button>
                  <mat-radio-button value="0" class="mr-8" (click)="leadPlanCurrent('no', leadSearchForm.value.drug_list_updated_flag)">No</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Application">
            <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="3.5%" fxLayoutAlign="left">
              <mat-form-field class="example-full-width selectBox-top-margin" fxFlex="50%">
                <mat-select multiple formControlName="app_dispo_id" placeholder="Select Dispositions" #appDisp
                  (openedChange)="triggerEvent(disp)">
                  <input class="custom-input" #disp name="searchText" placeholder="Search" autocomplete="off"
                    (keydown)="$event.stopPropagation()" />
                  <mat-option *ngFor="let el of total_app_dispos" [class.hide]="
                      disp.value !== '' &&
                      el.dispo_name
                        ?.toLowerCase()
                        .indexOf(disp?.value?.toLowerCase()) === -1
                    " [value]="el.dispo_id">
                    {{ el.dispo_name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-raised-button type="button" (click)="closePopover()">
        Cancel
      </button>
      <button mat-raised-button type="button" (click)="resetForm()">
        Reset
      </button>
      <button mat-raised-button type="submit" color="primary" [disabled]="isDisabled && (!leadSearchForm.value.lead_plan_current
      || !leadSearchForm.value.updated_drug_list)">Search</button>
    </mat-dialog-actions>
  </form>
</div>
