<div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <div class="dialog-title" cdkDragHandle>
        <h2 mat-dialog-title>eSIGNATURE SCOPE OF APPOINTMENT REQUIREMENTS</h2>
        <mat-icon mat-dialog-close class="cursor-pointer">close</mat-icon>
    </div>
    <mat-divider></mat-divider>
    <mat-dialog-content class="mat-typography">
        <span>
            <p>The Scope of Appointment cannot be emailed to the lead/beneficiary until the following required information is added to the lead record.
                <a class="linked-td" (click)="openUserProfile()">Click Here</a> to update lead details with the information listed below.
            </p>
            <p>Missing eSignature Scope of Appointment Requirements:</p>
        </span>
        <ul>
            <li *ngIf="leadMissingReq.fname">Lead's First Name</li>
            <li *ngIf="leadMissingReq.lname">Lead's Last Name</li>
            <li *ngIf="leadMissingReq.dob">Lead's DOB</li>
            <li *ngIf="leadMissingReq.email">Lead's Email</li>
            <li *ngIf="leadMissingReq.phone">Lead's Default Phone Number</li>
            <li *ngIf="leadMissingReq.address1">Lead's Default Street Address</li>
            <li *ngIf="leadMissingReq.city">Lead's Default City</li>
            <li *ngIf="leadMissingReq.state">Lead's Default State</li>
            <li *ngIf="leadMissingReq.zip">Lead's Default Zip Code</li>
            <li *ngIf="leadMissingReq.medicare_parta_date">Lead's Medicare Part A Effective Date</li>
            <li *ngIf="leadMissingReq.medicare_partb_date">Lead's Medicare Part B Effective Date</li>
        </ul>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-raised-button mat-dialog-close>Close</button>
        <button mat-raised-button color="primary" (click)="openUserProfile()">Update Lead</button>
    </mat-dialog-actions>
</div>
