import { Injectable } from '@angular/core';
import { NOTICE_TYPE } from '@app/constants';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToastMsgService {

  public subject = new Subject<any>();
  public isExpanded: boolean = false;

  private _timeout: NodeJS.Timeout;

  constructor() { }

  public showSuccess(msg: string, isFromComponent: boolean = false) {
    if (isFromComponent) {
      return {
        type: NOTICE_TYPE.Success,
        text: msg
      }
    } else {
      this.subject.next({
        type: NOTICE_TYPE.Success,
        text: msg
      });
      this._clearMessage(NOTICE_TYPE.Success);

      return;
    }
  }
  public showBlueBanner(msg: string) {
    this.subject.next({
      type: NOTICE_TYPE.Announcement,
      text: msg
    })
    this._clearMessage(NOTICE_TYPE.Announcement);
    
    return {
      type: NOTICE_TYPE.Announcement,
      text: msg
    };
  }

  public showError(msg: string, isFromComponent: boolean = false) {
    if (isFromComponent) {
      return {
        type: NOTICE_TYPE.Alert,
        text: msg
      }
    } else {
      this.subject.next({
        type: NOTICE_TYPE.Alert,
        text: msg
      });

      this._clearMessage(NOTICE_TYPE.Alert);
      return;
    }
  }

  public showWarning(msg: string) {
    this.subject.next({
      type: NOTICE_TYPE.Warning,
      text: msg
    });
    
    this._clearMessage(NOTICE_TYPE.Warning);
  }

  public closeNotice(msg: string) {
    this.subject.next({
      type: NOTICE_TYPE.Close,
      text: msg
    });

    this._clearMessage(NOTICE_TYPE.Close);
  }

  public resetMessage() {
    this.subject.next({
      type: '',
      text: ''
    });

    return {
      'type': '',
      'text': ''
    };
  }

  private _clearMessage(messageType: string) {
    const maxTimeout: any = {
      'alert': 30000,
      'warning': 20000,
      'success': 15000,
      'announcement': 3000,
    }
  
    const timer = maxTimeout[messageType] || 0;

    if (this._timeout) {
      clearTimeout(this._timeout);
    }

    this._timeout = setTimeout(() => {
      this.resetMessage();
    }, timer);

  }

}
