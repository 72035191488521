<div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <div class="dialog-title" cdkDragHandle>
        <h2 mat-dialog-title>eSIGNATURE SCOPE OF APPOINTMENT</h2>
        <mat-icon mat-dialog-close class="cursor-pointer">close</mat-icon>
      </div>
      <mat-divider></mat-divider>
    <mat-dialog-content class="mat-typography blue-button-dialog-box">
        <div class="health-record-main-box">
            <div class="health-record-header">
                <p>The eSignature Scope Of Appointment (SOA) provided here is a CMS-approved, proprietary SMS tool that is not specific
                    to any plan sponsor and can be used for all appointments as per CMS Medicare Marketing Guidelines.</p>
            </div>
            <form [formGroup]="f2fForms">
                <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="3.5%" fxLayoutAlign="left">
                    <mat-form-field class="example-full-width" fxFlex="50%">
                        <mat-label>Appointment Date</mat-label>
                        <input formControlName="aptDate" matInput [matDatepicker]="picker" [min]="minDate" autocomplete="off" appDate (mousedown)="$event.stopPropagation()"(input)="checkAptDate(f2fForms.value.aptDate)" (dateChange)="checkAptDate(f2fForms.value.aptDate)">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error *ngIf="f2fForms.invalid">Please enter a valid date as "MM/DD/YYYY" between today and 1 year.</mat-error>
                    </mat-form-field>
                    <mat-form-field class="example-full-width" fxFlex="50%">
                        <mat-select disableOptionCentering formControlName="contactMethod" placeholder="Initial Method of Contact">
                            <mat-option value="Web" label=" Web"> Web</mat-option>
                            <mat-option value="Email" label="Email">Email </mat-option>
                            <mat-option value="Phone" label="Phone">Phone</mat-option>
                            <mat-option value="Text" label="Text">Text</mat-option>
                            <mat-option value="Walk in" label="Walk in">Walk in</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="3.5%" fxLayoutAlign="left">
                    <mat-form-field class="example-full-width" fxFlex="47%">
                        <input formControlName="agentSignature" matInput placeholder="Enter Agent Name Here to eSign"  autocomplete="off" required (mousedown)="$event.stopPropagation()" [appInputMaxLength] 	/>
                    </mat-form-field>
                </div>
            </form>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-raised-button mat-dialog-close>Close</button>
        <button *ngIf="f2fForms.valid" mat-raised-button color="primary" [disabled]="isButtonClick" (click)="f2fSOA()">Continue</button>
    </mat-dialog-actions>
</div>
