import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER  } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderModalsModule } from './modal/header-modals/header-modals.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { HeaderComponent } from './layout/header/header.component';
import { QuickLinksComponent } from './layout/sidebar/quick-links/quick-links.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { SidebarModalsModule } from './modal/sidebar-modals/sidebar-modals.module';
import { SharedModule } from './shared/shared.module';
import { BnNgIdleService } from 'bn-ng-idle';
import { RegistrationModule } from '@modules/registration/registration.module';
import { initializeAppFactory } from '@shared/factories/app.initializer';
import { AuthService } from '@app/service/auth.service';
import { LaunchdarklyService } from '@shared/services/LaunchDarkly/launchdarkly.service';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    QuickLinksComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CoreModule,
    BrowserAnimationsModule,
    SharedModule,
    HeaderModalsModule,
    SidebarModalsModule,
    RegistrationModule,
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [AuthService, LaunchdarklyService],
      multi: true
    },
  ]
})
export class AppModule { }
