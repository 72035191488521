import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, UntypedFormBuilder, FormControl, Validators, FormGroupDirective, FormArray } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog';
import { AdminService } from '@data/services/admin/admin.service';
import { LeadService } from '@data/services/lead/lead.service';
import * as data from '@data/states.json';
import { ConfirmDeleteComponent } from '@shared/modals/confirm-delete/confirm-delete.component';
import { CommonService } from '@shared/services/common/common.service';
import { Subject, Subscription, takeUntil } from 'rxjs';
@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {
  private isErrorSubs: Subscription;
  private leadLedgerSubs: Subscription;
  private formArraySubs: Subscription;
  private countiesByZipSubs: Subscription;
  private _componentDestroyed$ = new Subject<boolean>();
  private checkedIndex: number;
  private deletedItems: any = [];
  public addressForm: UntypedFormGroup;
  public address_content = [
    {
      name: 'Home',
      isSelected: false
    }, {
      name: 'Billing',
      isSelected: false
    }, {
      name: 'Business',
      isSelected: false
    }, {
      name: 'Mailing',
      isSelected: false
    }, {
      name: 'Other',
      isSelected: false
    }
  ];
  public submitted: boolean = false;
  public hasChange: boolean = false;
  public state_data: any = [];
  @Input() formArrayName: string;
  public addresseListArr: FormArray;
  @Input() type!: string;
  public countiesData: any = [];
  constructor(
    private fb: UntypedFormBuilder,
    private rootFormGroup: FormGroupDirective,
    private leadService: LeadService,
    private adminService: AdminService,
    private commonService: CommonService,
    public dialog: MatDialog,
  ) {
    this.isErrorSubs = this.leadService.getErrorAlert().subscribe(response => {
      if (response) {
        this.submitted = true;
        if (this.addresseListArr) {
          this.addresseListArr.controls.find((x: any) => {
            if (x.controls.address1.status) { x.controls.address1.touched = true }
            if (x.controls.city.status) { x.controls.city.touched = true }
            if (x.controls.state_id.status) { x.controls.state_id.touched = true }
            if (x.controls.zip.status) { x.controls.zip.touched = true }
          })
        }
      }
    });
    this.leadLedgerSubs = this.leadService.getLeadLedger().subscribe(response => {
      this.addressForm = this.rootFormGroup.control;
      this.addresseListArr = this.addressForm.get(this.formArrayName) as FormArray;
    })
    this.formArraySubs = this.adminService.getAdminFormArray().subscribe(response => {
      this.addressForm = this.rootFormGroup.control;
      this.addresseListArr = this.addressForm.get(this.formArrayName) as FormArray;
    });
    /* code for to refresh the delete itmes */
    this.leadService.getFormChangeAlert().pipe(takeUntil(this._componentDestroyed$)).subscribe((elem: string) => {
      if (elem == 'clearArray') {
        this.deletedItems = [];
        if (this.addresseListArr?.value?.length) {
          this.addresseListArr.value.forEach((element: any) => {
            this.address_content.forEach((ele: any) => {
              if (element.phone_type == ele.name) {
                ele['isSelected'] = ele.name == 'Other' ? false : true;
              } else {
                ele['isSelected'] = false;
              }
            });
          });
        }
      }
    })

    /* code for to add the line through when add the phone || address */
    this.leadService.getCrossedItems().pipe(takeUntil(this._componentDestroyed$)).subscribe((elem: string) => {
      if (elem == 'emailAdded' || elem == 'phoneAdded') {
        this.addressForm = this.rootFormGroup.control;
        this.addresseListArr = this.addressForm.get(this.formArrayName) as FormArray;
        if (this.addresseListArr?.value) {
          this.addresseListArr?.value.forEach((element: any, index: number) => {
            this.deletedItems.forEach((ele: number) => {
              if (index == ele) {
                element['address_class'] = 'true'
              }
            });
          });
        }
      }
    })
  }

  ngOnInit(): void {
    this.state_data = (data as any).default;
    this.addressForm = this.rootFormGroup.control;
    this.addresseListArr = this.addressForm.get(this.formArrayName) as FormArray;
    if (!this.addresseListArr?.value?.length) {
      this.addAddressType({ name: 'Home' });
    } else {
      this.addresseListArr.value.forEach((element: any) => {
        this.address_content.forEach((ele: any) => {
          if (element.address_type_id == ele.name) {
            ele['isSelected'] = ele.name == 'Other' ? false : true;
          }
        });
      });
    }
  }
  // ::::::::::::::::::::::::::::: add address functionality ::::::::::::::::::::::::::::: //

  public addAddressType(addressType: any) {
    this.addresses.push(
      this.fb.group({
        address_type_id: addressType.name,
        address1: this.type != 'profile' && this.type != 'add-user' ? new FormControl('') : new FormControl('', [Validators.required]),
        address2: '',
        city: this.type != 'profile' && this.type != 'add-user' ? new FormControl('') : new FormControl('', [Validators.required]),
        state_id: this.type != 'profile' && this.type != 'add-user' ? new FormControl('') : new FormControl('', [Validators.required]),
        zip: this.type != 'profile' && this.type != 'add-user' ? new FormControl('') : new FormControl('', [Validators.required, Validators.minLength(5)]),
        county: new FormControl(''),

        address_id: [''],
        apo: [''],
        country_id: [''],
        county_fips: [''],
        zip_4: [''],
        address_class: [''],
        isSelected: ""
      })
    );
    addressType.isSelected = addressType.name == 'Other' ? false : true;
    /* code to select the previous default address when adding new address */
    this.addresseListArr.value.find((x: any, i: number) => {
      if (this.checkedIndex) {
        if (i == this.checkedIndex) {
          x.isSelected = true;
        } else {
          x.isSelected = false
        }
      }
      if (this.deletedItems.includes(i)) {
        x.address_class = "true"
      } else {
        x.address_class = ""
      }
    })
    this.leadService.setCrossedItems('addressAdded');
  }

  public get addresses(): UntypedFormArray {
    return this.addressForm.get("addresses") as UntypedFormArray
  }


  // ::::::::::::::::::::::::::::: end address functionality ::::::::::::::::::::::::::::: //

  //function to Strike-through the address value
  public clearAddress(value: any, index: number) {
    this.hasChange = true;
    value.address_class = "true";
    this.deletedItems.push(index)
    this.leadService.sendFormChangeAlert('valueChange');
  }

  //function to undo Strike-through the address value
  public refreshAddress(value: any, index: number) {
    if (this.deletedItems.includes(index)) {
      this.deletedItems.splice(index)
    }
    value.address_class = "";
    this.leadService.sendFormChangeAlert('');
  }

  //function called when checkbox is unchecked for address
  public addressCheckBoxvalue(checkbox: any, item: any, index: number) {
    this.hasChange = true;
    this.addresses.value.forEach((val: any) => {
      if (val.address_id == item.address_id) {
        this.checkedIndex = index;
        val.isSelected = !val.isSelected;
      } else {
        val.isSelected = false;
      }
    });
    this.leadService.sendFormChangeAlert('valueChange');
  }

  // get countries according to zip code
  public getCounties(i: number, element: any, alert: boolean) {
    if (this.addresseListArr.controls[i]?.value?.zip?.length == 5) {
      this.countiesByZipSubs = this.leadService.getCountiesByZip(this.addresseListArr.controls[i]?.value?.zip).subscribe({
        next: (res: any) => {
          if (res.length > 0) {
            this.addresseListArr.controls[i].patchValue({ "state_id": '', "county": '' });
            this.countiesData = res || [];
            if (this.countiesData?.length) {
              if (this.countiesData?.length < 2) {
                if (this.countiesData[0]?.counties.length < 2) {
                  /* set county and state's value according to zip code */
                  this.addresseListArr.controls[i].patchValue({ "state_id": this.countiesData[0]?.state_id, "county": this.countiesData[0]?.counties[0]?.county_name });
                } else {
                  /* set state's value according to zip code */
                  this.addresseListArr.controls[i].patchValue({ "state_id": this.countiesData[0]?.state_id });
                  if (element.county) {
                    this.addresseListArr.controls[i].patchValue({ "county": element.county });
                  }
                  if (alert) {
                    this.commonService.countyStateAlert(this.countiesData)
                  }
                }
              } else {
                if (alert) {
                  this.commonService.countyStateAlert(this.countiesData)
                }
                if (element.county) {
                  this.addresseListArr.controls[i].patchValue({ "county": element.county });
                  this.changeOption(element, i)
                }
              }
            }
          } else {
            const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
              width: '30%', disableClose: true, autoFocus: false,
              data: {
                'text': 'The zip code you entered (' + this.addresseListArr.controls[i]?.value?.zip + ') cannot be found in our system.',
                'type': 'ok'
              }
            });
            dialogRef.afterClosed().subscribe(() => {
              this.addresseListArr.controls[i].patchValue({ "state_id": '', "county": '', "zip": '' });
            })
          }
        }, error: (err: any) => {

        }
      });
    } else {
      this.addresseListArr.controls[i].patchValue({ "state_id": '', "county": '' });
    }
  }

  public changeOption(country: any, i: number) {
    this.addresseListArr.controls[i].patchValue({ "state_id": country.state_id });
  }

  public ngOnDestroy() {
    this._componentDestroyed$.next(true);
    this._componentDestroyed$.complete();
    if (this.isErrorSubs) this.isErrorSubs.unsubscribe();
    if (this.leadLedgerSubs) this.leadLedgerSubs.unsubscribe();
    if (this.formArraySubs) this.formArraySubs.unsubscribe();
    if (this.countiesByZipSubs) this.countiesByZipSubs.unsubscribe();
  }

}
