<div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <div cdkDragHandle fxLayout="row" fxLayoutAlign="space-between baseline">
        <h2 mat-dialog-title>Quick Links</h2>
        <button class="dialogTitleClose" mat-icon-button mat-dialog-close><mat-icon>close</mat-icon></button>
    </div>

    <mat-divider></mat-divider>

    <ng-container *ngIf="isLoading; else componentLoaded">
        <div class="loading-spinner">
            <app-sms-spinner [size]="'sm'">
                <span *ngIf="!isSubmit; else savingMessage">Retrieving Quick Links</span>
                <ng-template #savingMessage>Saving Changes</ng-template>
            </app-sms-spinner>
        </div>

    </ng-container>

    <ng-template #componentLoaded>
        <mat-dialog-content>
                <ng-container *ngIf="isFFIGUser">
                    <mat-nav-list class="ql-list">
                        <a mat-list-item (click)="openSingleSignOn()" class="ql-item">Target Leads</a>
                    </mat-nav-list>
                </ng-container>

                <div cdkDropList (cdkDropListDropped)="rearrangeLinks($event)" cdkDropListLockAxis="y" [cdkDropListDisabled]="!isEdit">
                    <mat-nav-list class="ql-list">
                        <ng-container *ngFor="let link of quickLinks">
                            <mat-list-item cdkDrag *ngIf="link?.display == '1' || isEdit" >
                                <mat-icon matListItemIcon class="drag-handle" *ngIf="isEdit">menu</mat-icon>

                                <a *ngIf="!link.isCMSLink; else cmsLink"
                                    class="ql-item"
                                    [href]="link?.quicklink_url" target="_blank">{{ link.quicklink_title }}</a>
                                <ng-template #cmsLink>
                                    <a class="ql-item" (click)="callPost($event)">{{ link.quicklink_title }}</a>
                                </ng-template>

                                <span class="ql-edit-actions" *ngIf="isEdit">
                                    <ng-container *ngIf="link?.quicklink_hierarchy_type_name != 'System'">
                                        <button mat-mini-fab matTooltip="Edit link" color="primary" (click)="addLink(link)"><mat-icon>edit</mat-icon></button>
                                        <button mat-mini-fab matTooltip="Delete link" color="primary" (click)="confirmDelete(link.quicklink_id)"><mat-icon>delete</mat-icon></button>
                                    </ng-container>

                                    <button mat-mini-fab color="primary" (click)="toggleDisplay(link)">
                                        <mat-icon *ngIf="link?.display == '1'" matTooltip="Hide link">remove</mat-icon>
                                        <mat-icon *ngIf="link?.display == '0'" matTooltip="Show link">add</mat-icon>
                                    </button>
                                </span>
                            </mat-list-item>
                        </ng-container>
                    </mat-nav-list>

                </div>

        </mat-dialog-content>

        <div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">
            <span>
                <button mat-raised-button color="primary" (click)="toggleActionButtons()">
                    <span *ngIf="isEdit">Cancel</span>
                    <span *ngIf="!isEdit">Edit</span>
                </button>
                <button mat-icon-button matTooltip="Add New Link" (click)="addLink()"><mat-icon>add</mat-icon></button>
            </span>

            <button mat-raised-button color="primary" *ngIf="isEdit" (click)="saveChanges()">Save</button>
        </div>
    </ng-template>

</div>