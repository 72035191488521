import { Injectable, OnDestroy } from '@angular/core';
import { launchDarklyFlags } from '@app/constants';
import { AuthService } from '@app/service/auth.service';
import { IUser } from '@data/user-profile/user-profile.interface';
import { environment } from '@env/environment';
import * as LaunchDarkly from 'launchdarkly-js-client-sdk';
import { LDFlagSet } from 'launchdarkly-js-client-sdk';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LaunchdarklyService implements OnDestroy {

  private client: LaunchDarkly.LDClient;
  private _componentDestroyed$ = new Subject<boolean>();

  private _flagChange$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  flagChange$: Observable<any> = this._flagChange$.asObservable();

  public leadSwitchFlagChange = new Subject();
  public configurableSettingsFlagChange = new Subject();
  public enableAepDisruptionReportFlagChange$ = new BehaviorSubject<boolean>(false);

  public flags: LDFlagSet;

  constructor (
    public authUser: AuthService
  ) {
    this.authUser.userDetails$
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe({
        next: (userDetails => {
          if (userDetails) {
            this.initialize(userDetails);
            this._oldInit();
          }
        })
      });
  }

  // This function intializes the flag
  public initialize(userDetails: IUser): boolean {

    //Set up the user context
    const user = {
      kind: 'user'
    } as LaunchDarkly.LDSingleKindContext;

    if (userDetails) {
      user.key = 'LAP.' + (environment.env).toUpperCase() + '.USER.' + userDetails?.user_id;
      user.name = userDetails?.fname + ' ' +userDetails?.lname;

    } else
      user.anonymous = true;

    this.client = LaunchDarkly.initialize(environment.launchDarklyClientId, user);

    return !!this.client;
  }

  private _oldInit() {
    // subscribe to a particular flag's changes
    this.client.on('change', (flags) => {

      if (flags[launchDarklyFlags['LEAD_SWITCH_KEY']] !== undefined) {
        this.flags[launchDarklyFlags['LEAD_SWITCH_KEY']] = flags[launchDarklyFlags['LEAD_SWITCH_KEY']].current;
        this.leadSwitchFlagChange.next(this.flags[launchDarklyFlags['LEAD_SWITCH_KEY']]);
      }

      if (flags[launchDarklyFlags['CONFIGURABLE_SETTINGS']] !== undefined) {
        this.flags[launchDarklyFlags['CONFIGURABLE_SETTINGS']] = flags[launchDarklyFlags['CONFIGURABLE_SETTINGS']].current;
        this.configurableSettingsFlagChange.next(this.flags[launchDarklyFlags['CONFIGURABLE_SETTINGS']]);
      }

      if (flags[launchDarklyFlags['ENABLE_AEP_DISRUPTION_REPORT']] !== undefined) {
        this.flags[launchDarklyFlags['ENABLE_AEP_DISRUPTION_REPORT']] = flags[launchDarklyFlags['ENABLE_AEP_DISRUPTION_REPORT']].current;
        this.enableAepDisruptionReportFlagChange$.next(this.flags[launchDarklyFlags['ENABLE_AEP_DISRUPTION_REPORT']]);
      }

    });

    this.client.on('ready', () => {
      this._setFlags();
    });
  }

  private _setFlags() {
    this.flags = this.client.allFlags();
    this.leadSwitchFlagChange.next(this.flags[launchDarklyFlags['LEAD_SWITCH_KEY']]);
    this.configurableSettingsFlagChange.next(this.flags[launchDarklyFlags['CONFIGURABLE_SETTINGS']]);
    this.enableAepDisruptionReportFlagChange$.next(this.flags[launchDarklyFlags['ENABLE_AEP_DISRUPTION_REPORT']]);
  }

  public getFlag(flagKey: string, defaultValue: LaunchDarkly.LDFlagValue): Observable<LaunchDarkly.LDFlagValue> {
    flagKey = launchDarklyFlags[flagKey];

    this.client.waitUntilReady().then(() => {
      this.flags = this.client.allFlags();
      const flagsArray = Object.entries(this.flags);

      flagsArray.filter(([key, value]) => {
        if (key === flagKey) {
          this.setFlag(key, defaultValue);
          return value;

        } else
          return defaultValue;

      });

      this.client.on('initialized', (flags) => {
        this.flags = {...flags};
      });

      this.client.on(`change:${flagKey}`, (flagValue) => {
        this.setFlag(flagKey, defaultValue)
      });

    });

    return this.flagChange$;
  }

  public setFlag(flagKey: string, defaultValue: LaunchDarkly.LDFlagValue) {
    this._flagChange$.next(this.client.variation(flagKey, defaultValue));
  }

  async ngOnDestroy() {
    this._componentDestroyed$.next(true);
    this._componentDestroyed$.complete();

    await this.client.close();
  }
}
