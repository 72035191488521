<form autocomplete="off" [formGroup]="phoneForm">
  <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
    <div fxLayout="row wrap">
      <div fxFlex.gt-lg="53" fxFlex.gt-md="53" fxFlex.gt-xs="100" fxFlex="100">
        <h4 class="user-profile-heading text-upper">Phones</h4>
      </div>
      <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
        <div class="example-button-row report-btn">
          <span class="material-icons plus" [matMenuTriggerFor]="phoneMenu" title="Add Entry">add</span>
        </div>
        <mat-menu #phoneMenu="matMenu">
          <ng-container *ngFor="let content of phone_content">
            <button *ngIf="!content.isSelected" (click)="addPhoneType(content)" mat-menu-item>{{content.text}}</button>
          </ng-container>
        </mat-menu>
      </div>
      <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100" formArrayName="phones">
        <ng-container *ngFor="let phone of phonesListArr?.controls; let i=index" [formGroupName]="i">
          <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="left" fxLayoutGap="3.5%">
            <mat-form-field class="example-full-width" fxFlex="20%">
              <mat-label>Type</mat-label>
              <mat-select disableOptionCentering  matNativeControl formControlName="phone_type" [disabled]="phone?.value?.phone_class == 'true'"
                [ngClass]="{'line-through': phone?.value?.phone_class == 'true'}">
                <mat-option *ngFor="let content of phone_content" value="{{content.text}}">{{content.text}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="example-full-width" fxFlex="25%">
              <mat-label>Phone</mat-label>
              <input [class]="phone?.value?.phone_class == 'true' ? 'not-allowed' : ''"
                (mousedown)="$event.stopPropagation()" matInput autocomplete="off" formControlName="phone"
                appPhoneNumberMask [maxlength]="14" [readonly]="phone?.value?.phone_class == 'true'"
                [ngClass]="{'line-through': phone?.value?.phone_class == 'true'}" appDisableAutofill>
              <mat-error *ngIf="phone?.get('phone')?.errors && submitted">
                Please enter correct phone number
              </mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width" fxFlex="20%">
              <mat-label>Ext ...</mat-label>
              <input [class]="phone?.value?.phone_class == 'true' ? 'not-allowed' : ''"
                [readonly]="phone?.value?.phone_class == 'true'" (mousedown)="$event.stopPropagation()" matInput
                autocomplete="off" formControlName="phone_ext" [maxlength]="20"
                [ngClass]="{'line-through': phone?.value?.phone_class == 'true'}">
            </mat-form-field>
            <mat-icon class="close" title="Undo" *ngIf="phone?.value?.phone_class == ''"
              (click)="clearPhone( phone.value,i)">clear
            </mat-icon>
            <mat-icon *ngIf="phone?.value?.phone_class == 'true'" (click)="refreshPhone(phone.value,i)">
              replay</mat-icon>
            <mat-checkbox *ngIf="type!='admin'" (change)="phoneCheckBoxvalue($event,phone.value,i)"
              [checked]="phone?.value?.isSelected==true || type == 'plan' || type=='add-user'" [disabled]="phone?.value?.isDisabled && phone?.value?.phone.length != 14">
              &nbsp;&nbsp;</mat-checkbox>
          </div>
        </ng-container>
      </div>
    </div>
  </div>