<div mat-dialog-title id="userAgreement">
    <h1>USER AGREEMENT</h1>
    <p id="instructions">User must agree to terms of service outlined below. <strong>A copy of this agreement will be emailed to the email associated with this account upon completion.</strong></p>
</div>
<div class="userAgreementContent" mat-dialog-content>
  <ng-container *ngIf="isLoading; else regForm">
    <app-sms-spinner size="1x"></app-sms-spinner>
  </ng-container>

  <ng-template #regForm>
    <div [innerHTML]="document"></div>
  </ng-template>
</div>
<div mat-dialog-actions align="end" *ngIf="!isLoading">
  <button mat-button color="secondary" [mat-dialog-close]="false">Do Not Agree</button>
  <button mat-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>Agree</button>
</div>
