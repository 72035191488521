import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { throwError, Observable, Subject } from 'rxjs';
import { catchError, distinct, switchMap } from 'rxjs/operators';
import { IValidateUrl } from './validate-url.interface';
import { IRegistration } from './registration.interface';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  constructor(
    private _http: HttpClient) { 
  }


  public registerUser(formValues: IRegistration) {
    return this._http.post(
        environment.registrationApi + 'registration', 
        this._encodeData(formValues),
        {
          headers: new HttpHeaders({
            'Content-Type':  'application/x-www-form-urlencoded',
          })
        })
      .pipe(
        catchError((error) => { return throwError(() => error); }
      ));

  }

  public getUserAgreement() {
    return this._http.get(environment.registrationApi + 'agreement')
      .pipe(
        catchError((error) => { return throwError(() => error); }
      ));

  }

  public validateUrl(token: string) {
    return this._http.post<IValidateUrl>(environment.registrationApi + 'validateUrl', {
      'token': token
    })
      .pipe(
        catchError((error) => { return throwError(() => error); }  
      ));

  }

  private _encodeData(formValues : IRegistration) {
    // Turn the data object into an array of URL-encoded key/value pairs.
    let urlEncodedDataPairs = [];

    for (const [key, value] of Object.entries(formValues)) {
      urlEncodedDataPairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
    }

    // Combine the pairs into a single string and replace all %-encoded spaces to 
    // the '+' character; matches the behaviour of browser form submissions.
    return urlEncodedDataPairs.join('&').replace(/%20/g, '+');
  }


}
