<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="loading-indicator" *ngIf="isLoading">
        <app-sms-spinner>
        </app-sms-spinner>
      </div>
      <app-sms-notice-banner *ngIf="errorMsg" type="alert" (closeBanner)="clearMessage()">
        <p>{{ errorMsg }}</p>
      </app-sms-notice-banner>
    <div clas="mat-dialog-content">
        <div class="main-container">
            <div class="left-container">
                <mat-icon class="material-icons md-18 md-dark">
                    warning
                </mat-icon>
            </div>
            <div class="right-container">
                <p>
                   Are you sure you want to <br> delete this Agent Writing <br> Number record ?
                </p>
            </div>
        </div>
        <div mat-dialog-actions>
            <button mat-button class="alert-btn" (click)="deleteAgent()">OK</button>
            <button mat-button mat-dialog-close>CANCEL</button>
        </div>
    </div>