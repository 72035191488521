import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-single-sign-on',
  templateUrl: './single-sign-on.component.html',
  styleUrls: ['./single-sign-on.component.scss']
})
export class SingleSignOnComponent implements OnInit {

  private _ssoData: any;
  private _ssoURL: string;

  public carrierName: string;
  public quickLinkText: string;

  public ssoForm: FormGroup;
  public hashFormControl = new FormControl();
  public userIdFormControl = new FormControl();
  public loginFormControl = new FormControl();
  public relayStateFormControl = new FormControl();
  public SAMLResponseFormControl = new FormControl();

  public ssoURLToiGoEApp: SafeUrl;
  public ssoiGoEAppRelayState: string | null;
  public ssoiGoEAppSAMLResponse: string | null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(DOCUMENT)
    private _document: Document,
    private _formBuilder: FormBuilder,
  ) {

    this._ssoData = data.data;
    this._ssoURL = data.url || this._ssoData.url || '';

    this.carrierName = data?.plan?.carrier_name || '';
    this.quickLinkText = data.quickLinkText || '';

    if (Array.isArray(this._ssoData)) {
      if (this._ssoData.length == 2) {
        this.ssoiGoEAppRelayState = this._ssoData[0]?.RelayState || null;
        this.ssoiGoEAppSAMLResponse = this._ssoData[1]?.SAMLResponse || null;
      }
      else
        this.ssoiGoEAppSAMLResponse = this._ssoData[0]?.SAMLResponse || null;

    } else {
      this.ssoiGoEAppRelayState = null;
      this.ssoiGoEAppSAMLResponse = null;
    }


    this.ssoForm = this._formBuilder.group({
      'hash': this.hashFormControl,
      'user_id': this.userIdFormControl,
      'login': this.loginFormControl,
      'RelayState': this.relayStateFormControl,
      'SAMLResponse': this.SAMLResponseFormControl,
    });

    this.ssoForm.patchValue({
      ...this._ssoData,
      ...{
        RelayState: this.ssoiGoEAppRelayState,
        SAMLResponse: this.ssoiGoEAppSAMLResponse
      }
    });
  }

  ngOnInit(): void {}

  public submitSSO() {
    const httpMethod = this._ssoData?.method?.toUpperCase();
    const ssoValues = Object.fromEntries(Object.entries<string>(this.ssoForm.value).filter(([_, v]) => v != null));

    const form = this._document.createElement("form");
    form.method = httpMethod || 'POST';
    form.target = "_blank";
    form.action = this._ssoURL;

    for (let [inputName, value] of Object.entries(ssoValues)) {
      const input = this._document.createElement("input");

      input.type = "hidden";
      input.name = inputName;
      input.value = value;
      form.appendChild(input);

    }

    this._document.body.appendChild(form);
    form.submit();
  }

}
