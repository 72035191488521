import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
@Injectable({
  providedIn: 'root',
})
export class RefreshTokenService {

  constructor(private http: HttpClient,private authService: AuthService) {

  }

  public requestRefresh(auth: string) {
    let body = new FormData();
    body.append('grant_type', 'refresh_token');
    body.append('refresh_token', auth);
    return this.http.post(environment.authClient + 'refresh_token', body).pipe(
      catchError((error) => {
        this.authService.killCookie();
        return throwError(() => error);
      })
    );
  }
}
