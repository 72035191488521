import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { validatorPatterns } from '@app/constants';
import { AuthService } from '@app/service/auth.service';
import { LeadDetail } from '@data/lead/lead.interface';
import { leadUtilityFunctions } from '@data/services/lead/lead-utility-functions';
import { LeadService } from '@data/services/lead/lead.service';
import { environment } from '@env/environment';
import { CommonService } from '@shared/services/common/common.service';
import { ToastMsgService } from '@shared/services/toastr/toast-msg.service';
import moment from 'moment';
import { Subject, Subscription, first, takeUntil } from 'rxjs';
@Component({
  selector: 'app-remote-soa',
  templateUrl: './remote-soa.component.html',
  styleUrls: ['./remote-soa.component.scss']
})
export class RemoteSoaComponent implements OnInit {
  private createEmailDraftSubs:Subscription;
  private getSoAeSignatureSubs:Subscription;
  private _componentDestroyed$ = new Subject<boolean>();
  public remoteForms : FormGroup;
  public minDate : Date;
  public maxDate : string;
  public leadDetails : any;
  public userDetails: any;
  public isGenerateDraft : boolean = false;
  public isEmailSOA : boolean = false;
  public generatingDraft : boolean = false;
  public email_types : any = environment;
  public groupByCategory:any;
  public Object = Object;

  constructor(
    private fb : FormBuilder,
    private auth: AuthService,
    private commonService:CommonService,
    private dialog: MatDialog,
    private leadService: LeadService,
    private toastMsgService: ToastMsgService,
    public luf : leadUtilityFunctions
  ) {
    this.userDetails = this.auth.user_details;
    this.leadDetails = this.leadService.lead_details;
    this.remoteForms = this.fb.group({
      'aptDate' : this.leadDetails?.emails?.length > 0 ? ['', Validators.required] : [''],
      'contactMethod' : this.leadDetails?.emails?.length > 0 ? ['', Validators.required] : [''],
      'agentSignature' : ['', Validators.required],

      'default_email' : this.leadDetails?.emails?.length == 0 ? [this.userDetails.default_email.email, [Validators.required, Validators.pattern(validatorPatterns.EMAIL)]] : ['',[ Validators.pattern(validatorPatterns.EMAIL)]],
      'leaddefault_email_type' : this.leadDetails?.emails?.length == 0 ? ['', Validators.required] : [''],
      'leadEmail' : this.leadDetails?.emails?.length == 0 ? ['', [Validators.required, Validators.pattern(validatorPatterns.EMAIL)]] : ['',[ Validators.pattern(validatorPatterns.EMAIL)]],

      'emailIds' : this.leadDetails?.emails?.length > 1 ? ['', Validators.required] : [''],
    });
    this.getDisclaimerText();
  }

  ngOnInit(): void {
    this.minDate = new Date();
    this.maxDate = moment(this.minDate).add(1, "year").format("MM/DD/YYYY");
  }

  public checkAptDate(date: string) {
    let futureDate = moment(date).format("MM/DD/YYYY")
    if (moment(futureDate).isAfter(this.maxDate)) {
      this.remoteForms.controls['aptDate'].setErrors({ invalid: true });
    }
  }

  public updateLeadEmail(){
    if(this.remoteForms.invalid){
      return;
    }else{
      this.generatingDraft = true;
      let formData = this.remoteForms.value;
      let payload = {"email_type":formData.leaddefault_email_type,"email": formData.leadEmail,"default_email":"true"}
    this.createEmailDraftSubs = this.leadService.createEmailDraft(payload).subscribe({
        next: (res: any) => {
          this.leadService.getLeadDetails(this.leadService.lead_details.lead_id).pipe(takeUntil(this._componentDestroyed$)).subscribe({
            next: (res: LeadDetail) => {
              this.leadService.lead_details = res;
              this.isGenerateDraft = true;
              this.generatingDraft = false;
            }, error: err => {
              this.generatingDraft = false;
              this.toastMsgService.showError(err.error.message);
            }
          });
        }, error: err => {
          this.generatingDraft = false;
          this.toastMsgService.showError(err.error.message);
        }
      })
    }
  }

  private getDisclaimerText(){
    //call API to get cms disclaimer
    var payload={
      user_id:this.auth.getToken().user_id,
      lead_id:this.leadDetails?.lead_id,
      county:this.leadDetails?.default_address.county
    }

    this.leadService.getCarrierPlanGeography(payload,'email').pipe(takeUntil(this._componentDestroyed$)).subscribe({
      next: (response: any) => {
        if (response.success == '0') {
          this.toastMsgService.showError(response.message);
        } else {
          this.groupByCategory = this.commonService.groupByPlanYear(response);
        }
      },
      error: (err) => {
        this.toastMsgService.showError(err.error.message);
      },
      complete: () => {},
    });
  }

  public remoteSOA(){
    if(this.remoteForms.invalid){
      return;
    }else{
      this.isEmailSOA = true;
      let fomrData = this.remoteForms.value;
      this.getSoAeSignatureSubs = this.leadService.getSoAeSignature(fomrData.agentSignature).subscribe({
        next: (res: any) => {
          let user = this.auth.user_details;
          var agentESignatureID = res.esignature_id;
          let lead = this.leadService.lead_details
          let payload = this.luf.generateRequestPayload(user,agentESignatureID,lead,fomrData,'remote');
          this.leadService.getClientInvite(payload).pipe(first()).subscribe({
            next: (res: any) => {
              if (res.success == 0) {
                if (user.default_phone.phone) {
									this.toastMsgService.showError('The eSignature Scope Of Appointment was not emailed. ' + res.message);
								} else {
									this.toastMsgService.showError('To send eSOA you need to have a phone number in your agent profile. Click on your name in the upper right hand corner and select "Profile" from the drop down to add your agent phone number');
								}
              }else {
                this.toastMsgService.showSuccess('The eSignature Scope Of Appointment was emailed.');
                this.leadService.sendRefreshConvTable('refreshConvTable');
							}
              this.dialog.closeAll();
              this.isEmailSOA = false;
            }, error: err => {
              this.isEmailSOA = false;
              this.toastMsgService.showError(err.error.message);
            }
          });
        }, error: err => {
          this.isEmailSOA = false;
          this.toastMsgService.showError(err.error.message);
        }
      })
    }
  }

  public ngOnDestroy(){
    this._componentDestroyed$.next(true);
    this._componentDestroyed$.complete();
    if(this.createEmailDraftSubs) this.createEmailDraftSubs.unsubscribe();
    if(this.getSoAeSignatureSubs) this.getSoAeSignatureSubs.unsubscribe();
  }
}
