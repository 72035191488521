<div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <div class="dialog-title" cdkDragHandle>
        <h2 mat-dialog-title>eSIGNATURE SCOPE OF APPOINTMENT</h2>
        <mat-icon mat-dialog-close class="cursor-pointer">close</mat-icon>
    </div>
    <mat-divider></mat-divider>
    <mat-dialog-content class="mat-typography blue-button-dialog-box">
        <div class="health-record-main-box">
            <div class="health-record-header">
                <p>The eSignature Scope Of Appointment (SOA) provided here is a CMS-approved, proprietary SMS tool that
                    is not specific
                    to any plan sponsor and can be used for all appointments as per CMS Medicare Marketing Guidelines.
                </p>
            </div>
            <div>
                <form [formGroup]="remoteForms">
                    <ng-container *ngIf="leadDetails?.emails?.length == 0 && !isGenerateDraft">
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="3.5%" fxLayoutAlign="left">
                            <mat-form-field class="example-full-width" fxFlex="35%">
                                <input formControlName="default_email" matInput placeholder="From" autocomplete="off"
                                    required (mousedown)="$event.stopPropagation()" [appInputMaxLength] />
                            </mat-form-field>
                            <mat-form-field class="example-full-width" fxFlex="20%">
                                <mat-select disableOptionCentering formControlName="leaddefault_email_type"
                                    placeholder="Type">
                                    <mat-option value="Home" label=" Home"> Home</mat-option>
                                    <mat-option value="Other" label="Other">Other </mat-option>
                                    <mat-option value="Work" label="Work">Work</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="example-full-width" fxFlex="35%">
                                <input formControlName="leadEmail" matInput placeholder="To" autocomplete="off" required
                                    (mousedown)="$event.stopPropagation()" [appInputMaxLength] />
                            </mat-form-field>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="leadDetails?.emails?.length > 0 || isGenerateDraft">
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="3.5%" fxLayoutAlign="left">
                            <mat-form-field class="example-full-width" fxFlex="50%">
                                <mat-label>Appointment Date</mat-label>
                                <input formControlName="aptDate" matInput [matDatepicker]="picker" [min]="minDate"
                                    autocomplete="off" appDate (mousedown)="$event.stopPropagation()" (input)="checkAptDate(remoteForms.value.aptDate)" (dateChange)="checkAptDate(remoteForms.value.aptDate)">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                                <mat-error *ngIf="remoteForms.invalid">Please enter a valid date as "MM/DD/YYYY" between today and 1 year.</mat-error>
                            </mat-form-field>
                            <mat-form-field class="example-full-width" fxFlex="50%">
                                <mat-select disableOptionCentering formControlName="contactMethod"
                                    placeholder="Initial Method of Contact">
                                    <mat-option value="Web" label=" Web"> Web</mat-option>
                                    <mat-option value="Email" label="Email">Email </mat-option>
                                    <mat-option value="Phone" label="Phone">Phone</mat-option>
                                    <mat-option value="Text" label="Text">Text</mat-option>
                                    <mat-option value="Walk in" label="Walk in">Walk in</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </ng-container>
                    <ng-container>
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="3.5%" fxLayoutAlign="left">
                            <mat-form-field class="example-full-width" fxFlex="50%"
                                *ngIf="leadDetails?.emails?.length > 1">
                                <mat-select disableOptionCentering formControlName="emailIds"
                                    placeholder="Please select lead email address">
                                    <mat-option *ngFor="let emails of leadDetails.emails"
                                        value="({{emails.email_type}}) {{emails.email}}">({{emails.email_type}})
                                        {{emails.email}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </ng-container>
                    <ng-container>
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="3.5%" fxLayoutAlign="left">
                            <mat-form-field class="example-full-width" fxFlex="50%">
                                <input formControlName="agentSignature" matInput
                                    placeholder="Enter Agent Name Here to eSign" autocomplete="off" required
                                    (mousedown)="$event.stopPropagation()" [appInputMaxLength] />
                            </mat-form-field>
                        </div>
                    </ng-container>

                    <ng-container>
                        <div class="btn-section" fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="3.5%"
                            fxLayoutAlign="flex-end">
                            <button mat-raised-button mat-dialog-close>Cancel</button>
                            <button *ngIf="remoteForms.valid && leadDetails?.emails?.length == 0 && !isGenerateDraft"
                                [disabled]="generatingDraft" mat-raised-button color="primary"
                                (click)="updateLeadEmail()">{{!generatingDraft ? 'Save to Generate Draft' : 'Generating
                                Draft...'}}</button>
                            <button *ngIf="remoteForms.valid && leadDetails?.emails?.length > 0 || isGenerateDraft"
                                [disabled]="isEmailSOA" mat-raised-button color="primary"
                                (click)="remoteSOA()">{{!isEmailSOA ? 'Email eSignature Scope Of Appointment' :
                                'Processing'}}</button>
                        </div>
                    </ng-container>

                </form>
            </div>
            <div class="outer-box" *ngIf="leadDetails?.emails?.length || isGenerateDraft">
                <div class="inner-box">
                    <table style="width:100%">
                        <tr>
                            <td><b>From:</b></td>
                            <td>{{email_types.SENDER_EMAIL}}</td>
                        </tr>
                        <tr>
                            <td><b>To:</b> </td>
                            <td>{{leadDetails?.default_email?.email}}</td>
                        </tr>
                        <tr>
                            <td><b>Subject:</b></td>
                            <td>Medicare Health Plans - Scope of Appointment eSignature Request:
                                for {{leadDetails?.fname.trim()}} {{leadDetails?.lname.trim()}}
                                from {{userDetails?.fname.trim()}} {{userDetails?.lname.trim()}}
                            </td>
                        </tr>
                        <tr>
                            <td style="vertical-align:top"><b>Message:</b></td>
                            <td>
                                <div>
                                    <div>
                                        <p>Dear {{leadDetails?.fname}} {{leadDetails?.lname}},</p>
                                        <p>Please click on the link below to be taken to a secure website where you can
                                            electronically sign the Scope of
                                            Appointment form required before we can discuss your Medicare health plan
                                            options.
                                        </p>
                                        <ul>
                                            <li>Scope of Appointment Document</li>
                                        </ul>
                                        <p>If you have any questions, do not hesitate to contact me.</p>
                                        <p>Sincerely,</p>
                                        <p class="userSign">{{userDetails?.fname}} {{userDetails?.lname}}</p>
                                        <p class="userSign">Licensed Insurance Agent</p>
                                        <p class="userSign">{{userDetails?.company}}</p>
                                        <p class="userSign"
                                            *ngIf="userDetails?.default_phone && userDetails?.default_phone.phone && userDetails?.default_phone.phone.length>0">
                                            {{userDetails?.default_phone.phone}}
                                        </p>
                                        <p class="userSign">TTY 711</p>
                                        <p class="userSign"
                                            *ngIf="userDetails?.default_email && userDetails?.default_email.email && userDetails?.default_email.email.length>0">
                                            {{userDetails?.default_email.email}}
                                        </p>
                                        <p class="userSign"
                                            *ngIf="userDetails?.default_address && userDetails?.default_address.address1 && userDetails?.default_address.address1.length>0">
                                            {{userDetails?.default_address.address1}}
                                        </p>
                                        <p class="userSign"
                                            *ngIf="userDetails?.default_address && userDetails?.default_address.city">
                                            {{userDetails?.default_address.city}},
                                            {{userDetails?.default_address.state_id}}
                                            {{userDetails?.default_address.zip}}
                                        </p>
                                        <p>
                                            If you do not want to be contacted again via email, please send an email to
                                            {{userDetails.default_email.email}} with the subject
                                            line: Do Not Email Me Again.
                                        </p>
                                        <div fxLayout="row" *ngIf="groupByCategory" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="3.5%" fxLayoutAlign="left">
                                          <div fxFlex="groupByCategory.length>1? 50% : 100%" *ngFor="let item of Object.keys(groupByCategory)">
                                            <div *ngFor="let type of groupByCategory[item]">
                                              <p>
                                                {{type.email_disclaimer_text_with_counts}}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <p>This e-mail, including any attachments, is intended solely for the use of the
                                            individual or entity to which it
                                            is addressed. If the reader of this e-mail is not the intended recipient,
                                            the reader is hereby notified that
                                            any dissemination, distribution, use or copying of this e-mail is strictly
                                            prohibited. If you have received
                                            this e-mail in error, please notify the sender by reply e-mail and destroy
                                            this e-mail immediately.
                                        </p>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </mat-dialog-content>

</div>
