import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { ToastMsgService } from '@shared/services/toastr/toast-msg.service';
import { MatDialog } from '@angular/material/dialog';
import { UserProfileComponent } from '@modal/header-modals/user-profile/user-profile.component';
import { HeaderService } from '@data/services/header/header.service';
import { CommonService } from '@shared/services/common/common.service';
import { NotificationComponent } from '@modal/header-modals/notification/notification.component';
import { AuthService } from '@app/service/auth.service';
import { QuickSearchComponent } from '@modal/header-modals/quick-search/quick-search.component';
import { ImportantNoticeComponent } from '@modal/header-modals/important-notice/important-notice.component';
import { UserService } from '@data/services/user/user.service';
import { BehaviorSubject, combineLatest, filter, first, Subject, Subscription, take, takeUntil } from 'rxjs';
import { ConfirmDeleteComponent } from '@shared/modals/confirm-delete/confirm-delete.component';
import { environment } from '@env/environment';
import { NavigationEnd, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AdminService } from '@data/services/admin/admin.service';
import { PhoneDialerUserComponent } from '@modules/integration/components/phone-dialer-user/phone-dialer-user.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import moment from 'moment';
import { AccountSettings } from '@data/admin/account-settings.interface';
import { AccountService } from '@data/services/admin/account.service';
import { ConfigurableSettingsComponent } from '@modules/configurable-settings/configurable-settings/configurable-settings.component';
import { LaunchdarklyService } from '@shared/services/LaunchDarkly/launchdarkly.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  private _componentDestroyed$ = new Subject<boolean>();
  private _componentLoaded$ = new Subject<boolean>();
  private _cobrandSub: Subscription;
  private _productFilterSub:Subscription;
  private _peproSub:Subscription;
  private isNotifyAlert: Subscription;
  private pollingInterval: number = 120000;
  private userId: string;
  private isDismissedNotify:boolean = false;
  private isNotifyDialog:boolean = false;

  public hasPeproAccess:boolean = false;
  public showPhoneDialer$ = new BehaviorSubject<boolean>(false);

  public isExpanded = true;
  public showSubmenu: boolean = false;
  public isSubMenuLoading: boolean = true;
  public isDisabled: boolean = false;
  public showHeader: boolean;
  public isLoading: boolean = true;
  public configurableSettingsFlag:boolean;

  public date: any;
  public month: any;
  public year: any;
  public day: any;
  public time: any;
  public userName: string = '';

  public defaultLogo: string = 'assets/images/dummy-navBar-logo-transparent.png';
  public defaultLogoSmall: string = 'assets/images/dummy-navBar-Logo-transparent-s.png';
  public notification_count: number = 0;

  @ViewChild('sidenav') sidenav: MatSidenavModule;

  constructor(
    private headerService: HeaderService,
    private commonService: CommonService,
    private authService: AuthService,
    private router: Router,
    private cookieService: CookieService,
    private toastMsgService: ToastMsgService,
    private userService: UserService,
    private route: Router,
    private _accountService: AccountService,
    private ld: LaunchdarklyService,
    public dialog: MatDialog,
    private _commonService: CommonService
  ) {
     //Set configurableSettingsFlag for SSPR
     this.ld.configurableSettingsFlagChange.pipe(takeUntil(this._componentDestroyed$)).subscribe((flag:any) => {
      this.configurableSettingsFlag = flag;
    });
    this.router.events
      .pipe(
        takeUntil(this._componentDestroyed$),
        filter(event => event instanceof NavigationEnd),
        first(),
      ).subscribe(navigation => {
        const url = (navigation as NavigationEnd).url
        this.showHeader = url.indexOf('registration') < 0;

        if (this.showHeader) {
          this.userId = this.authService.getToken().user_id;

          this.checkPeproAccess();
          this._getUserDetails();
          this._notificationCount();

          //Polling after every 2 mins
          setInterval(() => {
            this.getNotificationCount();
          }, this.pollingInterval);

          setInterval(() => {
            this.currentDateTime();
          }, 1000);

        }

      });

    let details = navigator.userAgent;
    let regexp = /android|iphone|kindle|ipad/i;
    let isMobileDevice = regexp.test(details);
    if (isMobileDevice) {
      this.isDisabled = true;
      this.isExpanded = false;
    } else {
      this.isDisabled = false;
      this.isExpanded = true;
    }
    this.toastMsgService.isExpanded = this.isExpanded;

    this._commonService.accountSettings$
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe({
        next: accountSettings => {
          if (accountSettings)
            this.showPhoneDialer$.next(accountSettings.phone_dialer_provider_active == '1');
        }
      });

      this.authService.userDetails$.pipe(takeUntil(this._componentDestroyed$)).subscribe(
        {
          next:(userDetails=>{
            if(userDetails){
              this.userName = userDetails.fname + ' ' + userDetails.lname;
            }
          })
        }
      )
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event:any): void {
    if (this.sidenav !== undefined) {
      if (event.target.innerWidth <= 980) { // This can be any value.
        this.isExpanded = false;
      } else {
        this.isExpanded = true;
      }
      this.commonService.setMenuExpand(this.isExpanded);
      this.toastMsgService.isExpanded = this.isExpanded;
    }
  }

  private _notificationCount() {
    this.isNotifyAlert = this.headerService.getNotifyAlert()
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe({
        next: (response: any) => {
          if (response) {
            if(!this.isDismissedNotify) {
              this.getNotificationCount();
            }
            this.isDismissedNotify = true;
          }
        },
        error: (err) => { },
        complete: () => { }
      })
  }

  public ngOnInit(): void {
    this.currentDateTime();
  }

  public ngOnDestroy() {
    this._componentDestroyed$.next(true);
    this._componentDestroyed$.complete();
    this._componentLoaded$.next(true);
    this._componentLoaded$.complete();
    if(this._productFilterSub)
    this._productFilterSub.unsubscribe()
    if(this._cobrandSub)
    this._cobrandSub.unsubscribe();
    if (this.isNotifyAlert)
      this.isNotifyAlert.unsubscribe();
  }

  //Check if user has pepro link access
  private checkPeproAccess() {
    this._peproSub = this._peproSub ? this._peproSub : this.headerService.getPeproAccess()
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.result === "True") {
            this.hasPeproAccess = true;
          }
        }
      );
  }

  private currentDateTime() {
    let date = new Date();
    this.date = date.getDate();
    this.month = this.getFormattedValue('MMMM');
    this.day = this.getFormattedValue('dddd');
    this.year = this.getFormattedValue('YYYY');
    this.time = this.getFormattedValue("hh:mm a");
  }

  private getFormattedValue(format: string) {
    return moment().format(format);
  }

  public expendMenu() {
    this.isExpanded = !this.isExpanded;
    this.commonService.setMenuExpand(this.isExpanded);
    this.toastMsgService.isExpanded = this.isExpanded;
  }

  public openMenu() {
    this.isSubMenuLoading = true;
    this.showSubmenu = true;

    this._commonService.getLoggedInAccountSettings(this.commonService.getAccountId(), true)
      .pipe(first())
      .subscribe({
        next: () => {
          this.isSubMenuLoading = false;
        }
      });

  }

  private _getCobrandDetails(accountId: string) {

    this._cobrandSub = this._cobrandSub
      ? this._cobrandSub
      : this._accountService
          .getLoggedInCobrandDetails(accountId)
          .pipe(first())
          .subscribe({
            next: (cobrandDetails) => {
              this.authService.cobrandDetails$.next(cobrandDetails);
              this.defaultLogo = cobrandDetails.cobrand_asset_urls['logo_2'];
              this.defaultLogoSmall =
                cobrandDetails.cobrand_asset_urls['logo_1'];
            },
            error: (err) => {},
            complete: () => {},
          });
  }

  public openUserProfile() {
    const dialogRef = this.dialog.open(UserProfileComponent, {
      width: '100%',
      disableClose: true,
      autoFocus: false,
      data: {
        user_id: this.userId
      },
    });
  }

  public openConfigurableSettings() {
    this.dialog.open(ConfigurableSettingsComponent, {
      width: '55%',
      disableClose: true,
      autoFocus: true
    });
  }

  public openPepro() {
    const laWebCookie = this.authService.getAuthCookie();

    // Set PEP cookie
    this.cookieService.set(
      'LAWeb2PEWeb', laWebCookie,
      { path: '/', domain: '.leadadvantagepro.com', secure: true }
    )

    window.open(environment.PEPro, '_openPEProLink');
  }

  public openCallRecordingUserSettings() {
    this.dialog.open(PhoneDialerUserComponent, {
      width: '55%',
      disableClose: true,
      autoFocus: true
    });

  }

  /* to open the search dialog */
  public openSearchDialog() {
    window.scroll(0, 0);
    this.dialog.open(QuickSearchComponent, {
      width: '60%',
      disableClose: true,
      autoFocus: false,
      position: {
        top: '50px'
      }
    })
  }

  private _getUserDetails() {
    combineLatest([
      this.userService.getUserRolePermissions()
    ])
    .pipe(takeUntil(this._componentDestroyed$))
    .subscribe({
      next: ([userDetailsResponse]) => {
        const userDetails = userDetailsResponse.body!;

        this.isLoading = false;

        this.userName = userDetails.fname + ' ' + userDetails.lname;
        this.authService.user_details = userDetails;
        this.authService.userDetails$.next(userDetails);
        this.authService.account_id = userDetails.account_id;

        this.commonService.setAccountId(userDetails.account_id);

        this.authService.user_details['rolePermission'] = JSON.parse(userDetailsResponse.headers.get('x-lapro-acl-array') || '[]');
        this.authService.user_details['hash'] = userDetailsResponse.headers.get('x-lapro-acl');

        this._componentLoaded$.next(true);

        this._getCobrandDetails(userDetails.account_id);
        this._commonService.getLoggedInAccountSettings(userDetails.account_id)
          .pipe(first())
          .subscribe();
      },
      error: (err) => { },
      complete: () => { }
    });
  }

  /* notification functionality */
  private getNotificationCount() {
    this.headerService.getNotificationCount()
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          this.notification_count = response.notice_outstanding_count;
          this.commonService.tempPollingInterval = this.pollingInterval;
          if (response['includes_important'] == '1') {
            this.headerService.getUserNotice(0, 'quickNotify')
              .pipe(takeUntil(this._componentDestroyed$))
              .subscribe({
                next: (response: any) => {
                  if (response?.length) {
                    if(this.isNotifyDialog){
                      return;
                    }
                    this.isNotifyDialog = true;
                    const dialogRef = this.dialog.open(ImportantNoticeComponent, {
                      width: '60%',
                      disableClose: true,
                      data: response
                    });
                    dialogRef.afterClosed().pipe(takeUntil(this._componentDestroyed$)).subscribe((elem:any)=>{
                      this.isDismissedNotify = false;
                      this.isNotifyDialog = false;
                    })
                  }
                },
                error: (err) => {
                },
                complete: () => { }
              })
          }
        },
        error: (err) => { },
        complete: () => { }
      });
  }

  public openNotification() {
    this.headerService.getUserNotice(0, 'openNotify')
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe((response: any) => {
        let data: any;
        if (response.result == '') {
          data = 'No result';
        } else {
          data = response;
        }
        const dialogRef = this.dialog.open(NotificationComponent, {
          width: '65%',
          disableClose: true,
          data: data,
        });
      });
  }

  public logout(): void {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      width: '30%', disableClose: true,
      data: {
        'text': 'Are you sure you want to logout?'
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this._componentDestroyed$)).subscribe(res => {
      if (res) {
        this.authService.killCookie();
        this.dialog.closeAll();
        localStorage.removeItem('printSettingsCheck');
        this.authService.redirectToAuth();
      }
    });
  }

  // method to redirect to dashboard
  public redirectToMain() {
    this.route.navigate(['/'])
  }

}
