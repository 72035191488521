<div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="3.5%" fxLayoutAlign="left" class="w-100">
    <div class="dialog-blue-button-box w-100">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="3.5%" fxLayoutAlign="left" class="w-100">
            <mat-form-field fxFlex="40%">
                <mat-label>First Name</mat-label>
                <input matInput autocomplete="off" [(ngModel)]="fName" (mousedown)="$event.stopPropagation()" [appInputMaxLength]/>
            </mat-form-field>
            <mat-form-field fxFlex="40%">
                <mat-label>Last Name</mat-label>
                <input matInput autocomplete="off" [(ngModel)]="lName" (mousedown)="$event.stopPropagation()" [appInputMaxLength]/>
            </mat-form-field>
            <div fxFlex="15%">
                <button mat-button mat-raised-button type="button" color="primary" (click)="searchLead()">Search</button>
            </div>
        </div>
        <div *ngIf="isSearch && !fName && !lName">
            <small>You are required to fill atleast one field.</small>
        </div>
        <div class="w-100">
            <div *ngIf="isLoading && !isHavingData">
                <div class="loadingSpinner">
                    <app-sms-spinner [size]="'sm'">
                        <span>Retrieving search results</span>
                    </app-sms-spinner>
                </div>
            </div>
            <ng-container *ngIf="isSearch && (fName || lName) && !isLoading && !isHavingData">
                <p>No results found based on the selected criteria.</p>
            </ng-container>
            <ng-container *ngIf="isHavingData">
                <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z1 main-table w-100 table-striped">
                    <ng-container matColumnDef="fname">
                        <th mat-header-cell *matHeaderCellDef> Lead Name </th>
                        <td mat-cell *matCellDef="let row">{{row.fname}} {{row?.lname}} </td>
                    </ng-container>
                    <ng-container matColumnDef="dob">
                        <th mat-header-cell *matHeaderCellDef> DOB </th>
                        <td mat-cell *matCellDef="let row"> 
                            {{row?.dob ? (row?.dob | date : 'MM/dd/yyyy') : 'N/A'}} ({{row?.dob ? (row?.dob | agePipe) : ''}})
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="default_address">
                        <th mat-header-cell *matHeaderCellDef> Address </th>
                        <td mat-cell *matCellDef="let row"> {{row?.default_address?.address1}} </td>
                    </ng-container>
                    <ng-container matColumnDef="city">
                        <th mat-header-cell *matHeaderCellDef> City </th>
                        <td mat-cell *matCellDef="let row"> {{row?.default_address?.city}} </td>
                    </ng-container>
                    <ng-container matColumnDef="state">
                        <th mat-header-cell *matHeaderCellDef> State </th>
                        <td mat-cell *matCellDef="let row"> {{row?.default_address?.state_id}} </td>
                    </ng-container>
                    <ng-container matColumnDef="zip">
                        <th mat-header-cell *matHeaderCellDef> Zip </th>
                        <td mat-cell *matCellDef="let row"> {{row?.default_address?.zip}} </td>
                    </ng-container>
                    <ng-container matColumnDef="dispo_name">
                        <th mat-header-cell *matHeaderCellDef> Disposition </th>
                        <td mat-cell *matCellDef="let row"> {{row?.dispo_name}} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns ,sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="cursor-pointer"
                        [ngClass]="{'custom-class':row?.lead_id == selectedRow?.lead_id}" (click)="selectRow(row)">
                    </tr>
                </table>
                
            </ng-container>
            <mat-paginator class="mat-paginator-sticky" #assignLeadPagination  [length]="totalRecords" [pageSizeOptions]="[10, 20, 30, 40, 50]">
            </mat-paginator>
        </div>
    </div>
</div>
