import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LeadService } from '@data/services/lead/lead.service';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, debounceTime, filter, finalize, switchMap, tap } from "rxjs/operators";
import { AuthService } from '../service/auth.service';
import { RefreshTokenService } from '../service/refresh-token.service';

@Injectable()
export class HttpsInterceptor implements HttpInterceptor {
  private AUTH_HEADER = 'Authorization';
  private token: string;
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

	constructor(
    private auth: AuthService,
    private cookieService:CookieService,
		private refreshToken: RefreshTokenService,
    public leadService : LeadService
  ) {
		this.token = this.auth.getToken()?.access_token;
	}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.body instanceof FormData) {
      let ignore =
        typeof request.body === 'undefined' ||
        request.body === null ||
        request.body.toString() === '[object FormData]' || // <-- form data handling
        request.headers.has('Content-Type');

      if (ignore) {
        return next.handle(request);
      }
    }
    if (!request.headers.has('Content-Type')) {
      request = request.clone({
        headers: request.headers.set('Content-Type', 'application/json;charset=utf-8'),
      });
    }

    request = this.addAuthenticationToken(request);

    return next.handle(request).pipe(
      tap((res: any) =>{
        if (res instanceof HttpResponse) {
          let hash = res.headers.get('x-lapro-acl');
          if (this.auth?.user_details?.hash !== undefined && hash !== null) {
            if (this.auth.user_details.hash !== hash) {
              this.auth.killCookie();
            }
          }

          if (this.auth.getToken() != undefined && JSON.parse((decodeURIComponent(this.cookieService.get("LAWEB")))))
					{
						if (this.auth.user_details && this.auth.user_details.user_id != JSON.parse((decodeURIComponent(this.cookieService.get("LAWEB")))).user_id){
							//Dont kill cookie only redirect to auth
						this.auth.redirectToAuth();
						}
					}
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          console.error('access denied');
          this.auth.killCookie();
        } else if (error.status === 419) {
          if (this.refreshTokenInProgress) {
            // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
            // which means the new token is ready and we can retry the requests again
            return this.refreshTokenSubject.pipe(
              filter((result) => result !== null),
              debounceTime(0),
              switchMap(() => next.handle(this.addAuthenticationToken(request)))
            );
          } else {
            this.refreshTokenInProgress = true;
            // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
            this.refreshTokenSubject.next(null);

            return this.refreshAccessToken().pipe(
              switchMap((data) => {
                this.refreshTokenSubject.next(data);
                this.token = data.access_token;
                this.auth.setToken(data);
                return next.handle(this.addAuthenticationToken(request));
              }),
              // When the call to refreshToken completes we reset the refreshTokenInProgress to false
              // for the next time the token needs to be refreshed
              finalize(() => (this.refreshTokenInProgress = false))
            );
          }
        } else {
          return throwError(() => error);
        }
        return throwError(() => error);
      })
    );
  }

  private refreshAccessToken(): Observable<any> {
    return this.refreshToken.requestRefresh(this.auth.getToken().refresh_token);
  }

  private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
    // If we do not have a token yet then we should not set the header.
    // Here we could first retrieve the token from where we store it.

    if (!this.token) {
      return request;
    }
    this.leadService.access_token = this.token;
    return request.clone({
      headers: request.headers.set(this.AUTH_HEADER, 'Bearer ' + this.token),
      ...(request.method == "GET" || request.method == "POST") && {
        params: (request.params ? request.params : new HttpParams())
        .set('noCache', new Date().getTime() + this.makeid())
      }
    });
  }

  private makeid() {
    let text = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 5; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }
}
