import { Component, ElementRef, HostListener, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Notice } from '@data/notice/notice.interface';
import { NotificationService } from '@data/notification/notification.service';
import { HeaderService } from '@data/services/header/header.service';
import { CommonService, formatLeadExportData } from '@shared/services/common/common.service';
import moment from 'moment';
import { groupBy, map, mergeMap, of, reduce, Subscription, toArray } from 'rxjs';
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NotificationComponent implements OnInit {
  private dismissNoticeSubs: Subscription;
  private getUserNoticeSubs: Subscription;
  private noticeTODismiss: any = [];
  private page_number: number = 0;
  public data: any = [];
  public notice: Notice;
  public parentIndex: number = 0;
  public childIndex: number = 0;
  public notify_data: any = [];
  public pastNoticesMessage: string = '';
  public displayingNoUnreadMessages = false;
  public disableMarkAsRead: boolean = false;
  public errorMsg: string | null = null;
  @ViewChild('leadExportDiv') csvDownloadLinkDiv: ElementRef;

  constructor(
    private headerService: HeaderService,
    @Inject(MAT_DIALOG_DATA) public event: any,
    public dialogRef: MatDialogRef<NotificationComponent>,
    public commonService: CommonService,
    private _notificationService: NotificationService,
  ) {
    this.data = this.event;
    if (this.data != 'No result') {
      this.displayingNoUnreadMessages = true;
      this.disableMarkAsRead = true;
      this.notify_data = this.data;
      this.notify_data = formatLeadExportData(this.notify_data);
      this.getNotificationOrder();
    }
  }

  @HostListener('click', ['$event.target']) public onClick(targetElement: any) {
    const notificationID = targetElement.getAttribute('id') || null;
    let exportType = this._notificationService.getExportType(this.notice.notice_title || '');

    if (this.csvDownloadLinkDiv?.nativeElement?.contains(targetElement) && notificationID) {
      this._notificationService.processExportDownloadNotification(exportType, notificationID, (this.showErrorMessage).bind(this));
    }
  }

  public ngOnInit(): void {}

  /* by default first message is selected when notification popup is opened */
  public notificationMsg(message: any, parentIndex: any, childIndex: any) {
    this.displayingNoUnreadMessages = true;
    this.parentIndex = parentIndex;
    this.childIndex = childIndex;
    if (message.dismissed_date == '') {
      if (
        this.notify_data[parentIndex]['data'][childIndex]['disableMarkAsRead']
      ) {
        this.disableMarkAsRead = false;
      } else {
        this.disableMarkAsRead = true;
        this.notify_data[parentIndex]['data'][childIndex]['disableMarkAsRead'] =
          true;
      }
    }
    this.notice = this.notify_data[parentIndex]['data'][childIndex];
    let data = {
      notice_ids: [this.notice.notice_id],
    };
    this.dismissNoticeSubs = this.headerService.dismissNotice(data).subscribe({
      next: (response) => {
        this.headerService.sendNotifyAlert('dismissNotify');
      },
      error: (err) => {
        this.showErrorMessage(err.error.message);
      },
      complete: () => {},
    });
  }

  public formatDate(date: string) {
    return moment(date).format('MM/DD/YYYY');
  }

  /* displaying the message when post is read and posted time */
  public displayedMessageFrom(toDate: any) {
    //get the moment time from but show "today" for anything less than a day ago
    var momentFrom = moment(toDate).fromNow();
    if (
      momentFrom.indexOf('second') > -1 ||
      momentFrom.indexOf('minute') > -1 ||
      momentFrom.indexOf('hour') > -1
    )
      return 'today';
    else return momentFrom;
  }

  /* load the past notification with increasing the page number on every hit */
  public loadPastNotice() {
    this.page_number = this.page_number + 1;
    this.getUserNoticeSubs = this.headerService
      .getUserNotice(this.page_number, 'pastNotify')
      .subscribe({
        next: (response: any) => {
          if (!response.length) {
            this.pastNoticesMessage = 'There are no past notices';
            return;
          }
          if (this.data == 'No result') {
            this.data = [];
          }
          this.data = this.data.concat(response);
          this.notify_data = this.data;
          this.notify_data = formatLeadExportData(this.notify_data);
          this.pastNoticesMessage = '';
          this.getNotificationOrder();
        },
        error: (err) => {
          this.showErrorMessage(err.error.message);
        },
        complete: () => {},
      });
  }

  /* chnage the notification into oder by date */
  private getNotificationOrder() {
    of(...this.notify_data)
      .pipe(
        groupBy((p: any) => p.start_date),
        mergeMap((group$) =>
          group$.pipe(reduce((acc, cur) => [...acc, cur], [`${group$.key}`]))
        ),
        map((arr) => ({ date: arr[0], data: arr.slice(1) })),
        toArray()
      )
      .subscribe((p) => {
        this.notify_data = p; //.reverse();
      });
    this.parentIndex = 0;
    this.childIndex = 0;
    this.notice = this.notify_data[0]['data'][0];
    if (this.notice.dismissed_date == '') {
      if (this.notify_data[0]['data'][0]['disableMarkAsRead']) {
        this.disableMarkAsRead = false;
      } else {
        this.disableMarkAsRead = true;
        this.notify_data[0]['data'][0]['disableMarkAsRead'] = true;
      }
    }
  }

  /* mark all as read notification */

  public markAllRead() {
    this.displayingNoUnreadMessages = false;
    for (let i = 0; i < this.notify_data.length; i++) {
      for (let j = 0; j < this.notify_data[i]['data'].length; j++) {
        this.notify_data[i]['data'][j]['disableMarkAsRead'] = true;
        this.noticeTODismiss.push(this.notify_data[i]['data'][j].notice_id);
      }
    }
    let obj = {
      notice_ids: this.noticeTODismiss,
    };
    this.dismissNoticeSubs = this.headerService.dismissNotice(obj).subscribe({
      next: (response) => {
        this.headerService.sendNotifyAlert('dismissNotify');
      },
      error: (err) => {
        this.showErrorMessage(err.error.message);
      },
      complete: () => {},
    });
  }

  /* mark as read notification */
  public markRead(noticeid: string) {
    this.displayingNoUnreadMessages = false;
    let data = {
      notice_ids: [noticeid],
    };
    this.dismissNoticeSubs = this.headerService.dismissNotice(data).subscribe({
      next: (response) => {
        this.headerService.sendNotifyAlert('dismissNotify');
      },
      error: (err) => {
        this.showErrorMessage(err.error.message);
      },
      complete: () => {},
    });
  }

  private showErrorMessage(errorMsg: string) {
    this.errorMsg = errorMsg;
    setTimeout(() => {
      this.errorMsg = null;
    }, 30000);
  }

  /* clear notice message */
  public clearMessage(timer: number) {
    setTimeout(() => {
      this.errorMsg = null;
    }, timer);
  }

  public checkForCMS(notice: Notice) {
    if (
      notice?.notice_title?.indexOf(
        'IMPORTANT COMPLIANCE INFORMATION – CMS DISCLAIMER'
      ) != -1
    )
    this.commonService.callCMSPostCall();
  }

  public ngOnDestroy() {
    if (this.dismissNoticeSubs) {
      this.dismissNoticeSubs.unsubscribe();
    }
    if (this.getUserNoticeSubs) {
      this.getUserNoticeSubs.unsubscribe();
    }
  }
}
