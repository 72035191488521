import { Environment } from "@shared/interfaces/environment.interface";

export const environment: Environment = {
  production: true,
  env: "PROD",
  authClient: "https://authorize.leadadvantagepro.com/",
  api: "https://api.leadadvantagepro.com/api/",
  registrationApi: "https://api.leadadvantagepro.com/",
  insfire: "https://www.insfiretech.com/",
  PEPro: "https://planexplorer.leadadvantagepro.com/",
  PEAPI: "https://planexplorerapi.leadadvantagepro.com/",
  INSURACTIVE_ACCOUNT_ID: "11229",
  PROTECTOR_PLUS_DENTAL: "https://myplan.ameritas.com/ppd/id/",
  TO: "lapro_issue@leadadvantage.atlassian.net",
  CC: "internetmarketing@seniormarketsales.com",
  JIRA_EMAIL : 'lapro_issue@leadadvantage.atlassian.net',
  INTERNET_MARKETING_EMAIL : 'internetmarketing@seniormarketsales.com',
  SENDER_EMAIL : 'message@insfiretech.com',
  CMSLink: 'https://cmscheck.seniormarketsales.com/mycarriers',
  launchDarklyClientId: '659ee04b6fb21a1099286919',
  SSP_URL: 'https://authorize.leadadvantagepro.com/SelfService/',
};
