<div autocomplete="off" [formGroup]="emailForm">
  <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
    <div fxLayout="row wrap">
      <div fxFlex.gt-lg="53" fxFlex.gt-md="53" fxFlex.gt-xs="100" fxFlex="100">
        <h4 class="user-profile-heading text-upper">Emails</h4>
      </div>
      <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
        <div class="example-button-row report-btn">
          <span class="material-icons plus" [matMenuTriggerFor]="emailMenu" title="Add Entry">add</span>
        </div>
        <mat-menu #emailMenu="matMenu">
          <ng-container *ngFor="let content of email_content">
            <button *ngIf="!content.isSelected" (click)="addEmailType(content)" mat-menu-item>{{content.name}}</button>
          </ng-container>
        </mat-menu>
      </div>
      <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100" formArrayName="emails">
        <ng-container *ngFor="let email of emailListArr?.controls; let i=index" [formGroupName]="i">
          <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="left" fxLayoutGap="3.5%">
            <mat-form-field class="example-full-width" fxFlex="20%">
              <mat-label>Type</mat-label>
              <mat-select disableOptionCentering matNativeControl formControlName="email_type" [disabled]="email?.value?.email_class == 'true'"
                [ngClass]="{'line-through': email?.value?.email_class == 'true'}">
                <mat-option *ngFor="let content of email_content" value="{{content.name}}">{{content.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="example-full-width" fxFlex="50%">
              <mat-label>Email</mat-label>
              <input [class]="email?.value?.email_class == 'true' ? 'not-allowed' : ''"
                [readonly]="email?.value?.email_class == 'true'" (mousedown)="$event.stopPropagation()"
                [appInputMaxLength] matInput autocomplete="off" formControlName="email"
                [ngClass]="{'line-through': email?.value?.email_class == 'true'}" appDisableAutofill>
              <mat-error *ngIf="email?.get('email')?.errors && submitted">
                Please enter correct email
              </mat-error>
            </mat-form-field>
            <mat-icon class="close" title="Undo" *ngIf="email?.value?.email_class == ''"
              (click)="clearEmail(email.value,i)">clear
            </mat-icon>
            <mat-icon *ngIf="email?.value?.email_class == 'true'" (click)="refreshEmail(email.value,i)">
              replay</mat-icon>
            <mat-checkbox *ngIf="type!='admin'" (change)="emailCheckBoxvalue($event,email.value,i)"
              [checked]="email?.value?.isSelected==true || type == 'plan' || type=='add-user'">
              &nbsp;&nbsp;</mat-checkbox>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>