import { AfterContentInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from '@app/service/auth.service';
import { LeadService } from '@data/services/lead/lead.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-select-assign-lead',
  templateUrl: './assign-lead.component.html',
  styleUrls: ['./assign-lead.component.scss']
})
export class AssignLeadComponent implements OnInit, OnChanges, AfterContentInit {
  private leadSearchSubs: Subscription;
  private assignLeadArray: any = [];
  @Input() clearSearch: boolean = false;
  @Output() lead = new EventEmitter<any>();
  @ViewChild(MatPaginator, { static: true }) assignLeadPagination!: MatPaginator;
  public displayedColumns: string[] = ['fname', 'dob', 'default_address', 'city', 'state', 'zip', 'dispo_name'];
  public dataSource = new MatTableDataSource();
  public isSearch: boolean = false;
  public fName: string = "";
  public lName: string = "";
  public isLoading: boolean = false;
  public isHavingData: boolean = false;
  public totalRecords: number = 0;
  public selectedRow: any;

  constructor(private authService: AuthService,
    private leadService: LeadService) { }

  public ngOnInit(): void { }

  public ngOnChanges(changes: SimpleChanges): void {
    this.dataSource.paginator = this.assignLeadPagination;
    if (this.clearSearch) {
      this.fName = "";
      this.lName = "";
      this.dataSource = new MatTableDataSource();
      this.selectedRow = "";
      this.isHavingData = this.isSearch = false;
      this.totalRecords = 0
    }
  }

  public ngAfterContentInit() {
    this.dataSource.paginator = this.assignLeadPagination;
  }

  public selectRow(row: any) {
    this.selectedRow = row;
    this.lead.emit(this.selectedRow);
  }

  public searchLead() {
    if (this.fName || this.lName) {
      let paramsObj = {
        search_type: "simple",
        criteria: {
          account_id: {
            type: "simple",
            value: [this.authService.account_id]
          },
          ...(this.fName) && {
            fname: {
              type: "simple",
              value: [`${this.fName}%`]
            }
          },
          ...(this.lName) && {
            lname: {
              type: "simple",
              value: [`${this.lName}%`]
            }
          }
        },
        field_list: "lead_id,fname,lname,dispo_name,dob,default_address",
        page_number: 1
      }

      this.isLoading = true;
      this.leadSearchSubs = this.leadService.leadSearch(paramsObj).subscribe({
        next: (res: any) => {
          this.isHavingData = res?.message ? false : true;
          this.totalRecords = res?.length;
          this.assignLeadArray = res;
          this.dataSource = new MatTableDataSource(this.isHavingData ? this.assignLeadArray: []);
          this.dataSource.paginator = this.assignLeadPagination;
          this.isLoading = false;
        }, error: err => {
          this.isLoading = false;
        }
      });

    }
    this.isSearch = true;
  }

  public ngOnDestroy(){
    if(this.leadSearchSubs){
      this.leadSearchSubs.unsubscribe()
    }
  }
}
