import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-permission-history',
  templateUrl: './permission-history.component.html',
  styleUrls: ['./permission-history.component.scss']
})
export class PermissionHistoryComponent implements OnInit {
  private historyData: any;
  public contactDisplayedColumns: string[] = ['lead_contact_permission_type', 'lead_contact_permission_to_contact', 'lead_contact_permission_change_date', 'login', 'fname'];
  public contactDataSource: any;
  @ViewChild('contactPaginator', { static: true }) contactPaginator!: MatPaginator;
  @ViewChild('contactSort', { static: true }) contactSort!: MatSort;

  constructor(
    @Inject(MAT_DIALOG_DATA) public permission: any,
    public dialogRef: MatDialogRef<PermissionHistoryComponent>,
  ) {
    this.historyData = permission;
  }

  ngOnInit(): void {
    this.contactDataSource = new MatTableDataSource(this.historyData);
    this.contactDataSource.sortingDataAccessor = (item: any, property: any) => {
      switch (property) {
        case 'login': return item.lead_contact_permission_change_user.login;
        case 'fname': return item.lead_contact_permission_change_user.fname;
        default: return item[property];
      }
    };
    this.contactDataSource.paginator = this.contactPaginator;
    this.contactDataSource.sort = this.contactSort;
  }
}
