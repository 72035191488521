import { Directive } from '@angular/core';

@Directive({
  selector: '[appDialogScroll]'
})
export class DialogScrollDirective {

  constructor() { }

  ngOnInit() {
    var root = document.getElementsByTagName('html')[0];
    root.classList.add('no-popup-scroll');
  }

  ngOnDestroy() {
    var root = document.getElementsByTagName('html')[0];
    root.classList.remove('no-popup-scroll');
  }

}
