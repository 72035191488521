import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { CommonService } from '@shared/services/common/common.service';
import { ToastMsgService } from '@shared/services/toastr/toast-msg.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from './core/service/auth.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  private _componentDestroyed$ = new Subject<boolean>;
  private _previousRoute: any = this._router.titleStrategy;

  public isExpanded: boolean = true;
  public message$ = new Subject<any>;
  public _selectedTab: string;

  constructor(
    private _auth: AuthService,
    private _toasMsgService: ToastMsgService,
    private _bnIdle: BnNgIdleService,
    private _router: Router,
    private activatedRoute: ActivatedRoute,
    private _commonService: CommonService
  ) {
    this._toasMsgService.subject
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe((res: any) => {
        if (res) {
          this.isExpanded = this._toasMsgService.isExpanded;
          this.message$.next(res);
        }
      });

    this._router.events
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe((event: Event) => {
        if (event instanceof NavigationStart) {

          this._toasMsgService.resetMessage();
          // ...
        }

        if (event instanceof NavigationEnd) {
          // ...
        }

        if (event instanceof NavigationError) {
          // ...
        }
      });

    this._commonService.getMenuExpand()
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe(response => {
        this.isExpanded = response;
      });

  }

  ngOnInit() {
    this._selectedTab = this.activatedRoute.snapshot.paramMap.get('tabName') || 'mapartd';
    const currentPage = this._previousRoute.title._doc.location.pathname || '/';
    const allowPageRefresh = [
        '^\/lead.*',
        '^\/registration\.html',
        '^\/quotes\/(mapartd|medsup|final)\/[0-9]+$', // Allow only few product types followed by numbers
      ].filter( urlPattern => currentPage.match(urlPattern) !== null );

    // if user is going to registration page, don't set a cookie
    if (currentPage !== '/registration.html')
      this._auth.setCookie();

    // only allow these URLs to do a page refresh
    if (currentPage !== '/' && !allowPageRefresh.length)
      this._router.navigate([''])

    this._bnIdle.startWatching(18000)
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe((isTimedOut: boolean) => {
        if (isTimedOut) {
          this._auth.killCookie();
        }
      });
  }

  ngOnDestroy() {
    this._componentDestroyed$.next(true);
    this._componentDestroyed$.complete();
  }

  public clearMessage() {
    this._toasMsgService.resetMessage();
  }

  public isProd() {
    return environment.production;
  }

}
