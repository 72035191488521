<app-header></app-header>

<app-sidebar [isExpanded]="isExpanded">
    <app-sms-notice-banner *ngIf="!isProd()" [type]="''" [showIcon]="false" [canDismiss]="false"
        class="fixed top-14 w-full z-10 text-black bg-orange-400">
        <div class="flex flex-row items-center gap-2">
            <mat-icon>warning</mat-icon>
            <span>WARNING: This is NOT a Production environment. Use for TESTING purposes ONLY.</span>
        </div>
    </app-sms-notice-banner>

    <ng-container *ngIf="message$ | async as message">
        <app-sms-notice-banner  #messageBanner class="banner" [ngClass]="{'expand-banner-width':!isExpanded}"
            *ngIf="message.text != ''"  (closeBanner)="clearMessage()" [type]="message.type">
            <p>{{ message.text ? message.text : 'An error occurred, please try again' }}</p>
        </app-sms-notice-banner>
    </ng-container>

</app-sidebar>
