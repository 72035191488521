import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { environment } from '@env/environment';
import { Reports } from '@data/reports/reports.interface';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private http: HttpClient) { }

  public getReportData(payload: Reports) {
    return this.http.post((environment.api) + "report", payload).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }
  public getCSVData(payload:Reports){

    var reportAPI = '';

    //set the API URL for report_type
    switch(payload.report_type){
      case 'aicoe':
      case 'f2f':
      case 'vsig':
        reportAPI = 'app/aicoe_data/csv';
        break;
      case 'selfservice':
        reportAPI = 'SelfServiceEmailInvite/report/';
        break;
    }

    return this.http.post((environment.api) + reportAPI, payload).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );

  }

  public reportRequestByDrug(payload: Reports) {
    return this.http.post((environment.api) + "search/leadsByDrug/", payload).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public downloadLeadsByDrug(id: string) {
    return this.http.get(environment.api + 'search/leadsByDrug/download/'+ id, {responseType: 'text'}).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public reportRequestByPharmacy(payload: Reports) {
    return this.http.post((environment.api) + "search/leadsByPharmacy/", payload)
      .pipe(
        catchError( error => throwError(() => error) ),
      );
  }

  public downloadLeadsByPharmacy(id: string) {
    return this.http.get(environment.api + 'search/leadsByPharmacy/download/'+ id, { responseType: 'text' })
      .pipe(
        catchError( error => throwError(() => error) ),
      );
  }

  public reportRequestByDisruption(payload: Reports) {
    return this.http.post((environment.api) + "search/leadsByDisruption/", payload)
      .pipe(
        catchError( error => throwError(() => error) ),
      );
  }

  public downloadLeadsByDisruption(id: string) {
    return this.http.get(environment.api + 'search/leadsByDisruption/download/'+ id, { responseType: 'text' })
      .pipe(
        catchError( error => throwError(() => error) ),
      );
  }

}
