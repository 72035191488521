<div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <div cdkDragHandle class="mat-dialog-title" mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
        <h2>Select an Outbound Dial Method</h2>
        <button mat-icon-button mat-dialog-close aria-label="Close Modal"><mat-icon>close</mat-icon></button>
    </div>

    <div class="mat-dialog-content" mat-dialog-content>
        <div>
            <h3>Attention</h3>
            <p>Centers for Medicare and Medicaid Services (CMS) requires agents to record all calls with beneficiaries that are part of the chain of enrollment into a Medicare Advantage or Part D Plan</p>
            <p>Phone.com provides a turnkey solution for satisfying CMS call recording requirements.</p>
        </div>

        <div id="phone-dialer-options" fxLayout="row" fxLayoutAlign="center stretch" fxLayoutGap="1em">
            <p class="option-label">Select a Dial Method:</p>
            <mat-card>
                <mat-card-content class="phone-dialer-option" fxLayout="column" fxLayoutAlign="space-between center" >
                    <div>
                        <mat-icon class="phone-dialer-logo icon" inline>phone</mat-icon>
                        <img src="assets/images/phonecom_glyph.png" class="phone-dialer-logo" aria-hidden="true" />
                    </div>
                    <p class="option-description">Phone.com</p>
                    <p class="option-description note">(automated call recording)</p>
                    <button mat-button type="button" class="phone-dialer-option-action phone-dialer-setup-button" mat-dialog-close (click)="dialWithPhoneDialer()">Dial with Automated Call Recording</button>
                </mat-card-content>
            </mat-card>
            <mat-card>
                <mat-card-content class="phone-dialer-option" fxLayout="column" fxLayoutAlign="space-between center">
                    <div>
                        <mat-icon class="phone-dialer-logo icon" inline>phone</mat-icon>
                    </div>
                    <p class="option-description">Standard Default</p>
                    <p class="option-description note">(no call recording)</p>
                    <button mat-button type="button" class="phone-dialer-option-action" mat-dialog-close color="primary" (click)="dialWithBrowserDefault()">Dial with my default phone dialer</button>
                </mat-card-content>
            </mat-card>
        </div>
    </div>

    <div mat-dialog-actions align="center">
        <mat-checkbox (change)="updatePreference($event)">Remember my selection</mat-checkbox>
    </div>

</div>
