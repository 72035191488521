import { FormGroup } from '@angular/forms';
import moment from 'moment';
    
export function StartEndTimeValidator(startTime: string, endTime: string,startDate: string, endDate:string){
    return (formGroup: FormGroup) => {
        const start_Time = formGroup.controls[startTime];
        const end_Time = formGroup.controls[endTime];
        const start_Date =  formGroup.controls[startDate];
        const end_Date = formGroup.controls[endDate]
        if (moment(start_Date.value).format('YYYY-MM-DD') > moment(end_Date.value).format('YYYY-MM-DD')) {
            start_Date.setErrors({ timeValidator: true });
        } else if (moment(start_Date.value).format('YYYY-MM-DD') == moment(end_Date.value).format('YYYY-MM-DD')) {
            if (start_Time.value > end_Time.value) {
                start_Time.setErrors({ timeValidator: true });
            } else {
                start_Time.setErrors(null);
                start_Date.setErrors(null);
            }
        }
        else if (moment(start_Date.value).format('YYYY-MM-DD') < moment(end_Date.value).format('YYYY-MM-DD')) {
            start_Date.setErrors(null);
        }
        else if (start_Time.value > end_Time.value) {
            start_Time.setErrors({ timeValidator: true });
        } else {
            start_Time.setErrors(null);
            start_Date.setErrors(null);
        }
    }
}