<div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <div cdkDragHandle class="flex justify-between items-baseline">
    <h2 mat-dialog-title>Notices</h2>
    <button mat-icon-button mat-dialog-close><mat-icon>close</mat-icon></button>
  </div>

  <mat-divider></mat-divider>

  <div mat-dialog-content>
    <app-sms-notice-banner *ngIf="errorMsg" type="alert" (closeBanner)="clearMessage(0)">
      <p>{{ errorMsg }}</p>
    </app-sms-notice-banner>

    <div class="flex">
      <ng-template><!-- Notifcation Labels - Left section --></ng-template>
      <div class="w-2/5 py-2 overflow-y-scroll min-h-24 max-h-96">
        <ng-container *ngIf="notify_data == ''; else showNotificationList"></ng-container>
        <ng-template #showNotificationList>
          <mat-selection-list [multiple]="false" [hideSingleSelectionIndicator]="true" class="pb-2">

            <ng-container *ngFor="let noti of notify_data; let i = index">
              <span mat-subheader [innerHTML]="formatDate(noti.date)"></span>

              <ng-container *ngFor="let data of noti.data; let ii = index">
                <mat-list-option (click)="notificationMsg(data,i, ii)">
                  <span class="flex row gap-1 items-center">
                    <mat-icon class="shrink-0" *ngIf="parentIndex == i && childIndex == ii">star</mat-icon>
                    <mat-icon class="shrink-0" *ngIf="data.important_popup == '1'">error</mat-icon>
                    <!-- <mat-icon *ngIf="parentIndex != i && childIndex != ii">remove</mat-icon> -->
                    <span class="shrink italic inline-block">{{ commonService.truncateChar(data.notice_title, 46) }}</span>
                  </span>
                </mat-list-option>
              </ng-container>
              <mat-divider></mat-divider>

            </ng-container>

          </mat-selection-list>
        </ng-template>

        <button type="button" mat-flat-button (click)="loadPastNotice()"><mat-icon>chevron_right</mat-icon> Load Past Notices</button>
        <p class="px-4" *ngIf="pastNoticesMessage != ''" [innerHTML]="pastNoticesMessage"></p>
      </div>

      <ng-template><!-- Notifcation Content - Right section --></ng-template>
      <div class="w-3/5 p-2 overflow-y-scroll max-h-96">
        <ng-container *ngIf="notify_data == ''; else showNotificationHistory">
          <p>There are no unread messages. You can access your notification history by selecting "Load Past Notices" on the left.</p>
        </ng-container>
        <ng-template #showNotificationHistory>
          <div class="flex justify-between">
            <p *ngIf="notice?.dismissed_date">Read <span [innerHTML]="displayedMessageFrom(notice.dismissed_date)"></span></p>
            <p *ngIf="notice?.start_date">Posted <span [innerHTML]="displayedMessageFrom(notice.start_date)"></span></p>
          </div>

          <div>
            <p class="text-center font-bold text-2xl" *ngIf="notice?.notice_title" [innerHTML]="notice.notice_title"></p>
            <div #leadExportDiv (click)="checkForCMS(notice)" *ngIf="notice?.notice_text" [innerHTML]="notice.notice_text | safeHtml"></div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>

  <div mat-dialog-actions align="end">
    <button type="button" mat-raised-button *ngIf="displayingNoUnreadMessages && disableMarkAsRead" (click)="markAllRead()">Mark All As Read</button>
    <button type="button" mat-raised-button *ngIf="displayingNoUnreadMessages && disableMarkAsRead" (click)="markRead(notice.notice_id || '')">Mark As Read</button>
    <button mat-raised-button mat-dialog-close>Close</button>
  </div>
</div>
