<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="custom-modal">
    <div class="dialog-title" cdkDragHandle>
        <h2 mat-dialog-title>New Lead</h2>
        <mat-icon mat-dialog-close class="cursor-pointer">close</mat-icon>
    </div>
    <mat-divider></mat-divider>
    <mat-dialog-content class="mat-typography">
        <div class="basic-container">
            <ng-container *ngIf="message.type">
                <app-sms-notice-banner #messageBanner [type]="message.type" (closeBanner)="clearMessage(0)">
                    <p>{{ message.text }}</p>
                </app-sms-notice-banner>
            </ng-container>
            <form [formGroup]="addLeadForm">
                <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="3.5%" fxLayoutAlign="left">
                    <mat-form-field class="example-full-width" fxFlex="50%">
                        <input matInput placeholder="First Name" formControlName="fname" [maxlength]="25" autocomplete="off" required (mousedown)="$event.stopPropagation()" [appInputMaxLength] appDisableAutofill/>
                        <mat-hint *ngIf="addLeadForm.value.fname.length == 25" class="error-msg">Maximum length is 25 characters</mat-hint>
                        <mat-error *ngIf="checkError('fname', 'required')">First name is a required field.</mat-error>
                    </mat-form-field>
                    <mat-form-field class="example-full-width" fxFlex="50%">
                        <input matInput placeholder="Last Name" formControlName="lname" [maxlength]="25" autocomplete="off" (mousedown)="$event.stopPropagation()" [appInputMaxLength] appDisableAutofill/>
                        <mat-hint *ngIf="addLeadForm.value.lname.length == 25" class="error-msg">Maximum length is 25 characters</mat-hint>
                        <mat-error *ngIf="checkError('lname', 'required')">Last name is a required field.</mat-error>
                    </mat-form-field>
                </div>
                <div *ngIf="isb2bPartner" fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="3.5%"
                    fxLayoutAlign="left">
                    <mat-form-field class="example-full-width" fxFlex="50%">
                        <mat-label>Client/Partner/Affiliate</mat-label>
                        <mat-select disableOptionCentering formControlName="b2bpartner_id" (selectionChange)="b2bChange($event.value)">
                            <mat-option *ngFor="let b2b of b2bPartner_data" value="{{b2b.b2bpartner_id}}"
                                [innerHTML]="b2b.company"></mat-option>
                        </mat-select>
                        <mat-error *ngIf="checkError('b2bpartner_id', 'required')">Client/Partner/Affiliate is a
                            required field.</mat-error>
                    </mat-form-field>
                    <mat-form-field class="example-full-width" fxFlex="50%">
                        <mat-label>Tracking Code</mat-label>
                        <mat-select disableOptionCentering formControlName="source_code">
                            <ng-container *ngFor="let tCode of tracking_data">
                                <mat-option *ngIf="tCode.active == '1'" value="{{tCode.tracking_code}}"
                                [innerHTML]="tCode.tracking_code"></mat-option>
                            </ng-container>
                        </mat-select>
                        <mat-error *ngIf="checkError('source_code', 'required')">Tracking Code is a required field.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="3.5%" fxLayoutAlign="left">
                    <mat-form-field fxFlex="19%">
                        <mat-select formControlName="phone_type">
                            <mat-option *ngFor="let phoneType of phoneTypes" [value]="phoneType.value">{{ phoneType.id }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field fxFlex="25%">
                        <input matInput placeholder="Telephone #" formControlName="phone" autocomplete="off"
                            appPhoneNumberMask [minlength]="14" (mousedown)="$event.stopPropagation()" appDisableAutofill>
                            <mat-error *ngIf="addLeadForm.value.phone.length != 14">Please enter a valid phone number
                            </mat-error>
                        </mat-form-field>
                    <mat-form-field fxFlex="19%">
                        <mat-select formControlName="email_type">
                            <mat-option *ngFor="let emailType of emailTypes" [value]="emailType.value">{{ emailType.id }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field fxFlex="25%">
                        <input matInput placeholder="Email" formControlName="email" autocomplete="off" (mousedown)="$event.stopPropagation()" [appInputMaxLength] 	/>
                        <mat-error *ngIf="checkError('email', 'pattern')" appDisableAutofill>
                            Please enter a valid email address
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="3.5%" fxLayoutAlign="left">
                    <mat-form-field class="example-full-width" fxFlex="75%">
                        <mat-label>Birthdate</mat-label>
                        <input matInput formControlName="dob" autocomplete="off"
                            [max]="maxDate" appDate (mousedown)="$event.stopPropagation()">
                            <mat-error *ngIf="checkError('dob', 'invalidDate')">You must enter a valid date.
                            </mat-error>
                    </mat-form-field>
                    <mat-form-field class="example-full-width">
                        <mat-label>Gender</mat-label>
                        <mat-select disableOptionCentering formControlName="gender">
                            <mat-option></mat-option>
                            <mat-option value="M">Male</mat-option>
                            <mat-option value="F">Female</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="3.5%" fxLayoutAlign="left"
                    layout-margin>
                    <mat-form-field fxFlex="30%">
                        <input matInput placeholder="Zip Code" formControlName="zip" autocomplete="off" [minlength]="5"
                            [maxlength]="5" appNumbersOnly (keyup)="getCounties()" (mousedown)="$event.stopPropagation()"  	/>
                    </mat-form-field>
                    <mat-form-field fxFlex="30%">
                        <mat-label>County</mat-label>
                        <mat-select disableOptionCentering formControlName="county">
                            <mat-optgroup *ngFor="let state of statesData;let i=index" label="{{state.state_name}}">
                                <mat-option *ngFor="let county of state.counties; let ii=index" (click)="changeOption(state)"
                                    [value]="county?.county_name"> {{county?.county_name}} </mat-option>
                            </mat-optgroup>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field fxFlex="30%">
                        <mat-label>State</mat-label>
                        <mat-select disableOptionCentering formControlName="state_id" (selectionChange)="changeState($event)">
                            <mat-option class="custom-select-option"></mat-option>
                            <mat-option  class="custom-select-option" *ngFor="let state of states" [value]="state?.abbreviation">
                                {{state?.abbreviation}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="3.5%" fxLayoutAlign="left"
                    layout-margin>
                    <div class="editor">
                        <label>Notes</label>
                        <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
                        <ngx-editor (mousedown)="$event.stopPropagation()" [editor]="editor" formControlName="notes"></ngx-editor>
                    </div>
                </div>
            </form>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-raised-button mat-dialog-close>Cancel</button>
        <button mat-raised-button color="primary" *ngIf="addLeadForm.valid" [disabled]="isButtonClicked" (click)="addLead('simple')">Add</button>
        <button mat-raised-button color="primary" *ngIf="addLeadForm.valid" [disabled]="isButtonClicked" (click)="addLead('details')">Add +
            Details</button>
    </mat-dialog-actions>
</div>
