<div fxLayout="row wrap">
  <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
    <div style="text-align: center">
      <h2>
        We're sorry. We're currently exploring the root cause of this unplanned
        downtime.
      </h2>
      <p>Please plan to try again later.</p>
    </div>
  </div>
</div>
