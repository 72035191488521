<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="custom-modal" appDialogScroll>
    <div class="dialog-title" cdkDragHandle>
        <h2 mat-dialog-title *ngIf="event.type == 'add'">Add {{event['tabName']}}</h2>
        <h2 mat-dialog-title *ngIf="event.type != 'add'">{{isMode ? 'Edit' : 'Add'}} {{isAppointment ? 'Appointment' : 'Task'}} </h2>
        <mat-icon class="cursor-pointer" (click)="closeModal()">close</mat-icon>
    </div>
    <mat-divider></mat-divider>
    <mat-dialog-content class="mat-typography">
        <div class="basic-container">
            <ng-container *ngIf="event.type == 'add'">
                <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="3.5%" fxLayoutAlign="left">
                    <mat-tab-group class="admin-sub-tab quick-search-tab" mat-align-tabs="start" (selectedTabChange)="tabClick($event)"
                        [selectedIndex]="selectedTabIndex" animationDuration="0ms" fxFlex="100">
                        <mat-tab label="Appointment">
                            <app-add-appointment (aptChange)="sendAptChange($event)" (addEvent)="onAdd($event)" [isFromCalendar]="calendarData">
                            </app-add-appointment>
                        </mat-tab>
                        <mat-tab label="Task">
                            <app-add-task (taskChange)="sendTaskChange($event)" (addEvent)="onAdd($event)" [isFromCalendar]="calendarData"></app-add-task>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </ng-container>
            <ng-container *ngIf="event.type != 'add'">
                <div class="basic-container" *ngIf="isAppointment">
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="3.5%" fxLayoutAlign="left">
                        <app-add-appointment (aptChange)="sendAptChange($event)" [isFromCalendar]="calendarData"  fxFlex="100"></app-add-appointment>
                    </div>
                </div>
                <div class="basic-container" *ngIf="!isAppointment">
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="3.5%" fxLayoutAlign="left">
                        <app-add-task (taskChange)="sendTaskChange($event)" [isFromCalendar]="calendarData"  fxFlex="100"></app-add-task>
                    </div>
                </div>
            </ng-container>
        </div>
    </mat-dialog-content>
</div>
