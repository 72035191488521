<div id="phone-dialer-user-setup" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <div cdkDragHandle class="dialog-title" mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
        <h2 mat-dialog-title>Call Recording</h2>
        <button mat-icon-button mat-dialog-close aria-label="Close Modal"><mat-icon>close</mat-icon></button>
    </div>

    <mat-tab-group mat-dialog-content class="mat-dialog-content">
        <mat-tab label="Dialers">
            <div class="mat-tab-content">
                <p>We have partnered with Phone.com to provide a call recording solution to meet your current and future compliance needs.</p>

                <ng-container *ngIf="isLoading; else phoneDialerSetting">
                    <app-sms-spinner></app-sms-spinner>
                </ng-container>

                <ng-template #phoneDialerSetting>
                    <form name="phoneDialerUserSettingForm" id="phoneDialerUserSettingForm"
                        [formGroup]="phoneDialerUserSettingForm">

                        <mat-checkbox formControlName="phone_dialer_provider_active">
                            <span><strong>Do not show Phone.com dialer option</strong></span>
                        </mat-checkbox>

                        <ol *ngIf="!phoneDialerUserSettingForm.get('phone_dialer_provider_active')?.value">
                            <li class="option-input">
                                <p>To get started with buit-in call recording, you will need to sign up for an account with Phone.com.</p>
                                <a mat-button class="signupButton" mat-dialog-close
                                    (click)="redirectToDialerLandingPage()">Sign up for a Phone.com account</a>
                            </li>
                            <li class="option-input">
                                <p>Once you have a Phone.com account, you can connect it here to allow us to initiate outbound calls through the Phone.com dialer.</p>
                                <a mat-button class="signupButton" mat-dialog-close
                                    (click)="redirectToDialerLandingPage()">Connect your Phone.com account</a>
                            </li>
                            <li class="option-input">
                                <p>Finally, decide what should happen when clicking on phone numbers</p>
                                <mat-radio-group formControlName="phone_dialer_provider_behavior_type_id" aria-label="Select an option">
                                    <mat-radio-button class="phoneDialerBehaviorType" *ngFor="let behaviorType of phoneDialerBehaviorTypes"
                                        [value]="behaviorType.phone_dialer_provider_behavior_type_id">
                                        <strong>{{behaviorType.phone_dialer_provider_behavior_type_name}}</strong>
                                    </mat-radio-button>
                                </mat-radio-group>
                            </li>
                        </ol>
                    </form>
                </ng-template>

            </div>
        </mat-tab>
    </mat-tab-group>

    <div mat-dialog-actions *ngIf="phoneDialerUserSettingForm.dirty" align="end">
      <button mat-button mat-dialog-close>Cancel</button>
      <button color="primary" mat-button mat-dialog-close cdkFocusInitial
        (click)="$event.preventDefault(); updateUserPhoneDialerSettings()">Update</button>
    </div>
</div>
