import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '@env/environment';
import { AuthService } from '@app/service/auth.service';
import { LaunchdarklyService } from '@shared/services/LaunchDarkly/launchdarkly.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(
    private cookieService: CookieService,
    private _authService: AuthService,
    private _launchDarklySvc: LaunchdarklyService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.cookieService.get('LAWEB')) {
      this._authService.redirectToAuth();
      return false;
    }

    return true;
  }
}
